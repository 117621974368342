import React, { useState, useEffect, useRef, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { checkUserIsSeller, checkValidString, getValBySearch } from '../../../utils/helpers';
import { searchProductAsync, setResultSearch, setSearchTerm } from '../../../services/store/storeSlice';
import Translation from '../../../utils/Translation';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

function ProductSearch({ resultSearch, search_term, language, auth, dynamicState }) {

  const dispatch = useDispatch();
  const {userProfile} = useSelector(state => state.profile);
  const [selectedValue, setSelectedValue] = useState(null);
  const [inputValue, setInputValue] = useState(search_term || '');
  const isFirstRender = useRef(true);

  const debouncedSearch = debounce(async (inputValue) => {
    const source = axios.CancelToken.source();
    if (checkValidString(inputValue) && inputValue.length >= 3) {
      dispatch(searchProductAsync({ auth, dynamicState, inputValue, source }));
    } else if (checkUserIsSeller(userProfile) && inputValue.length === 0) {
      dispatch(setSearchTerm(inputValue));
    }

    return () => source.cancel();
  }, 300); 

  const handleSetProductName = (event, value) => {
    dispatch(setSearchTerm(value));
    dispatch(setResultSearch([]));
    setSelectedValue(null);
    setInputValue(value);
  };

  const handleChange = (event) => {
    const search_term = event.target.value;
    if (search_term === '') dispatch(setResultSearch([]));
    setInputValue(search_term);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // Skip first render
      return;
    }

    debouncedSearch(inputValue); 

    return () => debouncedSearch.cancel();
  }, [inputValue]); 

  const mappedOptions = useMemo(
    () => resultSearch.map((option) => getValBySearch(option.name, inputValue, language)),
    [resultSearch, inputValue, language]
  );

  return (
    <Stack>
      <Autocomplete
        freeSolo
        id="searchValue"
        disableClearable
        options={mappedOptions}
        value={selectedValue}
        inputValue={inputValue}
        onChange={handleSetProductName}
        renderInput={(params) => (
          <TextField
            {...params}
            label={<Translation data="store.product_search" />}
            slotProps={{
              input: {
                ...params.InputProps,
                type: 'search',
              },
            }}
            onChange={handleChange}
          />
        )}
      />
    </Stack>
  );
}

export default memo(ProductSearch);
