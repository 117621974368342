import React from 'react'
import Translation from "../../../utils/Translation";
import { Box } from '@mui/material';
export default function ShowPrice({Details,page}) {
  return (
    (page === "store") ?
        (<Box className="show-price price-on-card">
           
            {Details.price < Details.default_price ? 
            <Box>
                <Box className="price">
                    <Translation data="store.price" />  
                    <span className='last-price'>  {Details.price?.toFixed(2)} </span> 
                    <span>  <Translation data="store.aed" /></span>
                </Box>
                <Box className="sale-price">
                {Details.default_price?.toFixed(2)} 
                <span>  <Translation data="store.aed" />  </span> 
                </Box>
            </Box>
            :<Box className="price">
                <Translation data="store.price" />  
                <span className='last-price'> {Details.default_price?.toFixed(2)} </span>
                <span><Translation data="store.aed" /></span>
            </Box>
            }
        </Box>) : (
        <Box className="show-price">
                <Translation data="store.price" />  
                {Details.price < Details.default_price ? 
                <Box>
                    <span>  {Details.price.toFixed(2)} </span> 
                    <span>  <Translation data="store.aed" /> {"  "}</span>
                    <span className="sale-price-single-page"> 
                    {Details.default_price?.toFixed(2)} <Translation data="store.aed" /> 
                    </span>
                </Box>
                :<Box>
                    <span> {Details.default_price?.toFixed(2)} </span>
                    <span><Translation data="store.aed" /></span>
                </Box>
                }
         </Box>
      )
    
  
  )
}
