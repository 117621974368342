import { useState, useEffect } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import Translation from "../../utils/Translation";

const NotificationToggle = () => {
  const [isGranted, setIsGranted] = useState(
    Notification.permission === "granted"
  );

  useEffect(() => {
    setIsGranted(Notification.permission === "granted");
  }, []);

  const handleToggle = () => {
    if (isGranted) {
      alert("To disable notifications, go to your browser settings.");
      setIsGranted(false);
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          setIsGranted(true);
          new Notification("Notifications enabled!");
        }
      });
    }
  };

  return (
    <FormControlLabel
      control={<Switch checked={isGranted} onChange={handleToggle} />}
      label={
        isGranted ? (
          <Translation data="setting.disable_notifications" />
        ) : (
          <Translation data="setting.enable_notifications" />
        )
      }
    />
  );
};

export default NotificationToggle;
