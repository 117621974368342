import React, { useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import ProductLimit from '../../../components/store/action/ProductLimit';
import MainTitle from '../../../components/headline/MainTitle';
import { Button, CircularProgress, Stack, TablePagination } from '@mui/material';
import Translation from '../../../utils/Translation';
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from 'react-router-dom';
import { allLinks, checkValidString } from '../../../utils/helpers';
import TableRows from './TableRows';
import { useDispatch } from 'react-redux';
import axios from "axios";
import { useSelector } from 'react-redux';
import { loadProductAsync, setActiveStep, setOffset, setPage, setProducts, setSearchTerm } from '../../../services/store/storeSlice';
import Grid from '@mui/material/Grid2';
import Container from '@mui/material/Container';
import ProductSearch from '../../../components/store/action/ProductSearch';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#005d6d",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


 function StoreSeller() {

  const auth = useSelector((state) => state.auth);
  const dynamicState = useSelector((state) => state.dynamicConfig);
  const {language} = useSelector((state) => state.global);
  const {limit,offset,search_term,resultSearch,products,isLoading,page,statusStoreCrudProduct,productIdDeleted} = useSelector((state) => state.store);
  const {sellerInfo} = useSelector((state) => state.seller);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangePage =  (event, value) => {

      if(value > 0){
        dispatch(setOffset((limit * value) - limit)); // calculate offset
        dispatch(setPage(value));
      }
  
  };

  const getProducts = useCallback((source) => {
    
    let data = {
      limit: limit,
      offset: offset,
      extra: (checkValidString(search_term)) ? `&search_term=${search_term}`:"" ,
      cancelToken: source.token
    };
    data.extra += `&store_ids=${sellerInfo?.id}`; //add store id

    dispatch(loadProductAsync({ auth, dynamicState, data }));
  });


  useEffect(() =>{

    const source = axios.CancelToken.source();

    // check product is empty and reset loading
    if (products.length === 0 && sellerInfo?.id !== undefined) 
        getProducts(source);
    
    return () => {
      source.cancel();
    };

  },[limit,offset,page,search_term,sellerInfo,dispatch]);



  useEffect(() =>{

    if(statusStoreCrudProduct==="succeeded") {
       dispatch(setProducts(products.filter((val) => val.id !== productIdDeleted)));
     }
    
  },[statusStoreCrudProduct,dispatch]);


  
  const handleAddProduct = () =>{
    dispatch(setActiveStep(0));
    navigate(`/${allLinks[16]}`);
  }

  return (
    <Box>
     <MainTitle  title='store_seller.main-title'  />

      <Container className='no-padding page_size'>
        <Grid   container>
            <Grid  size={{lg:4,md:5,sm:6,xs:12}}  >
                <ProductSearch
                  resultSearch={resultSearch} 
                  search_term={search_term} 
                  language={language} 
                  auth={auth} 
                  dynamicState={dynamicState} 
                  />  
            </Grid>  
            <Grid  size={{lg:6,md:5,sm:1}}>
                
            </Grid> 
            <Grid  size={{lg:1,md:1,sm:3,xs:6}} className="align-content-center limit-responsive">
                <ProductLimit limit={limit} />    
            </Grid>  
            <Grid   size={{lg:1,md:1,sm:2,xs:6}} className="align-content-center">
                <Stack direction="row-reverse" >
                    <Button variant="contained" className='font-weight-600  icon-arabic' onClick={handleAddProduct}  endIcon={<AddIcon />}>
                      <Translation data="request.add" />
                    </Button>
                </Stack>
            </Grid>  
        </Grid>
       </Container>

        {isLoading ? 
         <CircularProgress className='full-preloader-position' /> 
         : <Box className="product-table">      
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <StyledTableRow>
                          <StyledTableCell />
                          <StyledTableCell><Translation data="store_seller.product_id" />  </StyledTableCell>
                          <StyledTableCell><Translation data="store_seller.product_name" />  </StyledTableCell>
                          <StyledTableCell ><Translation data="store_seller.product_img" /> </StyledTableCell>
                          <StyledTableCell ><Translation data="store_seller.product_review" /> </StyledTableCell>
                          <StyledTableCell ><Translation data="store_seller.status" /> </StyledTableCell>
                          <StyledTableCell ><Translation data="store_seller.rejected_reason" /> </StyledTableCell>
                          <StyledTableCell ><Translation data="store_seller.create_at" /> </StyledTableCell>
                          <StyledTableCell ><Translation data="store_seller.product_action" /></StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {products.length > 0 && products.map((row,idx) => (
                          <TableRows key={idx} product={row} language={language} auth={auth} dynamicState = {dynamicState}  /> 
                        ))}
                      </TableBody>

                    </Table>

                    <TablePagination
                      page={page}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={({ page }) => `${Translation({ data: "store_seller.rows_per_page" })} ${page}`}
                      count={-1}
                      rowsPerPage={10}
                    />

                </TableContainer>
          </Box>
        }
  
    </Box>
  );
}

export default StoreSeller;