  import Card from "@mui/material/Card";
  import CardContent from "@mui/material/CardContent";
  import Typography from "@mui/material/Typography";
  import CardActionArea from "@mui/material/CardActionArea";
  import CardActions from "@mui/material/CardActions";
  import { getValByLang, removeHtmlCharacter, truncateText } from "../../utils/helpers";
  import { useSelector } from "react-redux";
  import { LazyLoadImage } from "react-lazy-load-image-component";
  import { useNavigate } from "react-router-dom";
  import { useDispatch } from "react-redux";
  import {setDetailsSelected, setProductDetails} from "../../services/store/storeSlice";
  import AddToCartButton from "./action/AddToCartButton";
  import ShowPrice from "./product_detail/ShowPrice";
  import { allLinks } from "../../utils/helpers";
import { Rating } from "@mui/material";
import Translation from "../../utils/Translation";

  export default function ProductCard({ product,setReadyForShowAttr  }) {
  
    const { language } = useSelector((store) => store.global);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const image = product.img? product.img: require("../../assets/image/product/default-product.webp");
    const Details = product.details[0];
    const getSingleProduct = (product, details) => {

      if(setReadyForShowAttr){// show product from slider inside product details
        setReadyForShowAttr(false);
        window.scrollTo(0,0);
      } 

      dispatch(setDetailsSelected(details));
      dispatch(setProductDetails(product));
      navigate(`/${allLinks[5]}/` + getValByLang(product.slug,language));
    };

    return (
      <Card className="product-card">
        <CardActionArea
          onClick={() =>
            getSingleProduct(// check if find product default price
              product,
              Details
            )
          }
        >
        
        <LazyLoadImage
          className="product-img"
          effect="blur"
          src={image}       
        />

          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              className="product-title"
            >
              {getValByLang(product.name, language)}
            </Typography>

            <Typography
              component="span"
              variant="p"
              sx={{ color: 'text.primary', display: 'block' }}>
              {product?.review === 0 ?  <Typography className="not_review_yet"><Translation data="store.no_review_yet" /></Typography> :
                <Rating  name="size-medium" className='starts_ar' value={product?.review} readOnly/>
              }
              
              </Typography>
          
            <Typography variant="body2" className="product-description">
              {  removeHtmlCharacter(truncateText(getValByLang(product.description, language),30))}
            </Typography>
          </CardContent>
        </CardActionArea>

        <CardContent sx={{ display: "flex" }}>
          <Typography
            sx={{ alignContent: "center" }}
            variant="span"
            component="b"
            className="product-actions font-size-14"
          >
            
            <ShowPrice Details={Details} page ="store"   />

          </Typography>
          <CardActions sx={{ width: "50%" }}>
            <AddToCartButton product={product} type="store" quantity={1} detail={Details}  />
          </CardActions>
        </CardContent>
      </Card>
    );
  }
