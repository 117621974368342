import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { LanguageOutlined } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link, useNavigate } from 'react-router-dom';
import Translation from '../../utils/Translation';
import { Badge } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setBadgeContent } from '../../services/globalSlice';
import { showNotify } from '../../services/request/requestSlice';
import { updateAllNotifications } from '../../components/notification/CheckNotification';
import { useState } from 'react';
import { checkUserIsCustomer,allLinks } from '../../utils/helpers';

export default function Sidebar({handleRoute,HandleUserLogoutClick,handleLanguage,langs,isSeller}) {
  const [open, setOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [openLang, setOpenLang] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const {notification,badgeContent} = useSelector(state => state.global);
  const {userProfile} = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(state => state.auth);

  const handleMenuItemClick = (notify) => {
   
    if(notify) {
      let req_id = notify?.req_id;

       if(req_id){
          dispatch(showNotify({req_id}));    
          navigate('/'); // return to request updated
       }else{
          if(checkUserIsCustomer(userProfile)){
          navigate(`/${allLinks[15]}`); // return to request updated
          }else{
          navigate(`/${allLinks[14]}`); // return to request updated
          }      
      }
    }

  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleClick = (active,val,type) => {


    if(type === "notify"){

      const updateFunction = (docData) => {
        return {
          ...docData,
          read: true
        };
      };
    
    let result = updateAllNotifications(`users/${auth.user.uid}/notifications-next`, updateFunction);

    if(result)
       dispatch(setBadgeContent(0));
    }

    active(!val);
  };
  
  const DrawerList = (

  <Box sx={{ width: 250 }} role="presentation">
           
    <Box className="navbar-color">
         <Link onClick={()=>(handleRoute('/'))} to="/"><img src={require('../../assets/image/layout/logo.png')}  className='main-logo'  alt='' /></Link>
    </Box>

   <List
      sx={{ width: '100%',pt:'5%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
   
      <ListItemButton onClick={() => handleClick(setOpenNotification,openNotification,"notify")}>
        <ListItemIcon>
        <Badge badgeContent={badgeContent > 0?badgeContent:null } color="error">
          <NotificationsIcon />
        </Badge>
        </ListItemIcon>
        <ListItemText primary=<Translation data="layout.notification"  />  />
        {openNotification ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={openNotification} timeout="auto" unmountOnExit>

        <Box
          sx={{
            maxHeight: 400, // Set a max height for the menu to make it scrollable
            overflowY: 'auto',
          }}
        >
          {
            notification.length > 0 ?   notification.slice(0,50).map((notify, index) => (
            <List key={index} onClick={() => handleMenuItemClick(notify)}  component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
              {notify?.notify?.title} <br></br> {notify?.notify?.body}
              </ListItemButton>
            </List>
          )):
          <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary={ <Translation data="layout.empty_notify"   />} />
              </ListItemButton>
            </List>
          }
       
        </Box>

      </Collapse>

      <ListItemButton  onClick={() => handleClick(setOpenLang,openLang,"language")}>
        <ListItemIcon>
          <LanguageOutlined />
        </ListItemIcon>
        <ListItemText primary=<Translation data="layout.languages"  /> />
        {openLang ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openLang} timeout="auto" unmountOnExit>

        <List component="div" disablePadding>
          {langs.map((option, index) => (
            <ListItemButton sx={{ pl: 4 }} key={index}  onClick={(event) => handleLanguage(event, index)}>
                <ListItemText primary= {option} />
              </ListItemButton>
          ))}  
        </List>
      </Collapse>


      <ListItemButton  onClick={() => handleClick(setOpenProfile,openProfile,"profile")}>
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary=<Translation data="layout.profile"  /> />
        {openProfile ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openProfile} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>

          <ListItemButton onClick={()=>HandleUserLogoutClick('profile')}>
            <ListItemText primary=<Translation data="layout.profile"  /> />
          </ListItemButton>

          { isSeller  && 

            <ListItemButton onClick={()=>HandleUserLogoutClick('store')}>
                      <ListItemText primary=<Translation data="layout.store"  /> />
                    </ListItemButton>
          }

          <ListItemButton  onClick={()=>HandleUserLogoutClick('logout')}>
            <ListItemText primary=<Translation data="layout.logout"  /> />
          </ListItemButton>

        </List>
      </Collapse>

    </List>
    </Box>
  );

  return (
    <div>
      <ViewHeadlineIcon   onClick={toggleDrawer(true)} />
      <Drawer open={open} onClose={toggleDrawer(false)} className='sidebar-responsive'>
        {DrawerList}
      </Drawer>
    </div>
  );
}
