import React, { memo } from "react";
import {FormControl, FormLabel, TextField } from "@mui/material";
import Translation from "../../../utils/Translation";

const ProductWarranty = ({setValue,register,displaySpecialWarranty,setdisplaySpecialWarranty}) => {


  return (
    <FormControl sx={{ display: "flex", flexDirection: "column", gap: 2, maxWidth: 400 }}>
     <FormLabel component="legend">{<Translation data="store_seller.warranty" />}</FormLabel>
      {/* Warranty Period (Number Input) */}
      <TextField
        label={<Translation data="store_seller.warranty_period" />}
        {...register(`warrantyPeriod`, {
          onChange: (e) => {
            e.target.value > 0 ?setdisplaySpecialWarranty(true) : setdisplaySpecialWarranty(false);
            setValue(`warrantyPeriod`, e.target.value); // Sync Arabic with English
          }
        })}
        type="number"
        fullWidth
        InputProps={{ inputProps: { min: 0 } }}
      />

     {/* Special Warranty Description */}
     {displaySpecialWarranty  &&
        <TextField
          label={<Translation data="store_seller.special_warranty_details" />}
          {...register(`specialWarranty`, {
          onChange: (e) => {
            setValue(`specialWarranty`, e.target.value); // Sync Arabic with English
          }
          })}
          className="specialWarranty"
          fullWidth
          multiline
          rows={3}
        />
     } 
    </FormControl>
  );
};

export default memo(ProductWarranty);
