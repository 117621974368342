import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Use localStorage as default storage
import authReducer from '../services/auth/authSlice';
import profileReducer from '../services/profile/profileSlice';
import globalReducer from '../services/globalSlice.js';
import dynamicConfigReducer from '../services/dynamic_config/dynamicConfigSlice';
import requestSLice from '../services/request/requestSlice';
import addressSlice from '../services/address/addressSlice';
import chatSlice from '../services/chat/chatSlice.js';
import storeSlice from '../services/store/storeSlice';
import cartSlice from '../services/cart/cartSlice';
import orderSlice from '../services/order/orderSlice';
import sellerSlice from '../services/seller/sellerSlice';

// Persist configuration for the cart slice
const cartPersistConfig = {
  key: 'cart',
  storage,
};

// Combine reducers
const rootReducer = combineReducers({
  global: globalReducer,
  auth: authReducer,
  dynamicConfig: dynamicConfigReducer,
  profile: profileReducer,
  request: requestSLice,
  address: addressSlice,
  chat: chatSlice,
  store: storeSlice,
  cart: persistReducer(cartPersistConfig, cartSlice), // Wrap cartSlice with persistReducer
  order: orderSlice,
  seller: sellerSlice
});

// Configure store with persistReducer
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Create persistor
export const persistor = persistStore(store);

export default store;


