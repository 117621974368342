import * as React from "react";
import Box from "@mui/material/Box";
import { memo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import SideBarComponent from "../../../components/store/layout/SideBarComponent";

function SideBarStore({
  isLoading,
  attributesAvailable,
  categoriesAvailable
}) {
  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box className="sidebar-top" role="presentation">
      <Box className="sidebar-close">
        <CloseIcon onClick={toggleDrawer(anchor, false)} fontSize="small" />
      </Box>

      {isLoading ? (
        <CircularProgress className="sidebar-preloader-position" />
      ) : (
        <SideBarComponent
          attributesAvailable={attributesAvailable}
          categoriesAvailable={categoriesAvailable}
        />
      )}
    </Box>
  );
  return (
    isLoading === false && (
      <SideBarComponent
        attributesAvailable={attributesAvailable}
        categoriesAvailable={categoriesAvailable}
      />
    )
  )
}



export default memo(SideBarStore);
