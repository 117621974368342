import { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useDispatch,useSelector } from 'react-redux';
import ResultSend from '../../../components/alert/ResultSend';
import {  getDownloadURL, ref as storageRef,  uploadBytes } from 'firebase/storage';
import {storage} from '../../../config/firebase-config';
import Translation from '../../../utils/Translation';
import { checkFindStoreForSeller, getValByLang, isFile, sellerIsRejected } from '../../../utils/helpers';
import { Alert, Avatar, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { createOrUpdateStoreAsync, setError, setStatusSeller } from '../../../services/seller/sellerSlice';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function SellerInfo() {

  const {language} = useSelector(state => state.global);
  const auth = useSelector(state => state.auth);
  const dynamicState = useSelector(state => state.dynamicConfig);
  const {sellerInfo,businessTypes,errorSeller,statusSeller} = useSelector(state => state.seller);
  const [name,setName] = useState(sellerInfo?.name? getValByLang( sellerInfo?.name,language):'');
  const [description,setDescription] = useState(sellerInfo?.description? getValByLang( sellerInfo?.description,language):'');
  const [businessTypeId,setBusinessTypeId] = useState(sellerInfo?.business_type_id? sellerInfo?.business_type_id:'');
  const [disable,setDisable]= useState(false);
  const [msg,setMsg]= useState(null);
  const [update,setUpdate]= useState(false);
  const [image, setImage]  = useState(sellerInfo?.img_url? sellerInfo?.img_url:null);
  const [emaritsId, setEmaritesId]  = useState(null);
  const [tradLic, setTradLic]  = useState(null);
  const [ibanCertificate, setIbanCertificate]  = useState(null);
  const dispatch = useDispatch();
  const handleMsg=(value)=> setMsg(value);
  const [previewImg, setPreviewImg] = useState(null);
  const [previewEmaritesId, setPreviewImgEmaritesId] = useState(null);
  const [previewTradLic, setPreviewTradLic] = useState(null);
  const [previewIbanCertificate, setPreviewIbanCertificate] = useState(null);
  const handleChangeStoreImg = (e) => {

    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setPreviewImg(URL.createObjectURL(e.target.files[0]));
    }
        
  };

  const handleChangeEmaritsId = (e) => {

    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setEmaritesId(file);
      setPreviewImgEmaritesId(URL.createObjectURL(file))
    } else {
      setEmaritesId(null);
      dispatch(setError(<Translation data="seller.valid_pdf_emarites_id" />));
      handleMsg(<Translation data="seller.valid_pdf_emarites_id" />);
    }
    
  };

 const handleChangeTradLic = (e) => {

  const file = e.target.files[0];
  if (file && file.type === "application/pdf") {
    setTradLic(file);
    setPreviewTradLic(URL.createObjectURL(file))
  } else {
    setTradLic(null);
    dispatch(setError(<Translation data="seller.valid_pdf_trad" />));
    handleMsg(<Translation data="seller.valid_pdf_trad" />);
  }
};

const handleChangeIbanCertificate = (e) => {

  const file = e.target.files[0];
  if (file && file.type === "application/pdf") {
    setIbanCertificate(file);
    setPreviewIbanCertificate(URL.createObjectURL(file))
  } else {
    setIbanCertificate(null);
    dispatch(setError(<Translation data="seller.valid_pdf_iban" />));
    handleMsg(<Translation data="seller.valid_pdf_iban" />);
  }
};


  const handleButton =  () =>{

    if(name && businessTypeId &&   (checkFindStoreForSeller(sellerInfo)  ||  (emaritsId && tradLic && ibanCertificate)))
      return false;

    return true;
  }

  const handleUpload = async (file, path) => {

    try {
      const imageRef = storageRef(storage, path);
      const snapshot = await uploadBytes(imageRef, file);
      const fileUrl = await getDownloadURL(snapshot.ref);

      return fileUrl; // Return the URL after successful upload

    } catch (error) {
      console.log(error);
      return ''; // Return an empty string in case of an error
    }
  };

  const handleSendStore = async (e) => {
    e.preventDefault();

    dispatch(setStatusSeller("idle"));
    dispatch(setError(null));
  
    setDisable(true);
    handleMsg("");
    setUpdate(true);

    try {
      // Perform all uploads concurrently
      const [imgUrl, emaritsIdUrl, tradLicUrl,ibanCertificateUrl] = await Promise.all([
        isFile(image) ?  handleUpload(image, `user/${auth.user.uid}/store/store_image`) : image,
        emaritsId ? handleUpload(emaritsId, `user/${auth.user.uid}/store/emirates_id`) : '',
        tradLic ? handleUpload(tradLic, `user/${auth.user.uid}/store/trade_license`) : '',
        ibanCertificate ? handleUpload(ibanCertificate, `user/${auth.user.uid}/store/iban_certificate`) : ''
      ]);
  
      // Proceed with sending store data
      sendStore(imgUrl, emaritsIdUrl, tradLicUrl,ibanCertificateUrl);
    } catch (error) {
      console.error('Error uploading files:', error);
      handleMsg("An error occurred while uploading files.");
      dispatch(setStatusSeller("failed"));
      setDisable(false);
    }
  };
  

  const sendStore = (imgUrl,emaritsIdUrl,tradLicUrl,ibanCertificate) => {

    let data = {
      "name": name,
      "description" : description,
      "img_url": imgUrl,
      "business_type_id": businessTypeId,
      "documents": [
          {
              "document_type":"emiratesId",
              "link": emaritsIdUrl
          },
          {
              "document_type":"tradeLicense",
              "link": tradLicUrl
          }
          ,
          {
              "document_type":"ibanCertificate",
              "link": ibanCertificate
          }
      ]
    };

    dispatch(createOrUpdateStoreAsync({auth,dynamicState,data}));
  } 


  useEffect(()=>{
  
    if(statusSeller === "succeeded") {
      (update) && handleMsg(<Translation data="seller.msg"  />);
       setPreviewImg(null);
    }
     
  
    if(statusSeller === "failed")
      (update) && handleMsg(errorSeller);
    
    setDisable(false);

  },[statusSeller,dispatch]);



  return (
    <Box sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 5
      }}>

      <Box sx={{margin:"10px"}}>
          <Avatar className='profile-avatar'  src={(sellerInfo?.img_url)?
          sellerInfo.img_url:require('../../../assets/image/seller/store_default.jpg')} />
      </Box>
    
      <Typography component="h1" variant="b" sx={{fontSize:"30px"}}>
        {(sellerInfo?.name)? getValByLang(sellerInfo?.name,language) :<Translation data = "seller.store_information"  /> }
      </Typography>

      <Box component="form"  noValidate sx={{ mt: 1,width:"50%" }} className='input-profile' onSubmit={(e) =>handleSendStore(e)} >
        <TextField
          required
          id="outlined-required"
          label=<Translation  data="seller.name"  />
          type='text'
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          />

          <TextField
            id="outlined-required"
            label=<Translation  data="seller.description"  />
            type='text'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
          /> 

          {(checkFindStoreForSeller(sellerInfo) && businessTypes.length > 0) ?
            <TextField
              id="outlined-read-only-input"
              label=<Translation  data="seller.business_type" />
              defaultValue= {getValByLang(businessTypes.filter((val) => val.id === sellerInfo?.business_type_id)[0]?.name,language)}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              />
            :
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"><Translation  data="seller.business_type" /></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                onChange = {(e)=> setBusinessTypeId(e.target.value)}
                value={businessTypeId}
                label="Business Type"
                required
              >
              {businessTypes.map((val,idx) => {
                  return (
                    <MenuItem key={idx} value={val.id}>{getValByLang(val.name,language)}</MenuItem>
                  )
              })}
              </Select>
            </FormControl>
          }
  
          <Box className="icon-arabic">
              {previewImg && 
                <Box  className="img-store">
                  <img src={previewImg} alt="Preview" />
                </Box>
              }
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                fullWidth>
                  <Translation  data="seller.store_img"  />
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleChangeStoreImg}
                  required
                />
              </Button>
          </Box>

          {(!checkFindStoreForSeller(sellerInfo) || sellerIsRejected(sellerInfo)) &&

            <>
            {sellerIsRejected(sellerInfo) && 
            <>
            <Box className="mt-1">
                <Alert severity="warning"> {sellerInfo?.rejection_reason} </Alert>
             </Box>
             <Box className="mt-1">
      
             {sellerInfo?.documents.map((obj,idx)=>{
                
                return(
                  <Box className="mt-1">
                    <Typography component="p" variant='b' className='document-name'> 
                        {obj?.document_type}
                    </Typography>
                    <iframe 
                      src=  {obj?.link}
                      width="100%" 
                      height="250px"
                      title="PDF Viewer"
                    />
                  </Box>
                  )
             })}
               
             </Box>
            </>
            }

                <Box className="mt-1 icon-arabic">
                    {previewEmaritesId && 
                      <Box  className="img-store">
                        <iframe 
                          src=  {previewEmaritesId}
                          width="100%" 
                          height="250px"
                          title="PDF Viewer"
                        />
                      </Box>
                    }
                    <Button
                      component="label"
                      role={undefined}
                      variant="outlined"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      fullWidth>
                        <Translation  data="seller.emirates_id"  />
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleChangeEmaritsId}
                        required
                      />
                    </Button>
                </Box>
                <Box className="mt-1 icon-arabic">
                    {previewTradLic && 
                      <Box  className="img-store">
                        <iframe 
                          src=  {previewTradLic}
                          width="100%" 
                          height="250px"
                          title="PDF Viewer"
                        />
                      </Box>
                    }
                    <Button
                      component="label"
                      role={undefined}
                      variant="outlined"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      fullWidth>
                        <Translation  data="seller.trade_license"  />
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleChangeTradLic}
                        required
                      />
                    </Button>
                </Box>
                <Box className="mt-1 icon-arabic">
                   {previewIbanCertificate && 
                      <Box  className="img-store">
                        <iframe 
                          src=  {previewIbanCertificate}
                          width="100%" 
                          height="250px"
                          title="PDF Viewer"
                        />
                      </Box>
                    }
                    <Button
                      component="label"
                      role={undefined}
                      variant="outlined"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      fullWidth>
                        <Translation  data="seller.iban_certificate"  />
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleChangeIbanCertificate}
                        required
                      />
                    </Button>
                </Box>
            </>
          }
      
          {(msg) && <Box sx={{ mt: 2}}>   <ResultSend  msg={msg}  error={errorSeller?true:false} />    </Box> }

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={handleButton() || disable}
            sx={{ mt: 3, mb: 2 }}>
            <Translation  data="seller.save"  /> 
          </Button>
      </Box>

   </Box>
  );
}







