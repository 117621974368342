import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, CircularProgress } from '@mui/material';
import Translation from '../../../utils/Translation';
import { Controller } from 'react-hook-form';
import { attrNameFullObject, getValByLang } from '../../../utils/helpers';
import AddAttribute from './AddAttribute';
import { useState } from 'react';
import { keyLang } from '../../../utils/helpers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

 function TagInput({ control, translate, language, tags , setValue ,getValues }) {

  const [open, setOpen] =useState(false);
  const [options, setOptions] =useState([]);
  const [loading, setLoading] =useState(false);
  const [searchTerm, setSearchTerm] =useState(''); // State for search term
  const [AddAttr,setAddAttr] = useState(false);
  const {sellerInfo} = useSelector((state) => state.seller)
  const [newTag,setNewTag] = useState('');

  const fetchTags = async (query) => {

    setLoading(true);
    setOpen(false);
    setOptions([]);

    await sleep(500); // Simulate delay
    const filteredTags = tags.filter((tag) =>
      getValByLang(tag.name, language).toLowerCase().includes(query.toLowerCase())
    );

    setOptions(filteredTags.slice(0, 25)); // Update options
    setOpen(true);
    setLoading(false);

    setAddAttr((filteredTags.length > 0)  ? false:true);
  };

  const handleOpen = () => {
    setOpen(true);
    setSearchTerm('');
    fetchTags(''); // Fetch all tags on open
  };

  const handleClose = () => {
    setOpen(false);
    setOptions([]); // Clear options on close
  };

  const handleInputChange = (_, value) => {
    setSearchTerm(value); // Update search term state
    if (open && value) fetchTags(value); // Fetch matching tags
  };

  const handleAddNewAttr = () => {
    setValue("tags", [...getValues("tags"),{id:null,name: attrNameFullObject(newTag), img :"", store_id:sellerInfo.id , new:true }]);
    setAddAttr(false);
  }

  const  handleSelection = (selectedOptions) => { 
    setValue("tags",getValues("tags"));
  }

  useEffect(() =>{

    if(searchTerm)
      setNewTag(searchTerm)

  },[searchTerm])

  return (
    <Box display="-webkit-inline-box" gap={2} className="margin-attribute">
      <Controller
        name="tags"
        control={control}
        render={({ field: { onChange, value }, fieldState }) => (
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={options}
            disableCloseOnSelect
            forcePopupIcon={!!options.length} // Show dropdown only if options exist
            getOptionLabel={(option) => getValByLang(option.name, language)}
            value={value || []}
            inputValue={searchTerm} // Bind searchTerm to inputValue
            onInputChange={handleInputChange} // Update search term
            onChange={(_, selectedOptions) => {
              onChange(selectedOptions); // Update React Hook Form value
              handleSelection(selectedOptions); // Call custom action
            }}

            onOpen={handleOpen}
            onClose={handleClose}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key || option.id} {...optionProps}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {getValByLang(option.name, language)}
                </li>
              );
            }}
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={<Translation data="store_seller.tag" />}
                placeholder={translate('store_seller.search')}
                error={!!fieldState.error}
                helperText={fieldState.error ? 'Please select at least one tag.' : ''}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  },
                }}
              />
            )}
          />
        )}
      />

      {AddAttr  && 
         <AddAttribute handleAddNewAttr= {handleAddNewAttr} />
      }
    </Box>
  );
}

export default TagInput;
