import Navbar from "./layout/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "./layout/Footer";
import Container from "@mui/material/Container";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import ChatWidget from "./customer/chat/ChatWidget";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  loadProfileAsync,
  setUserProfile,
  setLoading,
  refreshProfileAsync,
} from "../services/profile/profileSlice";
import PageDriver from "./others/PageDriver";
import Profile from "./profile/Profile";
import ResultError from "../components/alert/ResultError";
import {
  getArchiveRequestAsync,
  getRequestAsync,
  getRequestUnRatedAsync,
} from "../services/request/requestSlice";
import ErrorLoadProfile from "./others/ErrorLoadProfile";
import { useState } from "react";
import { loadAddressAsync } from "../services/address/addressSlice";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { db } from "../config/firebase-config"; // Adjust the path to your firebase.js file
import { UpdateRequestAfterNotifyAsync } from "../services/request/requestSlice";
import {
  NoIndexPage,
  IndexPage,
  checkStorePage,
  checkUserIsSeller,
  checkUserIsCustomer,
  checkUserIsDriver,
  checkFindProfileUser,
  checkFindStoreForSeller,
  sellerIsDisabled,
} from "../utils/helpers";
import {
  loadAllAttributesAsync,
  loadDeliveryOptionsAsync,
  loadStorePageAsync,
  setProducts,
} from "../services/store/storeSlice";
import ButtonCart from "../components/store/cart/ButtonCart";
import { allLinks } from "../utils/helpers";
import {
  getBusinessTypesAsync,
  getSellerReportAsync,
  getStoreAsync,
} from "../services/seller/sellerSlice";
import SellerInfo from "./seller/create_store/SellerInfo";
import SellerStatus from "./seller/create_store/SellerStatus";
import { GetOrderByIdAsync, GetOrderStoreAsync, GetUserOrdersAsync } from "../services/order/orderSlice";

export default function Base() {
  const { isLoading, userProfile, statusProfile } = useSelector(
    (state) => state.profile
  );
  const { user } = useSelector((state) => state.auth);
  const auth = useSelector((state) => state.auth);
  const dynamicState = useSelector((state) => state.dynamicConfig);
  const { status, error } = useSelector((state) => state.request);
  const { sellerInfo, statusSeller , sellerReport } = useSelector((state) => state.seller);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");
  const handleMsg = (val) => setMsg(val);

  useEffect(() => {
    if (user) {
      dispatch(setLoading({ isLoading: true }));
      dispatch(loadProfileAsync({ auth, dynamicState })); // check find profile
      NoIndexPage(); // add no indexing for seo
    } else {
      dispatch(setUserProfile({ userProfile: null }));
      navigate(`/${allLinks[0]}`);
      IndexPage(); // add indexing for seo
    }
  }, [dispatch, user]);

  //effect after load profile and load all data
  useEffect(() => {
    const fetchData = async () => {
      if (statusProfile === "succeeded") {
        if (!checkFindProfileUser(userProfile)) {
          navigate(`/${allLinks[10]}`); // not find profile redirect to profile page
        } else if (checkFindProfileUser(userProfile)) {
          // check user is customer or seller

          // ** store request  ** //
          dispatch(loadAllAttributesAsync({ auth, dynamicState })); 

          // get all address
          dispatch(loadAddressAsync({ auth, dynamicState }));

          // ** load delivery options  ** //
          dispatch(loadDeliveryOptionsAsync({ auth, dynamicState })); 

          if (checkUserIsCustomer(userProfile)) {
            // get request review invoked once only
            dispatch(getRequestUnRatedAsync({ auth, dynamicState }));

            // get request active
            dispatch(getRequestAsync({ auth, dynamicState }));

            // get archive active
            dispatch(getArchiveRequestAsync({ auth, dynamicState })); 

            // ** load store page  ** //
            dispatch(loadStorePageAsync({ auth, dynamicState })); 

            // ** load order page  ** //
            dispatch(GetUserOrdersAsync({ auth, dynamicState })); 
          }

          if (checkUserIsSeller(userProfile)) {
            // reset product
            dispatch(setProducts([]));

            // get store information
            await dispatch(getStoreAsync({ auth, dynamicState }));

            // get all get Business Type for add seller
            await dispatch(getBusinessTypesAsync({ auth, dynamicState }));
          }
        }
      }
    };

    fetchData(); // Call async function
  }, [statusProfile, dispatch]);

  useEffect(() => {
    if (statusSeller === "succeeded") {
      // check user find  store
      if (!checkFindStoreForSeller(sellerInfo)) {
        // redirect page
        navigate(`/${allLinks[13]}`);
      } else {

        if (!sellerReport?.reports) 
           dispatch(getSellerReportAsync({ auth, dynamicState })); // get seller report

        // invoked all orders seller
        let data = {
              store_id: sellerInfo?.id,
              limit: 20,
              offset: 0,
              status: "all"
            };
        dispatch(GetOrderStoreAsync({ auth, dynamicState, data }));
      }
    }
  }, [statusSeller, dispatch]);

  // effect for all requests + archive request
  useEffect(() => {
    if (status === "failed") handleMsg(error);
  }, [status, dispatch]);



  //check find any change in the request,profile
  useEffect(() => {
    if (user) {
      const unsubscribe = onSnapshot(
        collection(db, `/users/${user?.uid}/digest_next`),
        (snapshot) => {
          const newData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // check profile
          if (
            localStorage.getItem("profile_update_id") !==
            newData[0]?.profile_update_id
          ) {
            if (localStorage.getItem("profile_update_id") !== null)
              dispatch(refreshProfileAsync({ auth, dynamicState }));

            localStorage.setItem(
              "profile_update_id",
              newData[0]?.profile_update_id
            );
          }
  
          // check request
          if (newData.length > 0) {
            if (
              !localStorage.getItem("request_update_id") &&
              newData[0]?.request_update_id !== undefined
            ) {
              localStorage.setItem(
                "request_update_id",
                newData[0]?.request_update_id
              );
              return;
            }

            if (
              newData[0]?.event === "new_offer_received" ||
              newData[0]?.event === "offer_cancelled" ||
              newData[0]?.event === "status_updated_by_driver"
            ) {
              // get the request id
              const req_id = newData[0]?.requestId;

              if (
                req_id !== null &&
                localStorage.getItem("request_update_id") !==
                  newData[0]?.request_update_id
              ) {
                localStorage.setItem(
                  "request_update_id",
                  newData[0]?.request_update_id
                );
                dispatch(
                  UpdateRequestAfterNotifyAsync({ auth, dynamicState, req_id })
                );
              }
            }
          }
        }
      );
      //  Cleanup listener on unmount
      return () => unsubscribe();
    }
  }, [dispatch]);



  //check find any change in the order
  useEffect(() => {

    if (user) {
      const orderDigestDocRef = doc(db, "users", user.uid, "digest_next", "order_digest_doc");
      const unsubscribe = onSnapshot(orderDigestDocRef, (docSnapshot) => {

        const data = { id: docSnapshot.id, ...docSnapshot.data() };

           // check profile
           if (
            localStorage.getItem("order_update_id") !==
            data?.order_update_id
          ) {
           
            if (localStorage.getItem("order_update_id") !== null && data?.order_id){ 
              let order_id = data?.order_id;
               dispatch(GetOrderByIdAsync({ auth, dynamicState ,order_id}))
            }

            localStorage.setItem(
              "order_update_id",
              data?.order_update_id
            );
          }
      }) 

      //  Cleanup listener on unmount
      return () => unsubscribe();
    }
  }, [dispatch]);
  



  return (
    <>
      {isLoading ? (
        <CircularProgress className="full-preloader-position" />
      ) : (
        <Box>
          {statusProfile === "failed" && <ErrorLoadProfile />}

          {checkUserIsDriver(userProfile) && <PageDriver />}

          {user && statusProfile === "succeeded" && (
            <>
              {!checkFindProfileUser(userProfile) && <Profile />}

              {statusSeller === "succeeded" &&
                checkUserIsSeller(userProfile) &&
                (!checkFindStoreForSeller(sellerInfo) ||
                  sellerIsDisabled(sellerInfo)) && (
                  <>
                    <SellerStatus sellerInfo={sellerInfo} />
                    <SellerInfo />
                  </>
                )}

              {checkFindProfileUser(userProfile) &&
                (checkUserIsCustomer(userProfile) ||
                  (checkUserIsSeller(userProfile) &&
                    checkFindStoreForSeller(sellerInfo) &&
                    !sellerIsDisabled(sellerInfo))) && (
                  <Box>
                    <Navbar />
                    <Container className="padding-outlet page_size">
                      {checkUserIsSeller(userProfile) && (
                        <SellerStatus sellerInfo={sellerInfo} />
                      )}
                      <Outlet />
                    </Container>
                    <ResultError />
                    {checkStorePage() && checkUserIsCustomer(userProfile) && (
                      <ButtonCart />
                    )}
                    {checkUserIsCustomer(userProfile) && <ChatWidget />}
                    <Footer />
                  </Box>
                )}
            </>
          )}
        </Box>
      )}
    </>
  );
}
