import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  removeFromCart,
  clearCart,
  updateToCart,
} from "../../../services/cart/cartSlice";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import Container from "@mui/material/Container";
import { getValByLang, allLinks } from "../../../utils/helpers";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import { setRoute } from "../../../services/globalSlice";
import { setDetailsSelected, setProductDetails } from "../../../services/store/storeSlice";

const Cart = () => {
  const { items, totalQuantity, totalPrice } = useSelector(
    (state) => state.cart
  );
  const { language } = useSelector((state) => state.global);
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const cartNotMatching = location.state?.msg || null;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartUpdated, setCartUpdated] = useState(false);

  const handleQuantity = (idx, itemCard, newVal) => {
    dispatch(updateToCart({ idx, itemCard, newVal }));
    setCartUpdated(true);
  };


  const getSingleProduct = (product, details) => {
        dispatch(setRoute('/product'));
        dispatch(setDetailsSelected(details));
        dispatch(setProductDetails(product));
  };

  return (
    <Box className="mt-6-percent store_page main-store">
      <Container className="no-padding  page_size">
        <Box style={{ width: "100%" }}>
          <Box sx={{ display: "inline-flex" }}>
            <Button  onClick={() => navigate(`/${allLinks[4]}`)}>
              <ArrowCircleLeftOutlinedIcon
                className="back-to-home"
              />
            </Button>

            <Typography
              variant="h5"
              component="h5"
              className="font-weight-600 mb-1 mt-1"
            >
              {t("cart.shopping_cart")}{" "}
              {items.length === 0 && t("cart.cart_empty")}
            </Typography>
            {items.length > 0 && (
              <Button
                variant="outlined"
                className="clear_cart mt-0-5"
                size="small"
                onClick={() => dispatch(clearCart())}
              >
                {" "}
                {t("cart.clear_cart")}
              </Button>
            )}
          </Box>

          {cartNotMatching && (
            <Box>
              <Typography
                variant="h5"
                component="h5"
                className="font-weight-600 mb-1 mt-1 cart_not_matching"
              >
                {cartNotMatching}
              </Typography>
            </Box>
          )}

          {items.length > 0 && (
            <Box className="cart-container">
              <Box className="cart-items">
                {items.map((item, idx) => (
                  <Box key={item.id} className="cart-item">
                    <Link to={`/${allLinks[5]}/` + getValByLang(item.slug,language)}>
                      <img src={item.img} onClick={() => getSingleProduct(item.product,item.detail)} className="cart-item-image" />
                    </Link>
                    <Box className="cart-item-details">
                      <p>
                        {getValByLang(item.name, language)}
                      </p>
                      <Box className="cart-details">
                        {item.color && (
                          <p>
                            {t("cart.color")}{" "}
                            {getValByLang(item.color, language)}
                          </p>
                        )}
                        {item.size && (
                          <p>
                            {t("cart.size")} {getValByLang(item.size, language)}
                          </p>
                        )}
                      </Box>
                      <Box className="quantity-controls">
                        <Button
                          variant="contained"
                          color="primary"
                          className="button_cart"
                          disabled={item.cannotRemove || item.quantity === 1 }
                          onClick={() => handleQuantity(idx, item, -1)}
                        >
                          {" "}
                          <RemoveIcon fontSize="small" />
                        </Button>
                        <span>{item.quantity}</span>
                        <Button
                          variant="contained"
                          color="primary"
                          className="button_cart"
                          disabled={item.cannotAdd || item.quantity === item.detail.count }
                          onClick={() => handleQuantity(idx, item, 1)}
                        >
                          {" "}
                          <AddIcon fontSize="small" />
                        </Button>
                        <Button
                          color="error"
                          onClick={() => dispatch(removeFromCart(item.id))}
                        >
                          <DeleteIcon fontSize="small" />
                        </Button>
                      </Box>
                    </Box>
                    <Box>
                      <p>
                        {" "}
                        {t("cart.price")} {item.price} {t("cart.aed")}{" "}
                      </p>
                    </Box>
                  </Box>
                ))}
              </Box>

              <Box className="cart-summary">
                <Typography variant="h6" gutterBottom>
                  {t("cart.order_summary")}
                </Typography>
                {totalQuantity && (
                  <p>
                    {t("cart.total_quantity")} {totalQuantity}
                  </p>
                )}
                {totalPrice?.toFixed(2) && (
                  <p>
                    {t("cart.total_price")} {totalPrice?.toFixed(2)}
                  </p>
                )}
                <Button
                  variant="contained"
                  onClick={() => navigate(`/${allLinks[7]}`)}
                  className="checkout-button"
                >
                  {t("cart.proceed_to_checkout")}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Cart;
