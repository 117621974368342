import React from "react";
import Slider from "react-slick";
import MainTitle from "../../../../components/headline/MainTitle";
import { Box } from "@mui/material";
import { memo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

function SliderAds({sliders}) {

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3, // Default for large screens
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000, // Adjust autoplay speed (milliseconds)
    responsive: [
    {
      breakpoint: 1200, // Large screens (Desktops)
      settings: {
        slidesToShow: 3,
        centerPadding: "50px",
      },
    },
    {
      breakpoint: 992, // Medium screens (Laptops/Tablets)
      settings: {
        slidesToShow: 2,
        centerPadding: "30px",
      },
    },
    {
      breakpoint: 768, // Small screens (Tablets)
      settings: {
        slidesToShow: 2,
        centerPadding: "20px",
      },
    },
    {
      breakpoint: 480, // Extra Small screens (Phones)
      settings: {
        slidesToShow: 2,
        centerPadding: "10px",
      },
    },
  ],
  };

  const allSliders = sliders.length > 0 ?   sliders[0].value.value:[];
   
  return (
    <div className="slider-container">

     <Box>
        <MainTitle  title="store.welcome_to_delverit_store"  />
     </Box>
     
      <Slider {...settings}>

      {allSliders. length > 0 && 
      
        allSliders.map((val,idx) =>{
          return (
            <div key={idx} className="slider-item">
            
                <LazyLoadImage
                  className="slider-image"
                  effect="blur"
                  src={val?.photo_url}
                  alt={val?.locale}
                  />              
                      
             </div>
          )
        })
      }
      </Slider>
    </div>
  );
}

export default memo(SliderAds);
