import ApiService from '../api/api_service';
import {ApiException} from '../../utils/exceptions';

const ProfileService = {

    async createOrder({auth,dynamicState,data}){

        const path = '/v1/order';
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const options = {"dynamicState":dynamicState,"reformatData":true}

        try{
            const response = await ApiService.post(path,headers,data,options);
            return response;
        }catch(error){
            throw new ApiException(error)
        }  
    },
    async GetOrderPaymentIntent({auth,dynamicState,order_id}){

        const path = `/v1/stripe/get-order-payment-intent/${order_id}`;
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const options = {"dynamicState":dynamicState,"reformatData":false}
        const data= {}

        try{
            const response = await ApiService.get(path,headers,data,options);
            return response;
        }catch(error){
            throw new ApiException(error)
        }  
    }, 
    async GetOrderById({auth,dynamicState,order_id}){

        const path = `/v1/order/${order_id}`;
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const options = {"dynamicState":dynamicState,"reformatData":false}
        const data= {}

        try{
            const response = await ApiService.get(path,headers,data,options);
            return response;
        }catch(error){
            throw new ApiException(error)
        }  
    }, 
    async GetOrderStore ({auth,dynamicState,data}){

        let path = `v1/store-orders/${data.store_id}?`;

        if(data?.limit)
            path += `limit=${data.limit}`;

        if(data?.offset)
            path += `&&offset=${data.offset}`;

        if(data?.status !== "all")
            path += `&&status=${data.status}`

        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const options = {"dynamicState":dynamicState,"reformatData":false}

        try{
            const response = await ApiService.get(path,headers,data,options);
            return response;
        }catch(error){
            throw new ApiException(error)
        }  
    },
    async ConfirmOrder ({auth,dynamicState,data}){

        let path = `v1/confirm-order-items`;
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const options = {"dynamicState":dynamicState,"reformatData":true}

        try{
            const response = await ApiService.post(path,headers,data,options);
            return response;
        }catch(error){
            throw new ApiException(error)
        }  
    },
    async UserOrders ({auth,dynamicState,attr}){

        let path = `v1/user-orders?`;
        let data = [];
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const options = {"dynamicState":dynamicState,"reformatData":false}
        data = (!attr) ? {limit: 20,offset: 0,status:"all" }:attr;

        if(data?.limit)
            path += `limit=${data.limit}`;

        if(data?.offset)
            path += `&&offset=${data.offset}`;

        if(data?.status !== "all")
            path += `&&status=${data.status}`

        try{
            const response = await ApiService.get(path,headers,data,options);
            return response;
        }catch(error){
            throw new ApiException(error)
        } 

    },
    async CancelOrder ({auth,dynamicState,data}){

        let path = `v1/order/cancel`;
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const options = {"dynamicState":dynamicState,"reformatData":true}

        try{
            const response = await ApiService.patch(path,headers,data,options);
            return response;
        }catch(error){
            throw new ApiException(error)
        }  
    },

    async GetLabelOrder ({auth,dynamicState,reference}){

        let path = `v1/shipping-label/${reference}`;
        let data=[];

        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const options = {"dynamicState":dynamicState,"reformatData":false}

        try{
            const response = await ApiService.get(path,headers,data,options);
            return response;
        }catch(error){
            throw new ApiException(error)
        }  
    },


};
export default ProfileService;

