import ProductCard from "../../../components/store/ProductCard";
import ProductSearch from "../../../components/store/action/ProductSearch";
import ProductLimit from "../../../components/store/action/ProductLimit";
import PaginationRounded from "../../../components/store/action/PaginationRounded";
import Grid from "@mui/material/Grid2";
import Container from "@mui/material/Container";
import { Box, CircularProgress, Typography } from "@mui/material";
import ProductSort from "../../../components/store/action/ProductSort";
import { useSelector } from "react-redux";
import { checkScreenLaptop, getValByLang } from "../../../utils/helpers";
import Translation from "../../../utils/Translation";
import SideBarStoreTop from "./SideBarStoreTop";

export default function ProductStore() {
  const {
    isLoading,
    productCount,
    products,
    categoriesAvailable,
    CategorySelected,
    resultSearch,
    search_term,
    sortBy,
    limit,
    page,
    attributesAvailable,
  } = useSelector((state) => state.store);
  const { language, screenWidth, mediumWidthScreen } = useSelector(
    (state) => state.global
  );
  const auth = useSelector((state) => state.auth);
  const dynamicState = useSelector((state) => state.dynamicConfig);

  return (
    <>
      <Container className="no-padding page_size">

        {!checkScreenLaptop(screenWidth,mediumWidthScreen) && 
            <Box class="mb-2">
              <SideBarStoreTop
                isLoading={isLoading}
                attributesAvailable={attributesAvailable}
                categoriesAvailable={categoriesAvailable}
              />
            </Box>
        }

        <Grid container>

          <Grid size={{ lg: 4, md: 5, sm: 5, xs: 12 }}>
            <ProductSearch
              resultSearch={resultSearch}
              search_term={search_term}
              language={language}
              auth={auth}
              dynamicState={dynamicState}
            />
          </Grid>

          <Grid size={{ lg: 5, md: 3, sm: 1 }}> </Grid>

          <Grid
            className="sort_responsive"
            size={{ lg: 2, md: 2, sm: 2, xs: 6 }}
          >
            <ProductSort sortBy={sortBy} />
          </Grid>
          <Grid
            className="limit_responsive"
            size={{ lg: 1, md: 2, sm: 2, xs: 6 }}
          >
            <ProductLimit limit={limit} />
          </Grid>
        </Grid>
      </Container>

      {(search_term || CategorySelected) && productCount > 0 ? (
        <Container className="no-padding page_size mt-2">
          <Typography variant="b" component="h3">
            {" "}
            <span className="no-bold">
              {" "}
              {productCount} <Translation data="store.results_for" />{" "}
            </span>{" "}
            "
            {categoriesAvailable?.name
              ? getValByLang(categoriesAvailable.name, language)
              : search_term}
            "
          </Typography>
        </Container>
      ) : (
        <></>
      )}

      <Container className="no-padding page_size products">
        <Grid spacing={2} size={{ lg: 12, md: 12, sm: 12, xs: 12 }} container>
          {isLoading === true? (
            <CircularProgress className="product-preloader-position" />
          ) : products.length === 0 ? (
            <Box>
              {" "}
              <Translation data="store.not_found_any_product" />{" "}
            </Box>
          ) : (
            products.map((val, idx) => {
              return (
                <Grid key={idx} size={{ xl: 3,lg:4, md: 4, sm:6, xs: 12 }}>
                  <ProductCard product={val} />
                </Grid>
              );
            })
          )}
        </Grid>
      </Container>

      {isLoading === false && (
        <Container className="no-padding  page_size">
          <PaginationRounded
            limit={limit}
            productCount={productCount}
            page={page}
          />
        </Container>
      )}
    </>
  );
}
