import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, CircularProgress } from '@mui/material';
import Translation from '../../../utils/Translation';
import { Controller } from 'react-hook-form';
import { attrNameFullObject, getValByLang } from '../../../utils/helpers';
import { useState } from 'react';
import AddAttribute from "./AddAttribute";
import { useSelector } from 'react-redux';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

function BrandInput({ control, translate, language, brands , setValue,getValues,register}) {
  
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [AddAttr,setAddAttr] = useState(false);
  const {sellerInfo} = useSelector((state) => state.seller);

  const fetchBrands = async (query) => {
    setLoading(true);
    await sleep(500); // Simulate delay
    const filteredBrands = brands.filter((brand) =>
      getValByLang(brand.name, language).toLowerCase().includes(query.toLowerCase())
    );
    setOptions(filteredBrands.slice(0, 25)); // Update options
    setLoading(false);

    setAddAttr(filteredBrands.length > 0 ? false:true);
  };

  const handleOpen = () => {
    setOpen(true);
    fetchBrands(searchTerm); // Fetch brands using the current search term
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (_, value, reason) => {

    if (reason === "input" || reason === "selectOption" || reason === "clear") {
      setSearchTerm(value); // Update search term
      fetchBrands(value); // Fetch matching brands on input change
    }
  
  };

  const handleAddNewAttr = () =>{
    setValue("brands",[{id:null,name: attrNameFullObject(searchTerm), img :"", store_id:sellerInfo.id , new:true }] );
    setSearchTerm('');
    setAddAttr(false);
  }

  const  handleSelection = (selectedOptions) => { 

    if(selectedOptions.length > 0){
      setValue("brands",[selectedOptions[selectedOptions.length - 1]]);
    }else{
      setValue("brands",[]);
    }
   
  }


  return (
    <Box display="-webkit-inline-box" gap={2} className="margin-attribute">
      <Controller
        name="brands"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            multiple
            id="checkboxes-brands-demo"
            options={options}
            forcePopupIcon={!!options.length} // Show dropdown only if options exist
            getOptionLabel={(option) => getValByLang(option?.name, language)}
            value={value || []}
            inputValue={searchTerm} // Bind searchTerm to inputValue
            onInputChange={handleInputChange} // Trigger fetch on input change
            onChange={(_, selectedOptions) => {
              onChange(selectedOptions); // Update React Hook Form value
              handleSelection(selectedOptions); // Call custom action
            }}
            onOpen={handleOpen}
            onClose={handleClose}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key || option.id} {...optionProps}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {getValByLang(option.name, language)}
                </li>
              );
            }}
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={<Translation data="store_seller.brand" />}
                placeholder={translate('store_seller.search')}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      />

      {AddAttr  && 
        <AddAttribute handleAddNewAttr= {handleAddNewAttr} />
      }
    </Box>
  );
}


export default BrandInput;
