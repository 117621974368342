import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import StoreIcon from "@mui/icons-material/Store";
import {allLinks, getValByLang, truncateText } from "../../../utils/helpers";
import { Button } from "@mui/material";
import Translation from "../../../utils/Translation";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { memo } from "react";

function BreadCrumbsProduct({
  navigate,
  name,
  language,
}) {

  return (
    <>
    <Button onClick={() => navigate(`/${allLinks[4]}`)}>
        <ArrowCircleLeftOutlinedIcon className="back-to-home" />
    </Button>
    <div role="presentation"  className="product-details-breadcrumb">
      <Breadcrumbs  aria-label="breadcrumb">
        <Button
        
          underline="hover"
          sx={{ display: "flex"}}
          color="inherit"
          type="button"
          onClick={() => navigate(`/${allLinks[4]}`)}
        >
          <StoreIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          <Translation data="store.back_to_store"   />
        </Button>
        <Typography
          sx={{ color: "text.primary", display: "flex", alignItems: "center" }}
        >
          {truncateText(getValByLang(name, language),5)}
        </Typography>
      </Breadcrumbs>
    </div>
    </>
    
  );
}

export default memo(BreadCrumbsProduct)
