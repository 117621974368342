import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Translation from '../../utils/Translation';
import { memo } from 'react';

function SellerReportCard({icon,keyVal,value,money}) {

  return (
    <Card className='card-seller-report'>
       <CardContent sx={{ height: '100%' }}>
          <Box className="font-size-14" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
             {icon} {/* Render the icon here */}
            <Typography variant="h6" component="div">
                 <Translation data={`home_seller.${keyVal}`} />:
            </Typography>
        
            <Typography variant="b" component="h3" className='mt-0-3'>
             {Number(value).toFixed(2)}  {money && <Translation data="home_seller.aed" /> }
           </Typography>
           </Box>
      </CardContent>
  </Card>
  )
}


export default memo(SellerReportCard)
