import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setFindErrorChat } from '../../services/chat/chatSlice';
import { setFindErrorAddress } from '../../services/address/addressSlice';
import { setFindErrorRequest } from '../../services/request/requestSlice';
import { setFindErrorProfile } from '../../services/profile/profileSlice';
import { setFindErrorStore } from '../../services/store/storeSlice';
import { setFindErrorOrder } from '../../services/order/orderSlice';
import { setFindErrorSeller } from '../../services/seller/sellerSlice';

export default function ResultError(props) {

  const {error,findErrorRequest} = useSelector(state => state.request);
  const {errorAddress,findErrorAddress} = useSelector(state => state.address);
  const {errorChat,findErrorChat} = useSelector(state => state.chat);
  const {errorProfile,findErrorProfile} = useSelector(state => state.profile);
  const {errorStore,findErrorStore} = useSelector(state => state.store);
  const {errorOrder,findErrorOrder} = useSelector(state => state.order);
  const {errorSeller,findErrorSeller} = useSelector(state => state.seller);

  const [open, setOpen] = React.useState(false);
  const [msg,setMsg] =  React.useState('');

  const dispatch = useDispatch();

  const handleClose = (event, reason) => {

    if (reason === 'clickaway') {
      return;
    }

    dispatch(setFindErrorChat(false));
    dispatch(setFindErrorAddress(false));
    dispatch(setFindErrorRequest(false));
    dispatch(setFindErrorProfile(false));
    dispatch(setFindErrorStore(false));
    dispatch(setFindErrorOrder(false));
    dispatch(setFindErrorSeller(false));
    setOpen(false);
  };

  useEffect(() => {

    if(findErrorRequest && error){
      setOpen(findErrorRequest);
      setMsg(error);
    }

    if(findErrorAddress && errorAddress){
      setOpen(findErrorAddress);
      setMsg(errorAddress);
    }

    if(findErrorChat && errorChat) {
      setOpen(findErrorChat);
      setMsg(errorChat);
    }

    if(findErrorProfile && errorProfile) {
      setOpen(findErrorProfile);
      setMsg(errorProfile);
    }

    if(findErrorStore && errorStore) {
      setOpen(findErrorStore);
      setMsg(errorStore);
    }


    if(findErrorOrder && errorOrder) {
      setOpen(findErrorOrder);
      setMsg(errorOrder);
    }

    if(findErrorSeller && errorSeller) {
      setOpen(findErrorSeller);
      setMsg(errorSeller);
    }
   
  }, [dispatch,findErrorRequest,findErrorAddress,findErrorChat,findErrorProfile,findErrorStore,findErrorOrder,findErrorSeller]);

  const action = (
    <React.Fragment>
      <IconButton
        size="large"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box className="show-error">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
        action={action}
      />
    </Box>
  );
}
