import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import MainTitle from "../../../components/headline/MainTitle";
import { Button, CircularProgress, TablePagination } from "@mui/material";
import Translation from "../../../utils/Translation";
import TableRows from "./TableRows";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid2";
import Container from "@mui/material/Container";
import {
  ConfirmOrderAsync,
  GetLabelOrderAsync,
  GetOrderStoreAsync,
  reloadOrder,
  setOffset,
  setPage,
} from "../../../services/order/orderSlice";
import OrderStatus from "../../../components/order/OrderStatus";
import OrderLimit from "../../../components/order/OrderLimit";
import FulfilmentModal from "../../../components/order/FulfilmentModel";
import RefreshIcon from '@mui/icons-material/Refresh';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#005d6d",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function OrderSeller() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const dynamicState = useSelector((state) => state.dynamicConfig);
  const { language } = useSelector((state) => state.global);
  const { limit, offset, isLoading, orders, statusOrder, page,invoked } = useSelector(
    (state) => state.order
  );
  const { sellerInfo } = useSelector((state) => state.seller);

  const { deliveryOptions } = useSelector((state) => state.store);
  const [orderFulfillment, setOrderFulfillment] = useState(null);
  const newRow = { items: [], package: null };
  const [rows, setRows] = useState([newRow]);
  const [productNotAvailable, setProductNotAvailable] = useState([]);
  const [openFulfillment, setOpenFulfillment] = useState(false);
  const handleCloseFulfillment = () => setOpenFulfillment(false);

  const handleChangePage = (event, value) => {
    if (value > 0) {
      dispatch(setOffset(limit * value - limit)); // calculate offset
      dispatch(setPage(value));
    }
  };

  const GetOrderStore = useCallback(() => {
    let data = {
      store_id: sellerInfo?.id,
      limit: limit,
      offset: offset,
      status: statusOrder,
    };

    dispatch(GetOrderStoreAsync({ auth, dynamicState, data }));
  });

  useEffect(() => {
    // check product is empty and reset loading
    if (!invoked && sellerInfo?.id !== undefined) GetOrderStore();
  }, [limit, offset, page, statusOrder, sellerInfo,invoked, dispatch]);

  const handleLabel = async (reference) => {
    const result = await dispatch(
      GetLabelOrderAsync({ auth, dynamicState, reference })
    );
    const base64String = result.payload?.data?.pdf; // Get base64 PDF string

    if (!base64String) {
      console.error("No PDF data found.");
      return;
    }

    // Convert Base64 to Blob
    const byteCharacters = atob(base64String);
    const byteNumbers = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const blob = new Blob([byteNumbers], { type: "application/pdf" });

    // Create URL for the Blob
    const url = URL.createObjectURL(blob);

    // Open in new tab
    window.open(url);

    // Optionally, trigger download
    const link = document.createElement("a");
    link.href = url;
    link.download = `label_${reference}.pdf`; // File name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Clean up URL object after some time
    setTimeout(() => URL.revokeObjectURL(url), 5000);
  };

  const handleFulfillment = (rows) => {
    let result = [];
    rows.forEach((element) => {
      result.push({
        items: element.items.map((val) => val.detail_id),
        fufillment_option: element.package.id,
      });
    });

    const data = {
      order_id: orderFulfillment?.id,
      fufillment: result,
      cancelledItems: orderFulfillment.order_details
        .map(
          (val) => !productNotAvailable.includes(val.detail_id) && val.detail_id
        )
        .filter((item) => typeof item === "number"),
    };

    dispatch(ConfirmOrderAsync({ auth, dynamicState, data }));
  };

  const handleOpenFulfillment = (order) => {
    setRows([newRow]);
    setProductNotAvailable([]);
    setOrderFulfillment(order);
    setOpenFulfillment(true);
  };

  return (
    <Box className="main-store">
      <MainTitle title="order_seller.order_page" />

      <Container className="no-padding page_size">
        <Grid container>
          <Grid size={{ lg: 2, md: 4, sm: 5, xs: 12 }}>
            <OrderStatus statusOrder={statusOrder} />
          </Grid>

          <Grid  size={{lg:1,md:1,sm:1}} className="align-content-center">
            <Button variant="contained" onClick={()=> dispatch(reloadOrder())}>
                <Translation  data="order_seller.refresh"  />
            </Button>
          </Grid>  

          <Grid  size={{lg:8,md:3,sm:1}} >
          
          </Grid>  

          <Grid
            size={{ lg: 1, md: 4, sm: 5, xs: 12 }}
            className="align-content-center"
          >
            <OrderLimit limit={limit} />
          </Grid>
        </Grid>
      </Container>

      <FulfilmentModal
        openFulfillment={openFulfillment}
        handleCloseFulfillment={handleCloseFulfillment}
        handleFulfillment={handleFulfillment}
        packageSize={deliveryOptions?.ecommerce_settings}
        language={language}
        newRow={newRow}
        rows={rows}
        setRows={setRows}
        orderFulfillment={orderFulfillment}
        productNotAvailable={productNotAvailable}
        setProductNotAvailable={setProductNotAvailable}
      />

      {isLoading ? (
        <CircularProgress className="full-preloader-position" />
      ) : (
        <Box className="order-table">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell />
                  <StyledTableCell>
                    <Translation data="order_seller.order_id" />{" "}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Translation data="order_seller.user" />{" "}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Translation data="order_seller.completed_at" />{" "}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Translation data="order_seller.proof_image" />{" "}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Translation data="order_seller.canceled_by" />{" "}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Translation data="order_seller.cancelation_comment" />{" "}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Translation data="order_seller.created_at" />{" "}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Translation data="order_seller.updated_at" />{" "}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Translation data="order_seller.action" />{" "}
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {orders.length > 0 &&
                  orders.map((row, idx) => (
                    <TableRows
                      key={idx}
                      order={row}
                      language={language}
                      auth={auth}
                      dynamicState={dynamicState}
                      handleOpenFulfillment={handleOpenFulfillment}
                      handleLabel={handleLabel}
                    />
                  ))}
              </TableBody>
            </Table>

            <TablePagination
              page={page}
              onPageChange={handleChangePage}
              labelDisplayedRows={({ page }) =>
                `${Translation({ data: "order_seller.rows_per_page" })} ${page}`
              }
              count={-1}
              rowsPerPage={10}
            />
          </TableContainer>
        </Box>
      )}
    </Box>
  );
}

export default OrderSeller;
