import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import HomeIcon from "@mui/icons-material/Home";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ContactsIcon from "@mui/icons-material/Contacts";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { logoutRequest } from "../../services/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import PublicIcon from "@mui/icons-material/Public";
import { setUserProfile } from "../../services/profile/profileSlice";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import SidebarOption from "./SidebarOption";
import {
  setValueTab,
  setRoute,
  setLastStorePath,
} from "../../services/globalSlice";
import {
  loadStyle,
  keyLang,
  checkStorePage,
  checkUserIsSeller,
  checkUserIsCustomer,
  checkRouteRelatedToStore,
  checkRouteRelatedToStoreForTapOnly,
} from "../../utils/helpers";
import Translation from "../../utils/Translation";
import CheckNotification from "../../components/notification/CheckNotification";
import ProfileService from "../../services/profile/profile_service";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import AppBarCategory from "../customer/store/AppBarCategory";
import { setLanguage } from "../../services/globalSlice";
import { allLinks } from "../../utils/helpers";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { setProducts } from "../../services/store/storeSlice";
import { setOrders } from "../../services/order/orderSlice";

function Navbar() {
  const mainLinks = {
    [`/${allLinks[2]}`]: 0,
    [`/${allLinks[3]}`]: 1,
    [`/${allLinks[4]}`]: 2,
    [`/${allLinks[8]}`]: 3,
    [`/${allLinks[9]}`]: 4,
    [`/${allLinks[14]}`]: 1,
  };
  const langs = ["English", "Arabic"];
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { statusLogout, user } = useSelector((state) => state.auth);
  let location = useLocation();
  const { t, i18n } = useTranslation();
  const { userProfile } = useSelector((state) => state.profile);
  const { language, valueTab , lastStorePath } = useSelector((state) => state.global);
  const auth = useSelector((state) => state.auth);
  const dynamicState = useSelector((state) => state.dynamicConfig);
  const settings = checkUserIsSeller(userProfile)
    ? ["profile", "store", "logout"]
    : ["profile", "orders", "logout"];

  const handleLanguage = (event, index) => {
    let lang = keyLang[index];
    i18n.changeLanguage(lang);

    loadStyle(lang);

    dispatch(setLanguage(lang));
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleRoute = (newRoute) => {
     // check link is related with store
    if (lastStorePath && checkRouteRelatedToStoreForTapOnly(newRoute)){
      navigate(lastStorePath);  // back to store
    }else{
      navigate(newRoute); // set new page by route
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const HandleUserLogoutClick = (setting) => {
    if (setting === "profile") {
      handleRoute(`/${allLinks[10]}`);
    }

    if (setting === "store") {
      handleRoute(`/${allLinks[13]}`);
    }

    if (setting === "orders") {
      handleRoute(`/${allLinks[15]}`);
    }

    if (setting === "logout") {
      let token = "0";
      ProfileService.updateFcmToken({ auth, dynamicState, token });
      localStorage.clear();
      
      dispatch(setLastStorePath(null));
      dispatch(setProducts([]));
      dispatch(setOrders([]));

      dispatch(logoutRequest());
    }

    handleCloseUserMenu();
  };

  useEffect(() => {
    handleRoute(location.pathname); // set route base on page
  }, []);

  // check if user find to redirect
  useEffect(() => {
    if (!user) {
      dispatch(setUserProfile({ userProfile: null }));
      navigate(`/${allLinks[0]}`);
    }
  }, [statusLogout, dispatch]);


  // save last page on store
  useEffect(() => {

    allLinks.forEach((link,index) => {
        
      if(location.pathname.includes(link)){

         dispatch(setRoute(link));

         if(index === 2)
           dispatch(setValueTab(0));

         if(index === 3 || index === 14)
          dispatch(setValueTab(1));

         if(checkStorePage(link) || index === 16 || index === 17)
          dispatch(setValueTab(2));

         if(index === 8)
          dispatch(setValueTab(3));

         if(index === 9|| index === 10 || index === 13)
          dispatch(setValueTab(4));
      }

      // You can perform any side effect here
    });


    if (checkRouteRelatedToStore(location.pathname)) 
        dispatch(setLastStorePath(location.pathname));

  }, [location.pathname]);

  return (
    <Box sx={{ flexGrow: 5 }} className="navbar-background">
      <Container className="no-padding page_size navbar-MuiTabScrollButton-horizontal">
        <Grid container spacing={3}>
          <Grid
            container
            size={{ lg: 2, md: 2, sm: 2 }}
            className="responsive-logo"
          >
            <Grid
              className="margin-auto padding-main-logo"
            >
              <Link onClick={() => handleRoute("/")} to="/">
                <img
                  src={require("../../assets/image/layout/logo.png")}
                  className="main-logo"
                  alt=""
                />
              </Link>
            </Grid>
          </Grid>

          <Grid size={{ lg: 8, md: 7, sm: 8, xs: 10 }}>
            <Tabs
              value={valueTab}
              className="space-around-tabs"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                icon={<HomeIcon />}
                label=<Translation data="layout.home" />
                className="color-navbar"
                onClick={() => handleRoute("/")}
              />

              {/* route for customer */}
              {!checkUserIsSeller(userProfile) && (
                <Tab
                  icon={<AddBoxIcon />}
                  label=<Translation data="layout.add_request" />
                  className="color-navbar"
                  onClick={() => handleRoute(`/${allLinks[3]}`)}
                />
              )}
              {!checkUserIsSeller(userProfile) && (
                <Tab
                  icon={<StoreMallDirectoryIcon />}
                  label=<Translation data="layout.store" />
                  className="color-navbar font-size-30"
                  onClick={() => handleRoute(`/${allLinks[4]}`)}
                />
              )}

              {/* route for seller */}
              {checkUserIsSeller(userProfile) && (
                <Tab
                  icon={<ShoppingCartIcon />}
                  label=<Translation data="layout.orders" />
                  className="color-navbar"
                  onClick={() => handleRoute(`/${allLinks[14]}`)}
                />
              )}
              {checkUserIsSeller(userProfile) && (
                <Tab
                  icon={<StoreMallDirectoryIcon />}
                  label=<Translation data="layout.store" />
                  className="color-navbar font-size-30"
                  onClick={() => handleRoute(`/${allLinks[4]}`)}
                />
              )}

              <Tab
                icon={<ContactsIcon />}
                label=<Translation data="layout.addresses" />
                className="color-navbar"
                onClick={() => handleRoute(`/${allLinks[8]}`)}
              />
              <Tab
                icon={<SettingsIcon />}
                label=<Translation data="layout.setting" />
                className="color-navbar"
                onClick={() => handleRoute(`/${allLinks[9]}`)}
              />
            </Tabs>
          </Grid>

          <Grid
            size={{ lg: 2, md: 3, sm: 2 }}
            className="desktop-option"
            container
          >
            <Grid className="margin-auto" size={{ lg: 3, md: 3, sm: 3, xs: 3 }}>
              <CheckNotification />
            </Grid>

            <Grid className="margin-auto" size={{ lg: 3, md: 3, sm: 3, xs: 3 }}>
              <ButtonGroup
                variant="contained"
                ref={anchorRef}
                className="box-shadow-unset"
                aria-label="Button group with a nested menu"
              >
                <IconButton
                  size="small"
                  aria-controls={open ? "split-button-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <PublicIcon />
                </IconButton>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {langs.map((option, index) => (
                            <MenuItem
                              key={option}
                              disabled={index === 2}
                              selected={
                                index === (language === keyLang[0] ? 0 : 1)
                              }
                              onClick={(event) => handleLanguage(event, index)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>

            <Grid
              className="margin-auto padding-profile"
              size={{ lg: 6, md: 6, sm: 6, xs: 6 }}
            >
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title={<Translation data="layout.profile" />}>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      src={
                        userProfile?.profile_image_url
                          ? userProfile.profile_image_url
                          : require("../../assets/image/profile/avatar.jpg")
                      }
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting, idx) => (
                    <MenuItem
                      key={idx}
                      onClick={() => HandleUserLogoutClick(setting)}
                    >
                      <Translation data={"layout." + setting} />
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Grid>
          </Grid>

          <Grid
            className="mobile-option margin-auto mobile-button"
            size={{ sm: 1, xs: 2 }}
          >
            <SidebarOption
              handleRoute={handleRoute}
              handleLanguage={handleLanguage}
              HandleUserLogoutClick={HandleUserLogoutClick}
              langs={langs}
              isSeller={checkUserIsSeller(userProfile)}
            />
          </Grid>
        </Grid>
      </Container>
      {checkStorePage() && checkUserIsCustomer(userProfile) && (
        <AppBarCategory />
      )}
    </Box>
  );
}

export default memo(Navbar);
