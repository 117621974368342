import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { memo } from 'react';
import { getValByLang } from '../../../utils/helpers';
import Translation from '../../../utils/Translation';


function CategoryBreadCrumb({category,language}) {

  const breadcrumbs = category.length  > 1 ?[
    <Link
      underline="hover"
      key="2"
      color="inherit"
    >
      <Translation data="store_seller.product_category"  /> {getValByLang(category[0]?.name,language) }
    </Link>,
    <Typography key="3" sx={{ color: 'text.primary' }}>
        {getValByLang(category[1]?.name,language) }
    </Typography>,
  ]:[
    <Typography key="3" sx={{ color: 'text.primary' }}>
      <Translation data="store_seller.product_category"  />  {getValByLang(category[0]?.name,language) }
    </Typography>,
  ]

  return (
    <Stack spacing={2} display="inline-flex">
   
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}

export default memo(CategoryBreadCrumb)
