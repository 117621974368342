import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import SendIcon from '@mui/icons-material/Send';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {Button} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useSelector } from 'react-redux';
import RequestDetails from '../dialog/RequestDetails';
import { useDispatch } from 'react-redux';
import { getSingleRequestReviewAsync, ratingAsync, setReqIdCancel, setStatusSingleRequestReview } from '../../services/request/requestSlice';
import Translation from '../../utils/Translation';


export default function ReviewRequestCard() {

    const [star , setStar] = useState(1);
    const {requestUnRated,statusSingleRequestReview,singleRequestReview} = useSelector(state => state.request);
    const auth = useSelector(state => state.auth);
    const dynamicState = useSelector(state => state.dynamicConfig);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const handelStar = (val) => {
        setStar(val)
    }

    const handleDetails = (req_id) => {
    
        dispatch(getSingleRequestReviewAsync({auth,dynamicState,req_id}));
    }

    const handleReview = (req_id,offer_id)=> {
      
       let  data = {
            offer_id: offer_id,
            rating_value: star
        };

        dispatch(setReqIdCancel({reqIdCancel:req_id}));
        dispatch(ratingAsync({auth,dynamicState,data}));
    }  

    const handleClose = () => {
      setOpen(false);
    };

    useEffect(()=>{

        if(statusSingleRequestReview === "succeeded") {
            setOpen(true);
            dispatch(setStatusSingleRequestReview("idle"));
        }
        
    },[dispatch,statusSingleRequestReview]);



  return (
    <>
        {open && 
            <RequestDetails data={singleRequestReview} open={open} handleClose={handleClose} />}
         {
             <Grid container spacing={2}>
                {requestUnRated.map((review,idx)=>{

                return(            
                    <Grid  size={{lg:4 ,md:6 , xs:12}}   key={idx}>
                        <Card>
                            <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" src={review?.profile_image_url}>
                                </Avatar>
                            }
                            title={review?.description}
                            subheader= {review?.unrated_driver_nickname}
                            />

                            <CardContent>
                            <Typography variant="p">

                            <Button size='small' onClick={() =>handelStar(1)}>
                                <StarIcon  />
                            </Button>

                            <Button size='small'  onClick={() =>handelStar(2)}>
                            {star >= 2 ? <StarIcon  />:  <StarBorderIcon  />}
                            </Button>

                            <Button size='small' onClick={() =>handelStar(3)}>
                            {star >= 3 ? <StarIcon  />:  <StarBorderIcon  />}
                            </Button>

                            <Button size='small'  onClick={() =>handelStar(4)}>
                                {star >= 4 ? <StarIcon  />:  <StarBorderIcon  />}
                            </Button>

                            <Button size='small' onClick={() =>handelStar(5)}>
                                {star >= 5 ? <StarIcon  />:  <StarBorderIcon  />}
                            </Button>
                            </Typography>
                            </CardContent>

                            <CardActions disableSpacing>
                             
                             <Box sx={{m:".5rem"}}>
                              <Button onClick={()=>handleDetails(review?.req_id)} size='small' variant="contained"  className='mr-0-5 table-button'> 

                               <Translation  data="home.details" />

                              </Button>
                             </Box>
                 
                            <IconButton  aria-label="Send Review" onClick={() => handleReview(review?.req_id,review?.offer_id)}>
                                <SendIcon className='send-review' />
                            </IconButton>
                        
                        
                            </CardActions>
                        </Card>
                    </Grid>)

                
                })}
             </Grid> 
         }
          
    </>
  );
}