import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { setLimit } from '../../../services/store/storeSlice';
import { useDispatch } from 'react-redux';
import Translation from '../../../utils/Translation'
import { memo } from 'react';

function ProductLimit({limit}) {
  
  const dispatch = useDispatch()
  const handleChange = (event) =>  dispatch(setLimit(event.target.value)); // reset limit

  return (
    <Box>
      <FormControl className='limit' fullWidth>
        <InputLabel id="demo-simple-select-label"><Translation data="store.limit" /></InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={limit}
          label="Limit"
          onChange={handleChange}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default memo(ProductLimit)
