import React from 'react'
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { getValByLang } from '../../../utils/helpers';
import { CardContent, Typography } from '@mui/material';
import { memo } from 'react';

function ReviewOrder({items,language}) {

  const { t, i18n } = useTranslation();
  return (
    <>
      {items.length > 0 && 
        <CardContent>
          <Typography variant="h6" gutterBottom>
             { t("checkout.order_review") }
          </Typography>

          <Box className="cart-container">
            <Box className="cart-items-checkout">
              {items.map((item,idx) => (
                <Box key={item.id} className="cart-item-checkout">
                  <img src={item.img}  className="cart-item-image" />
                  <Box className="cart-item-details">
                    <p>{getValByLang(item.name,language)}</p>
                    <Box className="quantity-controls">
                      <span>{ t("cart.quantity") }{item.quantity}</span>
                    </Box>
                  </Box>
                  <Box>
                    <p> {t("cart.price")} {item.price} {t("cart.aed")}  </p>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </CardContent>
      }
    </>
  )
}


export default memo(ReviewOrder);
