import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Button, Typography } from "@mui/material";
import { getValByLang } from "../../../../utils/helpers";
import Translation from "../../../../utils/Translation";
import { memo } from "react";
import { setCategorySelected } from "../../../../services/store/storeSlice";
import { useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";

function SliderCategory({categories,language}) {

  const dipsatch = useDispatch();
  const settings = {

    infinite: true,
    speed: 500,
    slidesToShow: 5, // Adjust based on your design
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000
  };

  return (
    <>
    <Box>
        <Typography variant="h5" component="h5" className="font-weight-600 mb-1">
            <Translation data="store.all_categories" />
        </Typography>
    </Box>
    <Box className="category-slider-container">
        <Slider {...settings}>
        {categories.map((category) => (
              <Button
              key={category.id}
              className="category-slider-item"
              onClick={() => dipsatch(setCategorySelected([category.id,...category.all_child]))} // Correct placement of onClick
              >
              <Box className="category-image-wrapper">
                  <LazyLoadImage
                    height={225}
                    width={300}
                    effect="blur"
                    src={category.image_url}
                    alt={category.name}
                    className="category-image"
                  />
              </Box>
               <p className="category-name">{getValByLang(category.name, language)}</p>
            </Button>
        ))}
        </Slider>
    </Box>
   </>
  );
}

export default memo(SliderCategory);
