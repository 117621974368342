import { getToken,onMessage } from "firebase/messaging";
import { messaging,db } from  "../../config/firebase-config"
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ProfileService from "../../services/profile/profile_service";
import { setNotification,setBadgeContent } from "../../services/globalSlice";
import { useDispatch } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useState } from "react";
import { Box } from "@mui/material";
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {Tooltip} from "@mui/material";
import Translation from "../../utils/Translation";
import IconButton from '@mui/material/IconButton';
import { collection, query, onSnapshot ,getDocs, writeBatch, doc, orderBy  } from 'firebase/firestore';
import NotificationAlert from "../../components/alert/Notification";
import { showNotify } from "../../services/request/requestSlice";
import { useNavigate } from "react-router-dom";
import { setBadgeContentChat } from "../../services/chat/chatSlice";
import {checkUserIsCustomer, getNotifyId , allLinks, checkUserIsSeller} from '../../utils/helpers'

const BATCH_SIZE = 500;

export default function CheckNotification() {

  const auth = useSelector(state => state.auth);
  const dynamicState = useSelector(state => state.dynamicConfig);
  const {userProfile} = useSelector(state => state.profile);
  const [singleNotify,setSingleNotify] = useState(null);
  const dispatch = useDispatch();

  async function requestPermission() {
    
    const VITE_APP_VAPID_KEY = getNotifyId();

    //requesting permission using Notification API
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      
      const token = await getToken(messaging, {
        vapidKey: VITE_APP_VAPID_KEY,
      });

      // update fcm token 
      ProfileService.updateFcmToken({auth,dynamicState,token});

      // handler for  forward request
      onMessage(messaging, async(payload) => {

        // get the request id
        let req_id = payload.data?.req_id;

        // for notification
      if(
          payload.data.event === "new_offer_received" ||
          payload.data.event === "offer_cancelled" ||
          payload.data.event === "status_updated_by_driver"
        ){

        // set local notify
        setSingleNotify(payload.notification);


        }else if( payload.data.action === "open_chat"  || payload.data.action  ==="chat_received") {    
          // for chatting
           dispatch(setBadgeContentChat(1));
        }
  
        // set new notification
        if(payload.data.event === "delivery_cash_payment_collected_by_driver") {
          // nothing
        }

        // set new notification
        if(payload.data.event === "service_completed") {
          // nothing
        }

         // set new notification for order
        if(payload.data.action === "open_order") {
          setSingleNotify(payload.notification);
        }
        
      });
      
      
    } else if (permission === "denied") {
      //notifications are blocked
      alert("You denied for the notification");
    }
  }

  useEffect(() => {

    requestPermission();

    //get past notification notifications
    const q = query(collection(db,`users/${auth.user.uid}/notifications-next`),orderBy("timestamp","asc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {

        const notificationPast = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
       
        // Set notification
        dispatch(setNotification([]));
        dispatch(setBadgeContent(0));

        let counter = 0;
        notificationPast.filter((notify) => {

          if(notify?.action !== "open_chat"  && notify?.action  !=="chat_received"){
              
            if(checkUserIsCustomer(userProfile) || (checkUserIsSeller(userProfile) && !notify?.req_id)){
              dispatch(setNotification({req_id: notify?.req_id ,notify: {title:notify?.title,body:notify?.body }}));
              if(notify?.read === false) counter++;
            }

           
          }
        
        });
         
        // set notify Unread number
        dispatch(setBadgeContent(counter));
    
    });
    return () => unsubscribe();

  }, []);

  

  return (
    <>
    <SimpleListMenu  />
    {singleNotify !==null &&
        <NotificationAlert  open={true} notification={singleNotify}  setSingleNotify={setSingleNotify}  />
       } 
    </>
 
   );
   
}



export const updateAllNotifications = async (collectionName, updateFunction) => {

  try {

  const querySnapshot = await getDocs(collection(db, collectionName));

  const docs = querySnapshot.docs;
  const totalDocs = docs.length;
  let currentBatch = writeBatch(db);
  let batchCount = 0;

  for (let i = 0; i < totalDocs; i++) {
    const document = docs[i];
    const docRef = doc(db, collectionName, document.id);
    const updatedData = updateFunction(document.data());

    currentBatch.update(docRef, updatedData);
    batchCount++;

    if (batchCount === BATCH_SIZE) {
      await currentBatch.commit();
      currentBatch = writeBatch(db);
      batchCount = 0;
    }
  }

  if (batchCount > 0) {
    await currentBatch.commit();
  }

  
  return true;

  } catch (error) {
    return false
    console.error("Error updating documents: ", error);
  }
};



function SimpleListMenu() {

  const { notification, badgeContent } = useSelector((state) => state.global);
  const {userProfile} = useSelector(state => state.profile);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClickListItem = (event) => {
    const updateFunction = (docData) => {
      return {
        ...docData,
        read: true,
      };
    };

    let result = updateAllNotifications(`users/${auth.user.uid}/notifications-next`, updateFunction);

    if (result) dispatch(setBadgeContent(0));

    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (notify, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);

    if (notify) {
      let req_id = notify?.req_id;
       
      if(req_id){
        dispatch(showNotify({ req_id }));
        navigate('/'); // return to request updated
      }else{
       if(checkUserIsCustomer(userProfile)){
        navigate(`/${allLinks[15]}`); // return to request updated
       }else{
        navigate(`/${allLinks[14]}`); // return to request updated
       }
        
      }

    
    }
  };

  const handleClose = async () => {
    setAnchorEl(null);
  };


  return (
    <Box sx={{ flexGrow: 0 }} className="notification-pos">
      <Tooltip title={<Translation data="layout.notification" />}>
        <IconButton
          size="large"
          aria-label="show new notifications"
          color="inherit"
          onClick={handleClickListItem}
          sx={{ p: 0 }}
        >
          <Badge badgeContent={badgeContent > 0 ? badgeContent : null} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox'
        }}
      >

        <Box
          sx={{
            maxHeight: 400, // Set a max height for the menu to make it scrollable
            overflowY: 'auto',
          }}
        >
          {notification.length > 0 ? (
            notification.slice(0, 50).map((notify, index) => (
              <MenuItem
                key={index}
                onClick={() => handleMenuItemClick(notify, index)}
                className="notify-padding"
              >
                {notify?.notify?.title} <br />
                {notify?.notify?.body}
              </MenuItem>
            ))
          ) : (
            <MenuItem onClick={() => handleMenuItemClick(null, 0)} className="notify-padding">
              <Translation data="layout.empty_notify" />
            </MenuItem>
          )}
        </Box>
   
      </Menu>
    </Box>
  );
}

