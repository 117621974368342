import { Box, Button, TextField } from "@mui/material";
import {Typography} from "@mui/material";
import Grid from '@mui/material/Grid2';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useDispatch } from "react-redux";
import { setMinPrice,setMaxPrice,setChangePrice } from "../../../services/store/storeSlice";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import Translation from '../../../utils/Translation';

export default function ProductPrice() {
  
 const dispatch = useDispatch();
 const [disable,setDisable] = useState(true);
 const {minPrice,maxPrice,changePrice}   = useSelector(store => store.store);
 const refMinPrice = useRef(null);
 const refMaxPrice = useRef(null);

 useEffect(() => {
    if(`${minPrice} - ${maxPrice}` === changePrice) setDisable(true)
    else if(minPrice < 0 || maxPrice < 0 ) setDisable(true)
    else if(minPrice > 0 || maxPrice > 0)  setDisable(false)
    else setDisable(true)
 },[maxPrice,minPrice])

const clearHandle = () => {
  refMinPrice.current.value = ''
  refMaxPrice.current.value = ''

  dispatch(setMinPrice(0))
  dispatch(setMaxPrice(0))
  dispatch(setChangePrice({minPrice:0,maxPrice:0}))
}

  return (
    <Box>
          <Typography className="display-inline-flex" component="h3" variant='b' id="input-slider" gutterBottom>
             <Translation data="store.price_sidebar"  /> 
          </Typography>

          <Button onClick={()=>clearHandle()} className="clear-price" disabled={minPrice <= 0 && maxPrice <= 0}> 
             <Translation data="store.clear"  /> 
          </Button>

          <Grid container sx={{ alignItems: 'center',gap:.5 ,mt:2 }}>

            <Grid size={{lg:4}}>
              <TextField
                label={minPrice > 0? minPrice: <Translation data="store.from" /> }
                id="outlined-size-small"
                size="small"
                inputRef={refMinPrice} // Step 2: Attach the ref to the input
                type="number"
                onChange={(e)=>dispatch(setMinPrice(e.target.value))}
              />
            </Grid>
          
            <Grid size={{lg:4}}>
              <TextField
                  label={maxPrice > 0? maxPrice:  <Translation data="store.to" /> }
                  id="outlined-size-small"
                  size="small"
                  inputRef={refMaxPrice} // Step 2: Attach the ref to the input
                  type="number"
                  onChange={(e)=>dispatch(setMaxPrice(e.target.value))}
                />
              </Grid>

              <Grid size={{lg:1}}> 
                  <Button disabled={disable} 
                  onClick={()=>dispatch(setChangePrice({minPrice,maxPrice}))} 
                  variant="outlined">
                     <Translation data="store.go"  /> 
                  </Button>
              </Grid>

          </Grid>

    </Box>
  );
}