import * as React from "react";
import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Button,
  Typography,
  FormControl,
  Autocomplete,
  TextField,
  Alert,
} from "@mui/material";
import Translation from "../../utils/Translation";
import { getValByLang, keyLang } from "../../utils/helpers";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  bgcolor: "background.paper",
  border: "2px solid #005d6d",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

export default function FulfilmentModal({
  openFulfillment,
  handleCloseFulfillment,
  handleFulfillment,
  packageSize,
  language,
  rows,
  setRows,
  newRow,
  orderFulfillment,
  productNotAvailable,
  setProductNotAvailable,
}) {
  const [validPackage, setValidPackage] = useState(true);
  const totalFee =
    rows.length > 0
      ? rows.reduce(
          (sum, val) => sum + (val?.package?.price ? val?.package?.price : 0),
          0
        )
      : 0;
  // Handle confirmation
  const handleConfirm = () => {
    handleFulfillment(rows);
    handleCloseFulfillment();
  };

  const handleAddRow = () => {
    setRows([...rows, newRow]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);

    console.log(updatedRows)
    setRows(updatedRows);
  };

  const handleProductChange = (idx, event, newValue) => {
    let allDetailsID = [];

    let updatedRows = rows.map((row, index) => {
      if (index === idx) {
        allDetailsID.push(newValue.map((val) => val.detail_id));

        return {
          ...row,
          items: newValue?.length > 0 ? newValue : [], // Update items correctly
        };
      } else {
        allDetailsID.push(row.items.map((val) => val.detail_id));
      }

      return row;
    });

    setProductNotAvailable(allDetailsID.length > 0 ? allDetailsID.flat() : []);
    setRows(updatedRows);
  };

  const handlePackageChange = (idx, event, newValue) => {
    let updatedRows = rows.map((row, index) => {
      if (index === idx) {
        return {
          ...row,
          package: newValue,
        };
      }
      return row;
    });

    setRows(updatedRows);
  };


  useEffect(() => {
    const isValid = !rows.some((row) => row.items?.length === 0 || !row?.package);
    setValidPackage(isValid);
  }, [rows]);
  

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openFulfillment}
      onClose={handleCloseFulfillment}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}
    >
      <Fade in={openFulfillment}>
        <Box sx={style} className="fulfillment-modal">
          <Typography id="transition-modal-title" variant="h6" component="h2">
            <Translation data="order_seller.select_package_size" />
          </Typography>
          <Typography
            className="mb-2"
            id="transition-modal-description"
            sx={{ mt: 2 }}
          >
            <Translation data="order_seller.appropriate_package_size" />
          </Typography>

          {rows?.length > 0 && (
            <Box className="mb-2">
              {rows.length > 0 &&
                productNotAvailable.length !==
                  orderFulfillment?.order_details.length && (
                  <Alert severity="error" className="mb-1">
                    {" "}
                   <b><Translation data="order_seller.products_are_not_selected" /></b> {" "}
                    {orderFulfillment?.order_details.map((val, idx) => {
                      return !productNotAvailable.includes(val.detail_id)
                        ? getValByLang(val.product_name, language) + " -  "
                        : "";
                    })}
                  </Alert>
                )}

              {totalFee !== 0 && (
                <Alert severity="warning">
                  {" "}
                  <Translation data="order_seller.fee" />
                  {totalFee}
                </Alert>
              )}
            </Box>
          )}

          <Box className="fulfillment-modal-content">
            <Grid container spacing={3}>
              {rows?.length > 0 &&
                rows.map((row, idx) => (
                  <>
                    <Grid
                      size={{ lg: 1, md: 1, sm: 2, xs: 2 }}
                      className="address-name"
                    >
                      {rows.length - 1 === idx && 

                        <Button
                          color="error"
                          onClick={() => handleRemoveRow(idx)}
                        >
                          <DeleteIcon fontSize="small" />
                        </Button>
                      }
                     
                    </Grid>

                    <Grid
                      key={idx}
                      size={{ lg: 5, md: 5, sm: 5, xs: 5 }}
                      className="address-name"
                    >
                      <FormControl fullWidth>
                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          options={orderFulfillment?.order_details.filter(
                            (val) =>
                              !productNotAvailable.includes(val.detail_id)
                          )}
                          getOptionLabel={(option) =>
                            getValByLang(option?.product_name, language)
                          }
                          onChange={(event, newValue) =>
                            handleProductChange(idx, event, newValue)
                          }
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <Translation data="order_seller.select_product" />
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid
                      size={{ lg: 3, md: 3, sm: 5, xs: 5 }}
                      className="address-name"
                    >
                      <FormControl fullWidth>
                        <Autocomplete
                          options={packageSize}
                          getOptionLabel={(option) =>
                            language === keyLang[0]
                              ? option?.text.en
                              : option?.text.ar
                          }
                          onChange={(event, newValue) =>
                            handlePackageChange(idx, event, newValue)
                          }
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <Translation data="order_seller.available_sizes" />
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid
                      size={{ lg: 3, md: 3, sm: 12, xs: 12 }}
                      className="address-name text-center"
                    >
                      {row?.package?.id && (
                        <>
                          <img
                            src={
                              row?.package?.image
                                ? row?.package?.image
                                : require("./../../assets/image/order/box.png")
                            } // Image URL from globalSizes
                            alt=""
                            style={{
                              width: 150,
                              height: 150,
                              objectFit: "contain",
                            }}
                          />
                          <Typography className="fulfillment-text">
                            {language === keyLang[0]
                              ? row?.package?.text.en
                              : row?.package?.text.ar}
                          </Typography>
                          <Typography className="fulfillment_fee">
                            {row?.package?.price}{" "}
                            <Translation data="home_seller.aed" />
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </>
                ))}
            </Grid>
          </Box>

          <Button
            variant="contained"
            className="mt-1 mb-1"
            onClick={handleAddRow}
          >
            <Translation data="order_seller.add_box" />
          </Button>

          {/* Action Buttons */}
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button variant="outlined" onClick={handleCloseFulfillment}>
              <Translation data="order_seller.cancel" />
            </Button>

            <Button
              variant="contained"
              onClick={handleConfirm}
              disabled={!validPackage}
            >
              <Translation data="order_seller.confirm_fulfillment" />
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
