import ApiService from "../api/api_service";
import { ApiException } from "../../utils/exceptions";

const SellerService = {

  async getStore({ auth, dynamicState}) {

    const path = `/v1/store`;
    const headers = {
      authorization: `Bearer ${auth.user.accessToken}`,
      contentType: "application/json",
    };
    const options = { dynamicState: dynamicState, reformatData: false };
    const data = {};

    try {
      const response = await ApiService.get(path, headers, data, options);

      return response.data;
    } catch (error) {
      throw new ApiException(error);
    }
  },
  async getSellerReport({auth, dynamicState}) {

    const path = `/v1/store-active-data`;
    const headers = {
      authorization: `Bearer ${auth.user.accessToken}`,
      contentType: "application/json",
    };
    const options = { dynamicState: dynamicState, reformatData: false };
    const data = {};

    try {
      const response = await ApiService.get(path, headers, data, options);

      return response.data;
    } catch (error) {
      throw new ApiException(error);
    }
  },
  async getBusinessTypes({ auth, dynamicState}) {

    const path = `/v1/store/business-types`;
    const headers = {
      authorization: `Bearer ${auth.user.accessToken}`,
      contentType: "application/json",
    };
    const options = { dynamicState: dynamicState, reformatData: false };
    const data = {};

    try {
      const response = await ApiService.get(path, headers, data, options);

      return response.data;
    } catch (error) {
      throw new ApiException(error);
    }
  },
  async createOrUpdateStore({ auth,dynamicState,data}) {

    const path = `/v1/stores`;
    const headers = {
      authorization: `Bearer ${auth.user.accessToken}`,
      contentType: "application/json",
    };
    const options = { dynamicState: dynamicState, reformatData: true };

    try {
      const response = await ApiService.put(path, headers, data, options);

      return response.data;
    } catch (error) {
      throw new ApiException(error);
    }
  },
  async reviewStore({ auth,dynamicState,data}) {

    const path = `/v1/stores/review`;
    const headers = {
      authorization: `Bearer ${auth.user.accessToken}`,
      contentType: "application/json",
    };
    const options = { dynamicState: dynamicState, reformatData: true };

    try {
      const response = await ApiService.post(path, headers, data, options);

      return response.data;
    } catch (error) {
      throw new ApiException(error);
    }
  }

};
export default SellerService;
