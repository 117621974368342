import {useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {  Stack } from '@mui/material';
import {TextField} from '@mui/material';
import ResultSend from '../alert/ResultSend';
import Map from '../map/SelectAddress';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid2';
import Translation from '../../utils/Translation'
import { useDispatch } from 'react-redux';
import { checkAddressInUaeAsync } from '../../services/address/addressSlice';
import { useSelector } from 'react-redux';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AddressModal(
  {
    open,
    handleClose,
    handleOnSubmitDelete,
    addressData,
    update,
    error,
    status,
    msg,
    handleOnSubmit,
    submit
  }
) {

  const [friendlyName,setFriendlyName] = useState('');
  const [mobile,setMobile] = useState('');
  const [coordinates , setCoordinates] = useState('');
  const [draftCoordinates , setDraftCoordinates] = useState('');
  const [city,setCity]= useState('');
  const [emarites,setEmarites]= useState('');
  const [buildingNo,setBuildingNo] = useState('');
  const [street,setStreet] = useState('');
  const [area,setArea] = useState('');
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const dynamicState = useSelector(state => state.dynamicConfig);
  const {checkAddressInUae} = useSelector(state => state.address);

  const handleCoordinates = (val) => {

    setDraftCoordinates(val);

    // check Coordinates in UAE
    dispatch(checkAddressInUaeAsync({auth,dynamicState,coordinates:val}));

  }

  const handleCity = (val) =>  setCity(val);
  const handleEmarites = (val) =>  setEmarites(val);
  
  const handleInputs = (obj) => {
    setFriendlyName(obj.friendly_name);
    setMobile(obj.mobile);
    setBuildingNo(obj.villa_bldng_no);
    setStreet(obj.street);
    setArea(obj.area);
    handleCoordinates(obj.coordinates);
    handleCity(obj.city);
    handleEmarites(obj.emarites)
  }

  const resetInput = () => {
    setFriendlyName('');
    setMobile('');
    setBuildingNo('');
    setStreet('');
    setArea('');
    // handleCoordinates('');
    handleCity('');
    handleEmarites('')
  }


  useEffect(()=>{
     
     resetInput();

      if(open && update && addressData) 
        handleInputs(addressData[0].address_data);

  },[open]);


useEffect(()=>{
     
  setCoordinates('');

  if(checkAddressInUae === 1)
    setCoordinates(draftCoordinates);
  

},[checkAddressInUae]);


const handleButton = () => {

  if(friendlyName && mobile && (coordinates !== undefined && coordinates !== '' && coordinates !== null ))
    return false;

  return true;
}
  
  return (
    <>
    <div>
      <Modal
      className='address-modal'
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>

          <Grid spacing={2} container>

            <Grid size={{md:11,sm:10,xs:9}}>
                <Typography id="modal-modal-title" variant="h4" component="h2" className='font-weight-600'>
                   {update ?  <Translation data="address.address_modal_update"  /> :
                     <Translation data="address.address_modal_add"  /> } 
                </Typography>
            </Grid>

            <Grid size={{md:1,sm:2,xs:3}}>
            {update && 
                <Button variant="outlined" className='delete-address' color="error" onClick={(e) => handleOnSubmitDelete(e)}>
                   <DeleteIcon  />
                </Button>
            } 
            </Grid>
            
          </Grid>
            
          <Box
              component="form"
              onSubmit={(e) => handleOnSubmit(e,{coordinates,mobile,friendlyName,buildingNo,street,area,city,emarites})}
              sx={{
                '& > :not(style)': { m: 1, width: '100%' },pt:"3%"
              }}
            >
            <TextField id="outlined-basic" value={friendlyName} onChange={(e)=>setFriendlyName(e.target.value)}  
            label={ <Translation data="address.address_name"  />} variant="outlined" helperText={<Translation data="address.address_name_desc"  />} />
            
            <TextField id="outlined-number"  value={mobile} onChange={(e)=>setMobile(e.target.value)} 
              label={ <Translation data="address.address_mobile"  />}    type="number"  helperText={ <Translation data="address.address_mobile_desc"  />}  />
                
            {city &&
              <Box>
                <Typography  variant="h6" component="b" className='font-weight-600'>
                { <Translation data="address.address_location" />}    <Typography  variant="h6" className=''>{city}</Typography>
                </Typography>
              </Box>
               }

            <Typography id="modal-modal-title" variant="h6" component="b" className='font-weight-600'>
               { <Translation data="address.address_select_address"  />} 
            </Typography>
              
            <Box className="map">
              <Map coordinates={coordinates} handleCoordinates={handleCoordinates} handleCity={handleCity} handleEmarites={handleEmarites}  />
            </Box>
            
            <Typography id="modal-modal-title" variant="h6" component="b" className='font-weight-600'>
            { <Translation data="address.address_details"  />}  </Typography>
            <TextField id="outlined-basic"   value={buildingNo}  onChange={(e)=>setBuildingNo(e.target.value)} label={<Translation data="address.building"  />} variant="outlined" />
            <TextField id="outlined-basic"   value={street} onChange={(e)=>setStreet(e.target.value)} label={ <Translation data="address.street"  />}  variant="outlined" />
            <TextField id="outlined-basic"   value={area} onChange={(e)=>setArea(e.target.value)} label={ <Translation data="address.area"  />}  variant="outlined" />

            {(submit && (status !== "loading")) &&  <ResultSend msg={msg}  error={error} />}

            {(checkAddressInUae === 0) && 

                ((coordinates?.lat === undefined || coordinates?.lng === undefined) &&
                  <Box  className='address-not-valid'>
                        <Translation data="address.address_not_valid"   />
                  </Box>
                )
            }

            <Stack direction="row" spacing={2} className='mt-2'>
                <Button variant="contained" size="large" className='font-weight-600' type='submit' disabled={handleButton()}>
                { <Translation data="address.save"  />} 
                </Button>
                <Button variant="outlined" size="large" onClick={()=>handleClose()}>
                { <Translation data="address.cancel"  />} 
                </Button>
            </Stack>
            
          </Box>
        
        </Box>
      </Modal>
    </div>
    </>

  );
}
