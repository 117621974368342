import { useState,useEffect } from 'react'; 
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import AutoCompleteSearch from '../action/AutoCompleteSearch';
import ReadMore from '../action/ReadMore';
import { getValByLang } from '../../../utils/helpers';
import { useSelector } from 'react-redux';
import {  Divider } from '@mui/material';
import {setAttributesSelected } from '../../../services/store/storeSlice';
import { useDispatch } from 'react-redux';

export default function AttributesList(props) {
  const counter = 5;
  const dispatch = useDispatch()
  const {language} = useSelector(store => store.global)
  const {attributesSelected} = useSelector(store => store.store)
  const [limit,setLimit] = useState(counter)
  const [searchVal,setSearchVal] = useState([])
  const [attributesVal,setAttributesVal] = useState([])
  const [checked,setChecked] = useState([])
  const handleSearch = (searchVal)  =>  setSearchVal(searchVal.val)
  
  useEffect(()=>{
    
    setAttributesVal(searchVal.length > 0 ? searchVal:props.list)
    
    if(props.name === "Store") 
      setChecked(attributesSelected.Store)
    
    if(props.name === "Brand") 
      setChecked(attributesSelected.Brand)
    
    if(props.name === "Size") 
      setChecked(attributesSelected.Size)
  
    if(props.name === "Color") 
      setChecked(attributesSelected.Color)
  
    if(props.name === "Tag") 
      setChecked(attributesSelected.Tag)
      
  },[searchVal])


  return (
    <>
    <FormGroup>
      <Typography component="h3" variant='b' className='attribute_name'> {props.title} </Typography>
      <AutoCompleteSearch language = {language} list = {props.list} keyVal = {props.name}  setAttributes={handleSearch}  />
      { 
        attributesVal.filter((val) => checked.includes(val.id))
              .map((val,idx) => {
                  return (<FormControlLabel
                  key = {idx} 
                  control={<Checkbox checked={checked.includes(val.id)}
                  onChange={() => dispatch(setAttributesSelected({key:props.name, val:val.id}))}  />} 
                  className='attribute-name'
                  label={getValByLang(val.name,language)} 
                />)
        })
      }
      { 
        attributesVal.slice(0,limit).filter((val) => !checked.includes(val.id))
          .map((val,idx) => {
               return (<FormControlLabel
               key = {idx} 
               control={<Checkbox checked={checked.includes(val.id)}
               onChange={() => dispatch(setAttributesSelected({key:props.name, val:val.id}))}  />} 
               className='attribute-name'
               label={getValByLang(val.name,language)} 
              /> )
        })
      }
      { limit < attributesVal.length && <ReadMore limit = {limit} counter={counter} setLimit={setLimit} />}
    </FormGroup>
    <Divider className='mt-2 mb-2' />
    </>
  );
}