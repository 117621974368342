
// Sidebar.js
import { Box } from '@mui/material';
import ButtonChat from './ButtonChat';
import Sidebar from './Sidebar';
import { setBadgeContentChat, setIsLoadingChat } from '../../../services/chat/chatSlice';
import { useDispatch } from 'react-redux';
import { setIsOpen } from '../../../services/chat/chatSlice';
import { useSelector } from 'react-redux';

const ChatWidget = () => {
  const {isOpen} = useSelector(state => state.chat);
  const dispatch = useDispatch();


  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    
    dispatch(setBadgeContentChat(0)); // reset badge chatting
    dispatch(setIsLoadingChat(true));
    dispatch(setIsOpen(open));
  };

  return (
    <Box>
      <ButtonChat open={isOpen}  actionHandle = {toggleDrawer}  />
      <Sidebar open={isOpen} toggleDrawer={toggleDrawer} />
    </Box>
  );
};

export default ChatWidget;

