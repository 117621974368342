import { Box, Typography } from "@mui/material";
import Translation from "../../utils/Translation";
import { memo } from "react";

function MainTitle(props) {
  return (
    <Box className="main-title">
      <Typography variant="h4" component="h4" className='font-weight-600 mb-1 mt-1'>

         <Translation data={props.title} />
        
      </Typography>
    </Box>
   
  )
}

export default memo(MainTitle)
