import React, { useState } from 'react';
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import {CircularProgress, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { attrNameFullObject, getValByLang } from '../../../utils/helpers';
import Translation from '../../../utils/Translation';
import AddAttribute from './AddAttribute';
import { sleep } from './BrandInput';
import { Controller } from "react-hook-form";
import { useSelector } from 'react-redux';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ColorInput({idx,colors,language,control,register,setValue}) {

 const [loading, setLoading] = useState(false);
 const [AddAttr,setAddAttr] = useState(false);
 const [searchTerm, setSearchTerm] = useState(''); // State for search term
 const [options,setOptions] = useState(colors);
 const {sellerInfo} = useSelector((state) => state.seller);   

 const handleAddNewAttr = () =>{
    setValue(`attributes.${idx}.color`,[{id:null,name: attrNameFullObject(searchTerm), store_id:sellerInfo.id ,new:true}]);
    setSearchTerm('');
    setAddAttr(false);
}

const handleInputChange =  async(_, value, reason) => {
  
    if (reason === "input" || reason === "selectOption" || reason === "clear") {

        setOptions([]); // Update options
        setLoading(true);
        await sleep(1); // Simulate delay

        setSearchTerm(value); // Update search term
        const filteredColors = colors.filter((color) =>
            getValByLang(color.name, language).toLowerCase().includes(value.toLowerCase())
            );
            setOptions(filteredColors.slice(0,25)); // Update options
            setAddAttr(filteredColors.length > 0 ? false:true);

            setLoading(false);
        }
  };

  const  handleSelection = (selectedOptions) => { 

    if(selectedOptions.length > 0){
        setValue(`attributes.${idx}.color`,[selectedOptions[selectedOptions.length - 1]]);
    }else{
        setValue(`attributes.${idx}.color`,[]);
    }
  }


  return (
    <>
    <Controller
        name={`attributes.${idx}.color`}
        control={control}
        render={({ field: { onChange, value } }) => (
            <Autocomplete
                multiple
                options={options}
                getOptionLabel={(option) => getValByLang(option?.name, language)}
                inputValue={searchTerm} // Bind searchTerm to inputValue
                onInputChange={handleInputChange} // Trigger fetch on input change
                forcePopupIcon={!!options.length} // Show dropdown only if options exist
                value={value || []}
                onChange={(_, selectedOptions) => {
                    onChange(selectedOptions); // Update React Hook Form value
                    handleSelection(selectedOptions); // Call custom action
                }}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    />
                    {getValByLang(option.name, language)}
                </li>
                )}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label={<Translation data="store_seller.color" />}
                    placeholder=""
                        InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                        <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </>
                        ),
                }}
                />
                )}
            />
        )}
    />

    {AddAttr  && 
        <AddAttribute handleAddNewAttr= {handleAddNewAttr} />
        }
    </>
  )
}

