import { memo } from "react";
import { FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import Translation from "../../../utils/Translation";
import { Controller } from "react-hook-form";

const ProductAbilityCheckbox = ({ setValue , control }) => {

  const handleChange = (key,value) =>  setValue(key, !value); // Update form state with only one value
   
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend"><Translation data="store_seller.ability" /></FormLabel>
      <FormGroup sx={{display:"flow"}}>
        <Controller
          key={"returnable"}
          name="returnable"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.value} // Check only if this is the selected value
                  onChange={() => handleChange("returnable",field.value)} // Update selection
                />
              }
              label={<Translation data={`store_seller.ability_returnable`} />}
            />
          )}
        />

        <Controller
          key={"replaceable"}
          name="replaceable"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.value} // Check only if this is the selected value
                  onChange={() => handleChange("replaceable",field.value)} // Update selection
                />
              }
              label={<Translation data={`store_seller.ability_replaceable`} />}
            />
          )}
        />
 
      </FormGroup>
    </FormControl>
  );
};

export default memo(ProductAbilityCheckbox);

