import React from 'react'
import AddressModal from '../../components/modal/AddressModal'
import{useEffect, useState}  from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { createAddressAsync, setStatusAddress } from '../../services/address/addressSlice';
import {  Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Translation from '../../utils/Translation';
import { setCheckAddressInUae,setDataAddress } from '../../services/address/addressSlice';
import RefreshAddressButton from '../../components/button/RefreshAddressButton';

export default function AddSection()  {
    
  const auth = useSelector(state => state.auth);
  const dynamicState = useSelector(state => state.dynamicConfig);
  const {errorAddress,status}= useSelector(state => state.address)
  const [open, setOpen] = useState(false);
  
  const handleClose = () => setOpen(false);
  const [submit,setSubmit] = useState(false);
  const [msg ,setMess] = useState(null);
  const dispatch= useDispatch();

  const handleOpen = () => {
   
    // reset the address object
    dispatch(setDataAddress([]));

    setOpen(true);
    setStatusAddress({status:"idle"})
    setSubmit(false);
    setMess(null);

    dispatch(setCheckAddressInUae(-1));
  }

  
  const handleOnSubmit = ((e,object) => {

    e.preventDefault();

    let point = object.coordinates.lat + "," + object.coordinates.lng
    let data = {
      coordinates: point,
      mobile: object.mobile,
      friendly_name: object.friendlyName,
      villa_bldng_no: object.buildingNo,
      street: object.street,
      area: object.area,
      location_address: "("+point+")",
      city: object.city,
      formatted_address: object.city,
      emirate : object.emarites
    };
   
    dispatch(createAddressAsync({auth,dynamicState,data}));
    setSubmit(true);
  });

  useEffect(()=>{ 

    if(submit === true){

      if(status === "succeeded"){
        setMess("Add Successfully");
        handleClose();
      }
      
      status === "failed" &&  setMess(errorAddress);
    }
   
  },[status]);

 

  return (
    <>
    <Stack direction="row-reverse" className='add-new-address'>
        <Button variant="contained" className='font-weight-600'  onClick={handleOpen}  endIcon={<AddIcon />}>
           <Translation data="request.add"   />
        </Button>

        <RefreshAddressButton />
     </Stack>

    <AddressModal 
     error={errorAddress}
     status={status}
     open={open}
     msg={msg}
     handleOpen={handleOpen}
     handleClose={handleClose}
     handleOnSubmit={handleOnSubmit}
     submit={submit}   
     update={false}
    />
    </>
    
  )
}
