import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MainTitle from "../../../components/headline/MainTitle";
import BasicInfo from "../product/BasicInfo";
import Attributes from "../product/Attributes";
import Details from "../product/Details";
import { useDispatch, useSelector } from "react-redux";
import ResultSend from "../../../components/alert/ResultSend";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../../../config/firebase-config";
import Translation from "../../../utils/Translation";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowCircleLeftOutlined } from "@mui/icons-material";
import {
  createAttributeAsync,
  createProductAsync,
  loadSingleProductForSellerAsync,
  setActiveStep,
  setAddedNewProduct,
  setAttrName,
  setErrorStore,
  setNewAttr,
  setProductDetails,
  updateProductAsync,
} from "../../../services/store/storeSlice";
import { useForm, useWatch } from "react-hook-form";
import { v1 as uuid } from "uuid";
import {
  areArraysEqualUnordered,
  checkValidString,
  cleanNonASCIICharacters,
  isJson,
  keyLang,
  removeHtmlCharacter
} from "../../../utils/helpers";
import { allLinks } from "../../../utils/helpers";
import { useFieldArray } from "react-hook-form";
import { CircularProgress } from "@mui/material";

const msgSuccessfully = <Translation data="store_seller.msg_added" />;
const msgSuccessfullyUpdate = <Translation data="store_seller.msg_update" />;
const steps = [
  "store_seller.basic_info",
  "store_seller.attributes",
  "store_seller.details",
];
const objectString = { en: "", ar: "" };

export default function CreateOrUpdateProduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { language } = useSelector((state) => state.global);
  const { sellerInfo } = useSelector((state) => state.seller);
  const store = useSelector((state) => state.store);
  const { productDetails, isLoading, attributesLoaded } = useSelector(
    (state) => state.store
  );
  const { data } = useSelector((state) => state.address);
  const auth = useSelector((state) => state.auth);
  const dynamicState = useSelector((state) => state.dynamicConfig);
  const [updatePage, setUpdatePage] = useState(
    window.location.pathname.includes(allLinks[17]) ? true : false
  );
  const [getProduct, setGetProduct] = useState(false);
  const [lastCatgProduct, setLastCatgProduct] = useState(null);

  const newDetailTemplate = {
    inventory: [],
    color: [],
    size: [],
    quantity: "",
    regular_price: "",
    sale_price: "",
    dimensions: {
      length: null,
      lengthUnit: null,
      weight: null,
      weightUnit: null,
      width: null,
      widthUnit: null,
      height: null,
      heightUnit: null,
    },
    images: [],
    customAttributes: [{ name: objectString, value: objectString }],
  };

  const [submit, setSubmit] = useState(false);
  const [msg, setMsg] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [fileImg, setFileImg] = useState(null);
  const [showDimension, setShowDimension] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [displaySpecialWarranty, setdisplaySpecialWarranty] = useState(false);

  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      productName_en: "",
      productName_ar: "",
      description_en: "",
      description_ar: "",
      mainImg: "",
      productType: "new",
      warrantyPeriod: 0,
      specialWarranty: "",
      returnable: false,
      replaceable: false,
      specifications_en: {},
      specifications_ar: {},
      categories: [], // The default value for the Autocomplete field
      categories_update: [], // The default value for the Autocomplete field
      brands: [], // Default value for the brands field
      tags: [], // Default value for the tags field
      attributes: [newDetailTemplate],
    },
  });
  const watchedAttributes = useWatch({ control, name: "attributes" });

  const {
    fields,
    append,
    remove,
    replace: replaceEn,
  } = useFieldArray({
    control,
    name: "specifications_en",
  });

  const {
    fields: fieldsAttr,
    append: appendAttr,
    remove: removeAttr,
    replace: replaceAr,
  } = useFieldArray({
    control,
    name: `specifications_ar`,
  });

  const getVal = (englishName, arabicName) =>
    JSON.stringify({
      en: checkValidString(englishName) ? englishName : arabicName,
      ar: checkValidString(arabicName) ? arabicName : englishName,
    });

  const handleUpload = async (image) => {
    return new Promise((resolve, reject) => {
      const imageRef = storageRef(
        storage,
        `store/${sellerInfo?.id}/products/${uuid()}`
      );

      uploadBytes(imageRef, image)
        .then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((imgUrl) => {
              resolve(imgUrl); // Return the uploaded image URL
            })
            .catch((error) => {
              console.error("Error getting URL:", error);
              reject(error);
            });
        })
        .catch((error) => {
          console.error("Upload error:", error);
          reject(error);
        });
    });
  };

  const getPriorityAttr = (detailFirst) => {
    if (detailFirst?.color?.length > 0) return "color";
    if (detailFirst?.size?.length >0) return "size";

    if (detailFirst?.customAttributes?.length > 0)
      if (detailFirst?.customAttributes[0]?.name.length > 0)
        if (detailFirst?.customAttributes[0]?.name[0]?.new)
          return null; // not created priority attribute yet
        else return String(detailFirst?.customAttributes[0]?.name[0]?.id);

    return "color";
  };

  const onSubmit = async (Formdata) => {
    console.log("Formdata :", Formdata);
    setSubmit(true);
    setMsg("");
    dispatch(setErrorStore(false));

    let mainImg = "";

    // upload main image product
    if (fileImg) mainImg = await handleUpload(fileImg);
    else mainImg = imagePreview;
    // upload image details for product

    let brandId = Formdata.brands.length > 0 ? Formdata.brands[0]?.id : null;
    let tagIds = Formdata.tags
      .filter((val) => val.id !== null)
      .map((val) => val.id);
    let colorIds = [];
    let sizeIds = [];

    // get brand
    if (!brandId && Formdata.brands?.length > 0 && Formdata.brands[0]?.new) {
      let attrName = "brands";
      let dataAttr = Formdata.brands[0];
      dispatch(setAttrName(attrName));
      dispatch(setNewAttr(dataAttr));
      let brandResult = await dispatch(
        createAttributeAsync({ auth, dynamicState, attrName, dataAttr })
      );
      brandId = brandResult.payload?.id;
      if (!brandId) return; // something error when enter brand
    }

    let dataTags = Formdata.tags.filter((tag) => tag?.new === true);
    if (dataTags.length > 0) {
      let attrName = "tags";
      let dataAttr = [];
      dispatch(setAttrName(attrName));
      for (let i = 0; i < dataTags.length; i++) {
        dataAttr = dataTags[i];
        dispatch(setNewAttr(dataAttr));
        let tagResult = await dispatch(
          createAttributeAsync({ auth, dynamicState, attrName, dataAttr })
        );
        tagIds = [...tagIds, tagResult.payload.id];
        if (!tagResult.payload.id) return; // something error when enter tag
      }
    }

    let specEn = { key: "", value: "" };
    let specAr = { key: "", value: "" };

    let lengthSpecification =
      Formdata.specifications_en.length > Formdata.specifications_ar.length
        ? Formdata.specifications_en.length
        : Formdata.specifications_ar.length;

    let allSpecifications =
      lengthSpecification === 0 ? null : { en: {}, ar: {} };

    for (let i = 0; i < lengthSpecification; i++) {
      specEn = Formdata.specifications_en[i];
      specAr = Formdata.specifications_ar[i];

      allSpecifications.en = {
        ...allSpecifications.en,
        [specEn?.key]: specEn?.value,
      };
      allSpecifications.ar = {
        ...allSpecifications.ar,
        [specAr?.key]: specAr?.value,
      };
    }

    if (allSpecifications)
      allSpecifications = JSON.stringify(allSpecifications);

    let categoryVal = [];
    if (
      Formdata?.categories[Formdata?.categories.length - 1]?.length === 0 ||
      !Formdata?.categories[Formdata?.categories.length - 1]
    ) {
      categoryVal = [lastCatgProduct];
    } else {
      categoryVal = [Formdata?.categories[Formdata?.categories.length - 1].id];
    }

    let imagesDetails = [];
    let imageDeatilsVal = null;
    let customAttr = [];
    let data = {
      id: updatePage ? id : null,
      name: getVal(Formdata.productName_en, Formdata.productName_ar),
      description: getVal(
        cleanNonASCIICharacters(Formdata.description_en),
        cleanNonASCIICharacters(Formdata.description_ar)
      ),
      store_id: sellerInfo?.id,
      img: mainImg,
      priority_attr: getPriorityAttr(Formdata.attributes[0]),
      specification: allSpecifications,
      catg_ids: categoryVal,
      brand_ids: [brandId],
      tag_ids: tagIds,
      productType: Formdata?.productType ?? "new",
      warranty: Number(Formdata?.warrantyPeriod ?? 0),
      special_warranty:
        Number(Formdata?.warrantyPeriod) > 0
          ? Formdata?.specialWarranty ?? null
          : null,
      returnable: Formdata?.returnable ? true : false,
      replaceable: Formdata?.replaceable ? true : false,
      details: [],
    };

    if (Formdata?.attributes.length > 0)
      for (let i = 0; i < Formdata?.attributes.length; i++) {
        imageDeatilsVal = [];
        customAttr = [];

        imagesDetails = getValues(`attributes.${i}.images`);
        if (imagesDetails?.length > 0) {
          for (let j = 0; j < imagesDetails.length; j++) {
            if (!imagesDetails[j]?.url) {
              // check is object or file
              imageDeatilsVal.push({
                url: await handleUpload(imagesDetails[j]),
                type: 1,
              });
            } else {
              imageDeatilsVal.push({
                url: imagesDetails[j]?.url,
                type: imagesDetails[j]?.type,
              });
            }
          }
        }

        if (Formdata?.attributes[i].color?.length > 0) {
          if (Formdata?.attributes[i].color[0]?.new) {
            let attrName = "colors";
            let dataAttr = Formdata?.attributes[i].color[0];
            dispatch(setAttrName(attrName));
            dispatch(setNewAttr(dataAttr));
            let colorResult = await dispatch(
              createAttributeAsync({ auth, dynamicState, attrName, dataAttr })
            );
            if (!colorResult.payload.id) return; // something error when enter color
            colorIds.push(colorResult.payload?.id);
          } else colorIds.push(Formdata?.attributes[i].color[0].id);
        }

        if (Formdata?.attributes[i].size?.length > 0) {
          if (Formdata?.attributes[i].size[0]?.new) {
            let attrName = "sizes";
            let dataAttr = Formdata?.attributes[i].size[0];
            dispatch(setAttrName(attrName));
            dispatch(setNewAttr(dataAttr));
            let sizeResult = await dispatch(
              createAttributeAsync({ auth, dynamicState, attrName, dataAttr })
            );
            if (!sizeResult.payload.id) return; // something error when enter size
            sizeIds.push(sizeResult.payload?.id);
          } else sizeIds.push(Formdata?.attributes[i].size[0].id);
        }

        if (Formdata?.attributes[i].customAttributes?.length > 0) {
          let customAttributtes = Formdata?.attributes[i].customAttributes;
          let values = null;

          for (let j = 0; j < customAttributtes.length; j++) {
            if (customAttributtes[j]?.name?.length > 0) {
              if (customAttributtes[j]?.name[0]?.new)
                values = {
                  key: customAttributtes[j]?.name[0]?.name,
                  priority: !data.priority_attr ? true : false, // not created priority attribute yet
                  details: {},
                };
              else
                values = {
                  id: customAttributtes[j]?.name[0]?.id,
                  priority: false,
                  details: {},
                };
            }

            if (customAttributtes[j]?.value?.length > 0) {
              if (customAttributtes[j]?.value[0]?.new)
                values.details = {
                  value: customAttributtes[j]?.value[0]?.value,
                };
              else
                values.details = {
                  id: customAttributtes[j]?.value[0]?.value_id,
                };
            }

            customAttributtes[j]?.name?.length > 0 &&
              customAttributtes[j]?.value?.length > 0 &&
              customAttr.push(values);
          }
        }

        // check dimensions

        let dimensions =
          !Formdata?.attributes[i]?.dimensions?.length &&
          !Formdata?.attributes[i]?.dimensions?.lengthUnit &&
          !Formdata?.attributes[i]?.dimensions?.weight &&
          !Formdata?.attributes[i]?.dimensions?.weightUnit &&
          !Formdata?.attributes[i]?.dimensions?.width &&
          !Formdata?.attributes[i]?.dimensions?.widthUnit &&
          !Formdata?.attributes[i]?.dimensions?.height &&
          !Formdata?.attributes[i]?.dimensions?.heightUnit
            ? null
            : JSON.stringify(Formdata?.attributes[i]?.dimensions);

        data.details.push({
          storage_id: Formdata?.attributes[i].inventory[0]?.fav_id,
          count: Number(Formdata?.attributes[i].quantity),
          sold_amount: 0,
          color_id: colorIds[i],
          size_id: sizeIds[i],
          default_price: Number(Formdata?.attributes[i].regular_price),
          price: (Formdata?.attributes[i]?.sale_price && Formdata?.attributes[i]?.sale_price  > 0)
            ? Number(Formdata?.attributes[i]?.sale_price)
            : Number(Formdata?.attributes[i].regular_price),
          dimensions: dimensions,
          images: imageDeatilsVal,
          custom_attribute: customAttr,
        });
      }

    console.log("data :", data);

    if (updatePage) dispatch(updateProductAsync({ auth, dynamicState, data }));
    else dispatch(createProductAsync({ auth, dynamicState, data }));
  };

  const handleNext = () => dispatch(setActiveStep(store.activeStep + 1));
  const handleBack = () => dispatch(setActiveStep(store.activeStep - 1));

  const backToStorePage = () => {
    navigate(`/${allLinks[4]}`);
  };

  const handleNextButton = useCallback(() => {
    if (store.activeStep === 0) {
      if (
        ((language === keyLang[0] &&
          checkValidString(getValues("productName_en"))) ||
          (language === keyLang[1] &&
            checkValidString(getValues("productName_ar")))) &&
        imagePreview
      )
        return false;
    }

    if (store.activeStep === 1) {
      if (
        (getValues("categories")?.length > 0 ||
          getValues("categories_update")?.length > 0) &&
        getValues("brands")?.length > 0
      )
        return false;
    }

    if (store.activeStep === 2) {
      let allAttributes = getValues("attributes");
      let allKeyAvailable = [];
      let schemaAttr = {
        size_id: null,
        color_id: null,
        customAttributes: [{ key_id: null, value_id: null }],
      };

      // check attributes
      if (allAttributes?.length > 0) {
        let isNotValid = false;
        let allValues = [];

        allAttributes.forEach((attr, index) => {
          //check custom attributes ---------------

          // build first schema
          if (index === 0) {
            schemaAttr.size_id = attr?.size?.id;
            schemaAttr.color_id = attr?.color?.id;

            for (let i = 0; i < attr.customAttributes.length; i++) {
              // check duplicate key
              if (
                attr.customAttributes[i]?.name &&
                allKeyAvailable.includes(attr.customAttributes[i]?.name[0]?.id)
              ) {
                isNotValid = true;
                return;
              }

              if (attr.customAttributes[i]?.name)
                allKeyAvailable.push(attr.customAttributes[i]?.name[0]?.id);
            }
            schemaAttr.customAttributes = attr?.customAttributes;
          } else {
            // check unified schema
            if (
              (schemaAttr?.color_id && attr?.color?.id) ||
              (!schemaAttr?.color_id && !attr?.color?.id)
            )
              if (
                (schemaAttr?.size_id && attr?.size?.id) ||
                (!schemaAttr?.size_id && !attr?.size?.id)
              ) {
                if (
                  attr.customAttributes &&
                  attr.customAttributes?.length !== 0
                ) {
                  let checkKeysValid = attr.customAttributes
                    .filter((val) => val?.name)
                    .map((val) => val?.name[0]?.id);

                  if (
                    !areArraysEqualUnordered(checkKeysValid, allKeyAvailable)
                  ) {
                    // check equal keys
                    isNotValid = true;
                    return;
                  }
                }
              }
          }

          // check custom attribute value empty or invalid
          let checkValueNotValid = [];
          if (attr.customAttributes && attr.customAttributes?.length !== 0)
            checkValueNotValid = attr.customAttributes.filter(
              (val) =>
                val?.name &&
                val?.value &&
                (val?.name[0]?.id || val?.name[0]?.new) &&
                !val?.value[0]?.value_id &&
                !val?.value[0]?.new
            );
          if (checkValueNotValid[0]) {
            isNotValid = true;
            return;
          }
          // check valid another attributes
          if (
            attr.inventory?.length === 0 ||
            Number(attr.quantity) <= 0 ||
            Number(attr.regular_price) <= 0 ||
            Number(attr.sale_price) < 0 ||
            Number(attr.regular_price) < Number(attr.sale_price)
          ) {
            isNotValid = true;
            return;
          }

          // save values  inside allValues
          allValues.push({
            inventory_id: attr?.inventory
              ? attr?.inventory[0]?.fav_id
              : undefined,
            color_id: attr?.color ? attr?.color[0]?.id : undefined,
            size_id: attr?.size ? attr?.size[0]?.id : undefined,
            customAttributes:
              attr.customAttributes && attr.customAttributes?.length !== 0
                ? attr.customAttributes.map((val) => val?.value[0]?.value_id)
                : undefined,
          });
        });

        // check duplicated values
        let lastVal = null;
        if (!isNotValid && allValues.length > 1)
          for (let i = 0; i < allValues.length; i++) {
            if (
              lastVal &&
              (lastVal?.inventory_id !== allValues[i]?.inventory_id ||
                lastVal?.color_id !== allValues[i]?.color_id ||
                lastVal?.size_id !== allValues[i]?.size_id ||
                (lastVal?.customAttributes &&
                  allValues[i]?.customAttributes?.length > 0 &&
                  lastVal?.customAttributes[0] !==
                    allValues[i]?.customAttributes[0]))
            )
              break;

            lastVal = allValues[i];
            if (i === allValues.length - 1) {
              isNotValid = true;
            }
          }

        return isNotValid;
      }
    }

    return true;
  });

  useEffect(() => {
    setMsg("");
    dispatch(setErrorStore(false));

    if (handleNextButton()) {
      if (store.activeStep === 2) {
        if (keyLang[0] === language)
          setMsg(
            removeHtmlCharacter(
              "Attributes Not Valid\nRules must :<ul><li> Add at least one detaill for product </li> <li> Not find duplicate detaills  </li> <li> Select inventory for each attribute  </li>  <li> Select the quantity for each attribute  </li>  <li> Regular price  bigger than the sale price  </li> <li> Choose dimensions for each attribute  </li><li> Unified custom attributes </li></ul>"
            )
          );
        else
          setMsg(
            removeHtmlCharacter(
              "السمات غير صالحة\nيجب أن تتضمن القواعد ما يلي: <ul><li> إضافة تفاصيل واحدة على الأقل للمنتج </li> <li> عدم العثور على تفاصيل مكررة </li> <li> تحديد المخزن لكل سمة </li> <li> تحديد الكمية لكل سمة </li> <li> السعر العادي أكبر من سعر البيع </li> <li> اختيار الأبعاد لكل سمة </li><li> سمات مخصصة موحدة </li></ul>"
            )
          );
      }

      dispatch(setErrorStore(true));
    }
  }, [
    watch("productName_en"),
    watch("productName_ar"),
    imagePreview,
    watch("categories"),
    watch("brands"),
    watchedAttributes,
  ]);

  useEffect(() => {
    if (submit) {
      if (store.statusStore === "succeeded")
        setMsg(updatePage ? msgSuccessfullyUpdate : msgSuccessfully);
      if (store.statusStore === "failed") {
        setMsg(store.errorStore);
        setSubmit(false);
      }
    } else {
      dispatch(setAddedNewProduct(false));
    }
  }, [store.statusStore, dispatch]);

  useEffect(() => {
    dispatch(setActiveStep(0)); // reset steps when enter to page
    setProductDetails(null); // reset whole product details

    const fetchData = async (id) =>
      await dispatch(
        loadSingleProductForSellerAsync({ auth, dynamicState, id })
      ); // get product details

    let productVal = store.products.filter(
      (val) => Number(val.id) === Number(id)
    );

    if (productVal.length > 0) dispatch(setProductDetails(productVal[0]));
    else if (!getProduct && updatePage) {
      setGetProduct(true);
      fetchData(id);
    }
  }, [dispatch]);

  // update form
  const updateFormValues = useCallback(() => {
    if (updatePage && productDetails) {
      if (productDetails?.id) {
        let productName = JSON.parse(productDetails.name);
        setValue("productName_en", productName.en);
        setValue("productName_ar", productName.ar);

        let descriptionName = JSON.parse(productDetails.description);
        setValue("description_en", descriptionName.en);
        setValue("description_ar", descriptionName.ar);

        setImagePreview(productDetails.img);

        setValue(`productType`, productDetails.productType);

        setValue(`warrantyPeriod`, Number(productDetails.warranty));
        setdisplaySpecialWarranty(
          Number(productDetails.warranty) > 0 ? true : false
        );

        setValue(`specialWarranty`, productDetails.special_warranty);
        setValue(`returnable`, productDetails.returnable ? true : false);
        setValue(`replaceable`, productDetails.replaceable ? true : false);

        let spcificationVal = isJson(productDetails.specification)
          ? JSON.parse(productDetails.specification)
          : null;

        if (spcificationVal) {
          let spcificationValEn = Object.entries(spcificationVal.en).map(
            ([key, value]) => ({ key, value })
          );
          let spcificationValAr = Object.entries(spcificationVal.ar).map(
            ([key, value]) => ({ key, value })
          );

          replaceEn(
            spcificationValEn.map((spec) => ({
              key: spec.key || "",
              value: spec.value || "",
            }))
          );

          replaceAr(
            spcificationValAr.map((spec) => ({
              key: spec.key || "",
              value: spec.value || "",
            }))
          );
        }

        let allCategory = [];
        let category = store.categories_without_arrange.filter((val) =>
          productDetails.categories.includes(val.id)
        );

        setLastCatgProduct(category.length > 0 && category[0]?.id);

        if (category?.length > 0 && category[0].parent_id) {
          let categoryParent = store.categories_without_arrange.filter(
            (val) => val.id === category[0].parent_id
          );
          allCategory = [categoryParent[0], category[0]];
        } else {
          allCategory = category;
        }
        setValue("categories_update", allCategory);

        let brand = store.brands.filter((val) =>
          productDetails.brands.includes(val.id)
        );
        setValue("brands", [brand[0]]);

        let tags = store.tags.filter((val) =>
          productDetails.tags.includes(val.id)
        );
        setValue("tags", tags);

        let details = productDetails.details;
        for (let i = 0; i < details.length; i++) {
          let storage = data.filter(
            (val) => val.fav_id === details[i].storage_id
          );
          let color = store.colors.filter(
            (val) => val.id === details[i].color_id
          );
          let size = store.sizes.filter((val) => val.id === details[i].size_id);

          setValue(`attributes.${i}.inventory`, storage);
          if (color.length > 0) setValue(`attributes.${i}.color`, color);
          if (size.length > 0) setValue(`attributes.${i}.size`, size);

          setValue(`attributes.${i}.quantity`, details[i].count);
          setValue(`attributes.${i}.regular_price`, details[i].default_price);
          setValue(`attributes.${i}.sale_price`, details[i].price);

          if (isJson(details[i].dimensions)) {
            let dimensions = JSON.parse(details[i].dimensions);
            if (
              dimensions.length ||
              dimensions.lengthUnit ||
              dimensions.weight ||
              dimensions.weightUnit ||
              dimensions.width ||
              dimensions.widthUnit ||
              dimensions.height ||
              dimensions.heightUnit
            ) {
              setShowDimension(true);
              setValue(`attributes.${i}.dimensions.length`, dimensions.length);
              setValue(
                `attributes.${i}.dimensions.lengthUnit`,
                dimensions.lengthUnit
              );
              setValue(`attributes.${i}.dimensions.weight`, dimensions.weight);
              setValue(
                `attributes.${i}.dimensions.weightUnit`,
                dimensions.weightUnit
              );
              setValue(`attributes.${i}.dimensions.width`, dimensions.width);
              setValue(
                `attributes.${i}.dimensions.widthUnit`,
                dimensions.widthUnit
              );
              setValue(`attributes.${i}.dimensions.height`, dimensions.height);
              setValue(
                `attributes.${i}.dimensions.heightUnit`,
                dimensions.heightUnit
              );
            }
          }

          setValue(`attributes.${i}.images`, details[i].images);

          for (let j = 0; j < details[i].custom_attribute_keys?.length; j++) {
            let customAttrKey = store.custom_attributes.filter(
              (val) => details[i].custom_attribute_keys[j] === val.id
            );
            if (customAttrKey.length > 0) {
              let CustomValue = customAttrKey[0].values.filter((val) =>
                details[i].custom_attribute_values.includes(val.value_id)
              );
              setValue(
                `attributes.${i}.customAttributes.${j}.name`,
                customAttrKey
              );
              setValue(
                `attributes.${i}.customAttributes.${j}.value`,
                CustomValue
              );
            }
          }
        }
      }
    }
  }, [productDetails, attributesLoaded, dispatch]);

  useEffect(() => {
    updateFormValues();
  }, [updateFormValues]);

  return (
    <Box
      className="add-request"
      sx={{
        maxWidth: "100%",
      }}
    >
      <Box sx={{ display: "inline-flex" }}>
        <Button onClick={() => navigate(`/${allLinks[4]}`)}>
          <ArrowCircleLeftOutlined className="back-to-home" />
        </Button>

        {updatePage ? (
          <Box>
            <MainTitle title="store_seller.update_product" />
          </Box>
        ) : (
          <Box>
            <MainTitle title="store_seller.add_new_product" />
          </Box>
        )}
      </Box>

      {msg !== "" && (
        <Box>
          <ResultSend msg={msg} error={store.errorStore ? true : false} />
        </Box>
      )}

      {productDetails.status === "rejected" && (
        <Box className="mt-2">
          <Translation data="order_seller.rejected_reason" />{" "}
          <ResultSend msg={productDetails?.rejected_reason} error={true} />
        </Box>
      )}

      {isLoading ? (
        <CircularProgress className="product-preloader-position" />
      ) : !productDetails.id && updatePage ? (
        <Box sx={{ p: 8 }}>
          <Translation data="store.not_found_any_product" />{" "}
        </Box>
      ) : (
        <Box sx={{ width: "100%", marginTop: "2%" }}>
          <Stepper activeStep={store.activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps} className="bold">
                    {" "}
                    <Translation data={label} />{" "}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <form onSubmit={handleSubmit(onSubmit)}>
            {store.activeStep === steps.length ? (
              <>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  <Translation data="store_seller.step_complete" />
                </Typography>

                <Box
                  sx={{ display: "flex", flexDirection: "row", pt: 2, gap: 2 }}
                >
                  <Box sx={{ flex: "1 1 auto" }} className="button-reset" />
                  {!store.addedNewProduct ? (
                    <>
                      <Button
                        variant="outlined"
                        disabled={store.activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        <Translation data="store_seller.back" />
                      </Button>
                      <Button
                        type="submit"
                        disabled={submit}
                        variant="contained"
                      >
                        <Translation data="store_seller.submit" />
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => backToStorePage()}
                    >
                      <Translation data="store_seller.back_to_store" />
                    </Button>
                  )}
                </Box>
              </>
            ) : (
              <>
                <Box className="form-store-seller">
                  {store.activeStep === 0 && (
                    <BasicInfo
                      fields={fields}
                      remove={remove}
                      append={append}
                      fieldsAttr={fieldsAttr}
                      removeAttr={removeAttr}
                      appendAttr={appendAttr}
                      control={control}
                      register={register}
                      errors={errors}
                      imagePreview={imagePreview}
                      setImagePreview={setImagePreview}
                      setFileImg={setFileImg}
                      setValue={setValue}
                      language={language}
                      getValues={getValues}
                      displaySpecialWarranty={displaySpecialWarranty}
                      setdisplaySpecialWarranty={setdisplaySpecialWarranty}
                    />
                  )}
                  {store.activeStep === 1 && (
                    <Attributes
                      getValues={getValues}
                      setValue={setValue}
                      control={control}
                      language={language}
                      register={register}
                    />
                  )}
                  {store.activeStep === 2 && (
                    <Details
                      showDimension={showDimension}
                      setShowDimension={setShowDimension}
                      newDetailTemplate={newDetailTemplate}
                      control={control}
                      register={register}
                      errors={errors}
                      getValues={getValues}
                      setValue={setValue}
                      language={language}
                      colors={store.colors.slice(0, 100)}
                      sizes={store.sizes.slice(0, 100)}
                      inventories={data}
                      watch={watch}
                      selectedImages={selectedImages}
                      setSelectedImages={setSelectedImages}
                    />
                  )}
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    variant="outlined"
                    disabled={store.activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    <Translation data="store_seller.back" />
                  </Button>

                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button
                    disabled={handleNextButton()}
                    variant="contained"
                    onClick={handleNext}
                  >
                    {store.activeStep === steps.length - 1 ? (
                      <Translation data="store_seller.finish" />
                    ) : (
                      <Translation data="store_seller.next" />
                    )}
                  </Button>
                </Box>
              </>
            )}
          </form>
        </Box>
      )}
    </Box>
  );
}
