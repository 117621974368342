import { useEffect, useState } from 'react'; 
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import AutoCompleteSearch from '../action/AutoCompleteSearch';
import ReadMore from '../action/ReadMore';
import { getValByLang } from '../../../utils/helpers';
import { useSelector } from 'react-redux';
import {  Divider } from '@mui/material';
import { setAttributesSelected } from '../../../services/store/storeSlice';
import { useDispatch } from 'react-redux';

export default function CustomAttributeList({attributes,values}) {

  const counter = 5
  const dispatch = useDispatch()
  const {language} = useSelector(store => store.global)
  const {attributesSelected} = useSelector(store => store.store)
  const [limit,setLimit] = useState(counter)
  const [searchVal,setSearchVal] = useState([])
  const [attributesVal,setAttributesVal] = useState([])
  const handleSearch = (searchVal)  => setSearchVal(searchVal.val)
  
  useEffect(()=>{

    if(searchVal.length !== 0)
      setAttributesVal(searchVal)
    else
      setAttributesVal(attributes.values.filter((val) => values.includes(val.value_id)))

  },[searchVal])


  return (
    <>
    <FormGroup>
    <Typography component="h3" variant='b'> {getValByLang(attributes.name,language)} :</Typography>
    <AutoCompleteSearch language = {language} list = {attributesVal} keyVal = {"Attributes"}  setAttributes = {handleSearch}  />
    { 
      attributesVal.filter((val) => attributesSelected.Custom_Value.includes(val.value_id))
         .map((val,idx) => {
            return (<FormControlLabel
            key = {idx} 
            control={<Checkbox checked={attributesSelected.Custom_Value.includes(val.value_id)}
            onChange={() => dispatch(setAttributesSelected({key:val.value, val:val.value_id}))}  />} 
            className='attribute-name'
            label={getValByLang(val.value,language)} 
            />)
        })
    }
    { 
      attributesVal.slice(0,limit).filter((val) => (!attributesSelected.Custom_Value.includes(val.value_id)))
        .map((val,idx) => {
            return (<FormControlLabel
            key = {idx} 
            control={<Checkbox checked={attributesSelected.Custom_Value.includes(val.value_id)}
            onChange={() => dispatch(setAttributesSelected({key:val.value, val:val.value_id}))}  />} 
            className='attribute-name'
            label={getValByLang(val.value,language)} 
            />)
        })
    }
    { limit < attributesVal.length && <ReadMore limit = {limit} counter={counter} setLimit={setLimit} />}
    </FormGroup>
    <Divider className='mt-2 mb-2' />
    </>
  );
}