import React, { useMemo } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";
import Translation from "../../../utils/Translation";
import { useFieldArray } from "react-hook-form";
import ProductVariant from "../../../components/store_seller/product/ProductVariant";
import CustomAttributes from "../../../components/store_seller/product/CustomAttributes";
import { memo } from "react";

function Details({
  newDetailTemplate,
  control,
  register,
  errors,
  setValue,
  getValues,
  language,
  colors,
  sizes,
  inventories,
  showDimension,
  setShowDimension,
  watch,
  selectedImages,
  setSelectedImages
}) {
  const {
    fields: fieldsDetails,
    append: appendDetails,
    remove: removeDetails,
  } = useFieldArray({
    control,
    name: "attributes",
  });

  const hasFields = useMemo(() => fieldsDetails.length > 0, [fieldsDetails]);

  const handleRemoveDetails = (idx) => {

    let details = getValues("attributes");
    removeDetails(idx); // Remove the selected item

    // update another element
    if(idx !== details.length -1)
      for (let i = idx; i < details.length - 1; i++) {
        setValue(
          `attributes.${i}.inventory`,
          getValues(`attributes.${i}.inventory`)
        );
        setValue(
          `attributes.${i}.color`,
          getValues(`attributes.${i}.color`)
        );
        setValue(
          `attributes.${i}.size`,
          getValues(`attributes.${i}.size`)
        );
      }
    
  };

  return (
    <>
      <Box className="details-section">
        {hasFields &&
          fieldsDetails.map((spec, idx) => (
            <Box className="form-details" key={idx}>
              {/* remove all details */}
              <Grid size={{ lg: 2, sm:2, xs: 2 }} className="delete-details">
                <IconButton
                  color="error"
                  onClick={() => handleRemoveDetails(idx)}
                  disabled={idx === 0 ? true : false}
                >
                  <Delete />
                </IconButton>
              </Grid>

              <ProductVariant
                idx={idx}
                inventories={inventories}
                language={language}
                register={register}
                colors={colors}
                sizes={sizes}
                errors={errors}
                showDimension={showDimension}
                setShowDimension={setShowDimension}
                control={control}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
              />

              {/* Custom Attributes */}
              <CustomAttributes
                idx={idx}
                language={language}
                register={register}
                errors={errors}
                control={control}
                setValue={setValue}
                watch={watch}
                getValues={getValues}
                selectedImages = {selectedImages}
                setSelectedImages = {setSelectedImages}
              />
            </Box>
          ))}

        {/*  add new Details */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 3,
            mb: 8,
          }}
          className="icon-arabic"
        >
          <Button
            variant="outlined"
            onClick={() => appendDetails(newDetailTemplate)}
            startIcon={<Add />}
          >
            {<Translation data="store_seller.add_details" />}
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default memo(Details);
