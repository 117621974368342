
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import { memo } from 'react';

function SliderHome() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        effect={'fade'}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[EffectFade, Navigation, Pagination]}
        className="mySwiper">
        <SwiperSlide>
          <img  loading="lazy" src= {require('../../../assets/image/home/slider/1.webp')} />
        </SwiperSlide>
        <SwiperSlide>
        <img loading="lazy" src= {require('../../../assets/image/home/slider/2.webp')} />
        </SwiperSlide>
        <SwiperSlide>
        <img  loading="lazy" src= {require('../../../assets/image/home/slider/3.webp')} />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default memo(SliderHome)