import {
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function PaymentMethod({ setPaymentMethod }) {
  const dispatch = useDispatch();
  const { orders } = useSelector((store) => store.order);
  const [cashOnDelivery, setCashOnDelivery] = useState(false);
  const { t, i18n } = useTranslation();
  const handlePaymentMethodChange = (event) =>
    setPaymentMethod(event.target.value);

  const result = useMemo(() => {
    const result = orders.filter((val) => val.order_status === "delivered");
    result.length > 0 && setCashOnDelivery(true);
  }, [dispatch, orders]);

  return (
    <CardContent>
      <Typography variant="h6" gutterBottom>
        {t("checkout.payment_options")}
      </Typography>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={0}
          name="radio-buttons-group"
        >
          <FormControlLabel
            key={0}
            value={0}
            control={<Radio />}
            label={t("checkout.credit_card")}
            onChange={(event) => handlePaymentMethodChange(event)}
          />

          {cashOnDelivery &&

          <FormControlLabel
            key={1}
            value={1}
            control={<Radio />}
            label={t("checkout.cash_on_delivery")}
            onChange={(event) => handlePaymentMethodChange(event)}
          />

          }
         
        </RadioGroup>
      </FormControl>
    </CardContent>
  );
}

export default memo(PaymentMethod);
