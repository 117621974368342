import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { memo } from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import Grid from '@mui/material/Grid2';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import Translation from '../../utils/Translation';

function Copyright() {
  return (
    <Typography variant="body2" className='copy-right'>
      <Link href="https://deliverit.ae/"><Translation data="layout.copy_right" /></Link>
    </Typography>
  );
}

 function Footer() {
  return (
    <Box   className='footer-background footer'>
      <Container
      className='page_size padding-footer'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: 4, sm: 8 },
          py: { xs: 8, sm: 10 },
          textAlign: { sm: 'center', md: 'left' },
        }}
      >
       
        <Grid container spacing={3}>
         
          <Grid size={{md:5,sm:12,xs:12}}>

            <Typography variant='h6' sx={{mb:"1rem"}} fontWeight={700}>
                <Translation data="layout.install_our_app" />
            </Typography>

            <Typography variant="body2" fontWeight={200}>
                <Translation data="layout.install_our_app_desc" />
            </Typography>

            <Box className="elementor-widget-container" sx={{mt:"1rem"}}>
                <Link href="https://play.google.com/store/apps/details?id=ae.computer_center.deliverit" target="_blank">
                    <img src={require('../../assets/image/layout/google.png')} alt="Get it on Google Play" width="170px" />
                </Link>

                <Link href="https://apps.apple.com/ae/app/deliverit-uae/id1510897598" target="_blank">
                    <img src={require('../../assets/image/layout/apple.png')} alt="Download on the App Store" width="170px" />
                </Link>
            </Box>
          </Grid>

          
         <Grid size={{md:4,sm:12,xs:12}}>
          
            <Typography variant='h6' sx={{mb:"1rem"}} fontWeight={700}>
                <Translation data="layout.use_ful_links" />
            </Typography>

            <Box sx={{display: "grid",lineHeight: "2rem"}} className="links">
              <Link color="text.secondary" href="https://deliverit.ae/contact-us/">
                   <Translation data="layout.contact_us" />
              </Link>
              <Link color="text.secondary" href="https://deliverit.ae/about/">
                  <Translation data="layout.about_us" />
              </Link>
              <Link color="text.secondary" href="https://deliverit.ae/privacy-policy/">
                 <Translation data="layout.privacy" />
              </Link>
              <Link color="text.secondary" href="https://deliverit.ae/terms-of-service/">
                <Translation data="layout.terms_of_service" />
            </Link>
            </Box>
          
          </Grid>
         

          <Grid size={{md:3,sm:12,xs:12}}>

            <Box>

              <Typography variant='h6' fontWeight={700}  sx={{mb:"1rem"}}>
                <Translation data="layout.contact" />
              </Typography>
                
              <Typography>
                  <Translation data="layout.contact_address" />
              </Typography>
              <br></br>
              <Typography>
                  <Translation data="layout.contact_phone" />
              </Typography>
            </Box>

            <Box className="social-media">
                 <Link href = "https://www.instagram.com/deliverit_uae" target='_blank'>
                   <InstagramIcon  />
                 </Link>
                
                 <Link href = "https://www.facebook.com/DeliveritApp.AE" target='_blank'>
                     <FacebookOutlinedIcon />
                  </Link>
                  <Link href = "https://twitter.com/Deliverit_UAE" target='_blank'>
                     <TwitterIcon />
                 </Link>
                 <Link href = "https://www.youtube.com/@deliveritapp3678" target='_blank'>
                   <YouTubeIcon  />
                  </Link>

                  <Link href = "https://www.linkedin.com/company/deliveritapplication/" target='_blank'>
                    <LinkedInIcon />
                 </Link>

                 <Link href = "https://www.tiktok.com/@deliverit_uae" target='_blank'>
                    <MusicNoteIcon />
                 </Link>
              </Box>

          </Grid>

        </Grid>
      </Container>

      <Box className="last-section" >

        <Container   className='page_size padding-footer'>
          <Box className="logo">
                <Link href="https://deliverit.ae/" target="_blank">
                      <img src={require('../../assets/image/layout/logo-footer.png')} alt="Logo" width="170px" />
                </Link>
              </Box>

            <Box className="pos-copy-right">
              {Copyright()}
            </Box>
        </Container>
 
      </Box>

    </Box>
   
  );
}

export default memo(Footer)