import { Box, Button, TextField } from '@mui/material'
import React from 'react';
import AddToCartButton from "./../action/AddToCartButton";
import { useSelector } from 'react-redux';
import { checkAddToCart,checkProductQuantity } from '../../../utils/helpers';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export default function CartWithQuantity({product,localDetailsSelected,quantity,setQuantity}) {

  const {items} = useSelector(state => state.cart);
  const checkAddToCartNewItem = checkAddToCart(localDetailsSelected,items);
  
  const handleQuantity = (val) => {
    let newQuantity = checkProductQuantity(val,items,localDetailsSelected);
    setQuantity(newQuantity);
  }
  
  return (
    <Box className="display-cart-with-quantity" sx={{ mt: 3, display:"flex" ,gap:2 }}>
      <Box display="flex" alignItems="center" gap={1} className="w-counter-single-product quantity-controls">
        <Button disabled={!checkAddToCartNewItem}  variant="contained" color="primary" onClick={() => handleQuantity(parseInt(quantity)-1)}>
          <RemoveIcon />
        </Button>
        <TextField
          disabled={!checkAddToCartNewItem} 
          type="number"
          value={quantity}
          onChange={(e) => handleQuantity(parseInt(e.target.value))}
          inputProps={{ min: 1, style: { textAlign: "center" } }}
          size="small"
        />
        <Button disabled={!checkAddToCartNewItem} variant="contained" color="primary" onClick={() => handleQuantity(parseInt(quantity)+1)}>
          <AddIcon/>
        </Button>
      </Box>
      <AddToCartButton  product={product} type="single_product"  quantity={quantity} setQuantity={setQuantity} detail={localDetailsSelected}  />
  </Box>
  )
}
