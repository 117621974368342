import React, { memo } from 'react';
import { Card,Typography,CardContent,CardActions, Divider, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

function OrderSummary({ totalPrice, totalQuantity,shippingFee,address,submit}) {

  const { t, i18n } = useTranslation();
  return (
    <Card className="order-summary" variant="outlined"> 
        <CardContent>
          <Typography variant="h6"  gutterBottom>
            {t("checkout.order_summary") }
          </Typography>
          <Typography> {t("checkout.item_price") } {totalPrice.toFixed(2)} {t("checkout.aed") } </Typography>
          {totalQuantity > 0 && <Typography>  {t("checkout.total_quantity") } {totalQuantity}</Typography>}
          {shippingFee !== null && shippingFee !== -1 && <Typography> {t("checkout.delivery") }  {shippingFee}</Typography>}
          <Typography variant="h6" sx={{ marginTop: 2 }}>
          {t("checkout.total") }  {(totalPrice + shippingFee).toFixed(2)}  {t("checkout.aed") } 
          </Typography>
        </CardContent>
        <Divider />
        <CardActions>
          <Button name="checkout" className='checkout-button' variant="contained" type='submit'  color="primary" disabled={(shippingFee  === -1 || !address.fav_id  || submit)  && true}  fullWidth>
             <Typography  className='checkout-button'>{t("checkout.place_order") } </Typography> 
          </Button>
        </CardActions>
        {shippingFee  === -1 && <Typography className='checkout-error'> {t("checkout.shipment_error")} </Typography>}
    </Card>
  )
}

export default memo(OrderSummary);
