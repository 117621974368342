import Divider from "@mui/material/Divider";
import AttributesList from "../../../components/store/product_attributes/AttributesList";
import ProductPrice from "../../../components/store/product_attributes/ProductPrice";
import CustomAttributeList from "../../../components/store/product_attributes/CustomAttributeList";
import CategoryList from "../../../components/store/product_attributes/CategoryList";
import Translation from "../../../utils/Translation";
import Box from "@mui/material/Box";

function SideBarComponent({ attributesAvailable, categoriesAvailable }) {
    return (
      <Box>
        {attributesAvailable.Store.length > 0 && (
          <AttributesList
            title={<Translation data="store.store" />}
            name="Store"
            list={attributesAvailable.Store}
          />
        )}
        {categoriesAvailable && <CategoryList />}
        {attributesAvailable.Brand.length > 0 && (
          <AttributesList
            title={<Translation data="store.brand" />}
            name="Brand"
            list={attributesAvailable.Brand}
          />
        )}
        <ProductPrice /> <Divider className="mt-2 mb-2" />
        {attributesAvailable.Size.length > 0 && (
          <AttributesList
            title={<Translation data="store.size" />}
            name="Size"
            list={attributesAvailable.Size}
          />
        )}
        {attributesAvailable.Color.length > 0 && (
          <AttributesList
            title={<Translation data="store.color" />}
            name="Color"
            list={attributesAvailable.Color}
          />
        )}
        {attributesAvailable.Tag.length > 0 && (
          <AttributesList
            title={<Translation data="store.tag" />}
            name="Tag"
            list={attributesAvailable.Tag}
          />
        )}
        {attributesAvailable.Custom_Key.length > 0 &&
          attributesAvailable.Custom_Key.map((val, idx) => {
            if (val.values.length > 0)
              return (
                <CustomAttributeList
                  key={idx}
                  attributes={val}
                  values={attributesAvailable.Custom_Value}
                />
              );
          })}
      </Box>
    );
  }

  export default SideBarComponent