import ApiService from "../api/api_service";
import { ApiException } from "../../utils/exceptions";

const StoreService = {
  async searchProduct({ auth, dynamicState, inputValue, source }) {
    let path = `/v1/search-products?search_term=${inputValue}`;

    const headers = {
      authorization: `Bearer ${auth.user.accessToken}`,
      contentType: "application/json",
    };
    const options = { dynamicState: dynamicState, reformatData: false };
    const data = { cancelToken: source.token };

    try {
      const response = await ApiService.get(path, headers, data, options);

      return response.data;
    } catch (error) {
      throw new ApiException(error);
    }
  },
  async loadProduct({ auth, dynamicState, data }) {
    let path = `/v1/get-all-products?limit=${data.limit}&offset=${data.offset}${data.extra}`;
    
    const headers = {
      authorization: `Bearer ${auth.user.accessToken}`,
      contentType: "application/json",
    };
    const options = { dynamicState: dynamicState, reformatData: false };

    try {
      const response = await ApiService.get(path, headers, data, options);

      return response.data;
    } catch (error) {
      throw new ApiException(error);
    }
  },
  async loadSingleProduct({ auth, dynamicState, id }) {
    let path = `/v1/product/${id}`;

    const headers = {
      authorization: `Bearer ${auth.user.accessToken}`,
      contentType: "application/json",
    };
    const options = { dynamicState: dynamicState, reformatData: false };
    const data = [];

    try {
      const response = await ApiService.get(path, headers, data, options);

      return response.data;
    } catch (error) {
      throw new ApiException(error);
    }
  },
  async loadSingleProductForSellerAsync({ auth, dynamicState, id }) {
    let path = `/v1/product-seller/${id}`;

    const headers = {
      authorization: `Bearer ${auth.user.accessToken}`,
      contentType: "application/json",
    };
    const options = { dynamicState: dynamicState, reformatData: false };
    const data = [];

    try {
      const response = await ApiService.get(path, headers, data, options);

      return response.data;
    } catch (error) {
      throw new ApiException(error);
    }
  },
  async loadAllAttributes({ auth, dynamicState }) {
    let path = `/v1/get-all-attributes`;
    let data = [];

    const headers = {
      authorization: `Bearer ${auth.user.accessToken}`,
      contentType: "application/json",
    };
    const options = { dynamicState: dynamicState, reformatData: false };

    try {
      const response = await ApiService.get(path, headers, data, options);

      return response.data;
    } catch (error) {
      throw new ApiException(error);
    }
  },
  async loadAvailableAttribute({ auth, dynamicState, parameter, data }) {
    let path = `/v1/get-all-store-attributes?${parameter}`;

    const headers = {
      authorization: `Bearer ${auth.user.accessToken}`,
      contentType: "application/json",
    };
    const options = { dynamicState: dynamicState, reformatData: false };

    try {
      const response = await ApiService.get(path, headers, data, options);

      return response.data;
    } catch (error) {
      throw new ApiException(error);
    }
  },
  async loadProductReviews({ auth, dynamicState, id }) {
    let path = `/v1/product-review/${id}`;
    let data = [];

    const headers = {
      authorization: `Bearer ${auth.user.accessToken}`,
      contentType: "application/json",
    };
    const options = { dynamicState: dynamicState, reformatData: false };

    try {
      const response = await ApiService.get(path, headers, data, options);

      return response.data;
    } catch (error) {
      throw new ApiException(error);
    }
  },
  async loadDeliveryOptions({ auth, dynamicState }) {
    let path = `v1/delivery-options/`;
    let data = [];

    const headers = {
      authorization: `Bearer ${auth.user.accessToken}`,
      contentType: "application/json",
    };
    const options = { dynamicState: dynamicState, reformatData: false };

    try {
      const response = await ApiService.get(path, headers, data, options);

      return response.data;
    } catch (error) {
      throw new ApiException(error);
    }},
    async loadStorePage({ auth, dynamicState }) {
      let path = `v1/get-store-page`;
      let data = [];
  
      const headers = {
        authorization: `Bearer ${auth.user.accessToken}`,
        contentType: "application/json",
      };
      const options = { dynamicState: dynamicState, reformatData: false };

      try {
        const response = await ApiService.get(path, headers, data, options);
  
        return response.data;
      } catch (error) {
        throw new ApiException(error);
      }},
      async deleteProduct({  auth, dynamicState,productId }) {
        let path = `v1/delete-product/${productId}`;
        let data = [];
    
        const headers = {
          authorization: `Bearer ${auth.user.accessToken}`,
          contentType: "application/json",
        };
        const options = { dynamicState: dynamicState, reformatData: false };
  
        try {
          const response = await ApiService.delete(path, headers, data, options);
    
          return response.data;
        } catch (error) {
          throw new ApiException(error);
      }
    },async reviewProduct ({  auth, dynamicState,data }) {
      let path = `v1/product-review`;
  
      const headers = {
        authorization: `Bearer ${auth.user.accessToken}`,
        contentType: "application/json",
      };
      const options = { dynamicState: dynamicState, reformatData: true };

      try {
        const response = await ApiService.post(path, headers, data, options);
  
        return response.data;
      } catch (error) {
        throw new ApiException(error);
    }
  },async createProduct ({  auth, dynamicState,data }) {
    let path = `v1/create-product`;

    const headers = {
      authorization: `Bearer ${auth.user.accessToken}`,
      contentType: "application/json",
    };
    const options = { dynamicState: dynamicState, reformatData: true };

    try {
      const response = await ApiService.post(path, headers, data, options);

      return response.data;
    } catch (error) {
      throw new ApiException(error);
  }
},async updateProduct ({  auth, dynamicState,data }) {
  let path = `v1/update-product/${data.id}`;

  const headers = {
    authorization: `Bearer ${auth.user.accessToken}`,
    contentType: "application/json",
  };
  const options = { dynamicState: dynamicState, reformatData: true };

  try {
    const response = await ApiService.put(path, headers, data, options);

    return response.data;
  } catch (error) {
    throw new ApiException(error);
}
},async createAttribute({auth,dynamicState, attrName ,dataAttr }) {
    let path = `v1/create-attribute/${attrName}`;

    const headers = {
      authorization: `Bearer ${auth.user.accessToken}`,
      contentType: "application/json",
    };
    const options = { dynamicState: dynamicState, reformatData: true };
    const data = dataAttr;
    console.log("data :",data)
    try {
      const response = await ApiService.post(path, headers, data, options);

      return response.data;
    } catch (error) {
      throw new ApiException(error);
  }
}

};
export default StoreService;
