
import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import orderService from "./order_service";

const initialState = {
    isLoading:false,
    status:"idle",
    errorOrder:null,
    findErrorOrder:false,
    orderCreated:[],
    paymentIntent:null,
    payment:null,
    orders:[],
    limit: 20,
    offset: 0,
    page: 1,
    statusOrder:"all",
    label:null,
    invoked : false
};

export const createOrderAsync =  createAsyncThunk("type/createOrderAsync", async ({auth,dynamicState,data},{rejectWithValue}) => {
      
  try{ 
    const order = await orderService.createOrder({auth,dynamicState,data});

    return order;
  }catch(error){
    return rejectWithValue({error:error.getError().message})
  }
   
});

export const GetOrderPaymentIntentAsync = createAsyncThunk("type/GetOrderPaymentIntentAsync", async ({auth,dynamicState,order_id},{rejectWithValue}) => {
      
  try{ 
    const order = await orderService.GetOrderPaymentIntent({auth,dynamicState,order_id});
    return order;
  }catch(error){
    return rejectWithValue({error:error.getError().message})
  }
   
});


export const GetOrderStoreAsync = createAsyncThunk("type/GetOrderStoreAsync", async ({auth,dynamicState,data},{rejectWithValue}) => {
      
  try{ 
    const order = await orderService.GetOrderStore({auth,dynamicState,data});
    return order;
  }catch(error){
    return rejectWithValue({error:error.getError().message})
  }
   
});


export const ConfirmOrderAsync = createAsyncThunk("type/ConfirmOrderAsync", async ({auth,dynamicState,data},{rejectWithValue}) => {
      
  try{ 
    const order = await orderService.ConfirmOrder({auth,dynamicState,data});
    return order;
  }catch(error){
    return rejectWithValue({error:error.getError().message})
  }
   
});


export const GetUserOrdersAsync = createAsyncThunk("type/userOrdersAsync", async ({auth,dynamicState,attr},{rejectWithValue}) => {
      
  try{ 
    const order = await orderService.UserOrders({auth,dynamicState,attr});
    return order;
  }catch(error){
    return rejectWithValue({error:error.getError().message})
  }
   
});



export const CancelOrderAsync = createAsyncThunk("type/CancelOrderAsync", async ({auth,dynamicState,data},{rejectWithValue}) => {
      
  try{ 
    const order = await orderService.CancelOrder({auth,dynamicState,data});
    return order;
  }catch(error){
    return rejectWithValue({error:error.getError().message})
  }
   
});



export const GetOrderByIdAsync = createAsyncThunk("type/GetOrderByIdAsync", async ({auth,dynamicState,order_id},{rejectWithValue}) => {
      
  try{ 
    const order = await orderService.GetOrderById({auth,dynamicState,order_id});
    return order;
  }catch(error){
    return rejectWithValue({error:error.getError().message})
  }
   
});


export const GetLabelOrderAsync = createAsyncThunk("type/GetLabelOrderAsync", async ({auth,dynamicState,reference},{rejectWithValue}) => {
      
  try{ 
    const order = await orderService.GetLabelOrder({auth,dynamicState,reference});
    return order;
  }catch(error){
    return rejectWithValue({error:error.getError().message})
  }
   
});



const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
      orderCreated:(state,action)=>{
        state.orderCreated = action.payload;
      },
      setOrders : (state,action) => {
        state.orders = action.payload;
      },setErrorOrder:(state,action)=>{
        state.errorOrder = action.payload;
        state.findErrorOrder = true;
      },setFindErrorOrder : (state,action) => {
        state.findErrorOrder = action.payload;
      },resetPayment : (state,action) => {
        state.orderCreated = [];
        state.paymentIntent = null;
        state.payment = null;
      },
      setLimit: (state, action) => {
        state.orders =
          action.payload < state.limit
            ? state.orders.slice(0, action.payload)
            : [];
        state.offset = 0;
        state.page = 1;
        state.limit = action.payload;
        state.invoked = false;
      },
      setOffset: (state, action) => {
        state.orders = action.payload === state.offset ? state.orders : [];
        state.offset = action.payload;
        state.invoked = false;
      },
      setPage: (state, action) => {
        state.page = action.payload;
        state.invoked = false;
      },
      setStatusOrder: (state, action) => {
        state.offset = 0;
        state.page = 1;
        state.orders = state.statusOrder === action.payload
                 ? state.orders.slice(0, action.payload): [];
        state.statusOrder = action.payload;
        state.invoked = false;
      },reloadOrder : (state,action) => {
        state.invoked = false;
      }
    },
    extraReducers: builder => {
        builder
          .addCase(createOrderAsync.pending, (state, action) => {
            state.isLoading = true;
            state.status = "loading";
            state.errorOrder = null;
            state.findErrorOrder = false;
            state.orderCreated = {};
            state.paymentIntent = null;
          })
          .addCase(createOrderAsync.fulfilled, (state, action) => {
            state.isLoading = false;
            state.status = 'succeeded';
            state.orderCreated = action.payload.data?.order;
            state.paymentIntent = action.payload.data?.paymentIntent;
            state.orders = [state.orderCreated,...state.orders];   
          })
          .addCase(createOrderAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.errorOrder = `Order creation failed: ${action.payload?.error}`;
            state.findErrorOrder = true;
            state.status = 'failed';
            console.log("error",action.payload)
          })
          .addCase(GetOrderPaymentIntentAsync.pending, (state, action) => {
            state.isLoading = true;
            state.status = "loading";
            state.payment = null;
          })
          .addCase(GetOrderPaymentIntentAsync.fulfilled, (state, action) => {
            state.isLoading = false;
            state.status = 'succeeded';
            state.payment = action.payload;
          })
          .addCase(GetOrderPaymentIntentAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.status = 'failed';
          })
          .addCase(GetOrderStoreAsync.pending, (state, action) => {
            state.isLoading = true;
            state.status = "loading";
            state.orders = [];
            state.findErrorOrder = false;
            state.invoked = false;
          })
          .addCase(GetOrderStoreAsync.fulfilled, (state, action) => {
            state.isLoading = false;
            state.status = 'succeeded';
            state.orders = action.payload?.data;
            state.invoked = true;
          })
          .addCase(GetOrderStoreAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.status = 'failed';
            state.findErrorOrder = true;
            state.invoked = true;
          })
          .addCase(ConfirmOrderAsync.pending, (state, action) => {
            state.isLoading = true;
            state.status = "loading";
            state.findErrorOrder = false;
          })
          .addCase(ConfirmOrderAsync.fulfilled, (state, action) => {
            let allOrder = [];
            state.isLoading = false;
            state.status = 'succeeded';
            state.orders.forEach((value) => {
              allOrder.push((value.id === action.payload?.data?.order?.id)?action.payload?.data?.order: value);
            });
            state.orders = allOrder;
          })
          .addCase(ConfirmOrderAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.status = 'failed';
            state.findErrorOrder = true;
          })
          .addCase(GetUserOrdersAsync.pending, (state, action) => {
            state.isLoading = true;
            state.status = "loading";
            state.orders = [];
            state.findErrorOrder = false;
            state.invoked = false;
          })
          .addCase(GetUserOrdersAsync.fulfilled, (state, action) => {
            state.isLoading = false;
            state.status = 'succeeded';
            state.orders = action.payload?.data;
            state.invoked = true;
          })
          .addCase(GetUserOrdersAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.status = 'failed';
            state.findErrorOrder = true;
            state.invoked = true;
          })         
           .addCase(CancelOrderAsync.pending, (state, action) => {
            state.isLoading = true;
            state.status = "loading";
            state.findErrorOrder = false;
          })
          .addCase(CancelOrderAsync.fulfilled, (state, action) => {
            let allOrder = [];
            state.isLoading = false;
            state.status = 'succeeded';
            state.orders.forEach((value) => {
              allOrder.push((value.id === action.payload?.data?.order?.id)?action.payload?.data?.order: value);
            });
            state.orders = allOrder;
          })
          .addCase(CancelOrderAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.status = 'failed';
            state.findErrorOrder = true;
          })
          .addCase(GetOrderByIdAsync.pending, (state, action) => {
            state.isLoading = true;
            state.status = "loading";
            state.findErrorOrder = false;
          })
          .addCase(GetOrderByIdAsync.fulfilled, (state, action) => {
            let allOrder = [];
            state.isLoading = false;
            state.status = 'succeeded';

            state.orders.forEach((value) => {
              allOrder.push((value.id === action.payload?.data?.id)?action.payload?.data: value);
            });
            state.orders = allOrder;       
          })
          .addCase(GetOrderByIdAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.status = 'failed';
            state.findErrorOrder = true;
          })

          .addCase(GetLabelOrderAsync.pending, (state, action) => {
            state.status = "loading";
            state.findErrorOrder = false;
            state.label =null;
          })
          .addCase(GetLabelOrderAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.label =action.payload;
          })
          .addCase(GetLabelOrderAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.findErrorOrder = true;
          })
      }
});


export const {orderCreated,setOrders,setErrorOrder,setFindErrorOrder,resetPayment,
  setLimit,setOffset,setPage,setStatusOrder,reloadOrder
} = orderSlice.actions;
export default orderSlice.reducer;

