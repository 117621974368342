import React from 'react';
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import {TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Translation from '../../../utils/Translation';
import { Controller } from 'react-hook-form';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export default function InventoryInput({idx,inventories,control,language,setValue,getValues}) {

  return (
    <Controller
    name={`attributes.${idx}.inventory`}
    control={control}
    render={({ field: { onChange, value } }) => (
            <Autocomplete
            multiple
            options={inventories}
            value={value || []} 
            getOptionLabel={(option) => option?.address_data?.friendly_name || ""} 
            isOptionEqualToValue={(option, value) => option?.fav_id === value?.fav_id}
            onChange={(_, selectedOptions) => {
                console.log(selectedOptions)
                onChange(selectedOptions); // Update React Hook Form value

                if(selectedOptions.length > 0){
                setValue(`attributes.${idx}.inventory`,[selectedOptions[selectedOptions.length - 1]]);
                }else{
                      setValue(`attributes.${idx}.inventory`,[]);
                }
              }}
            renderOption={(props, option, { selected }) => (
            <li {...props}>
                <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                />
                {option?.address_data?.friendly_name}
            </li>
            )}
            renderInput={(params) => (
            <TextField
                {...params}
                label={<Translation data="store_seller.inventory" />}
                placeholder=""
            />
            )} /> 
     )}
   />
  )
}
