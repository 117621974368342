import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import {
  getValByLang,
  keyLang,
  removeHtmlCharacter,
} from "../../../utils/helpers";
import Translation from "../../../utils/Translation";
import Specification from "./Specification";
import { memo } from "react";
import { Link } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function DescriptionProduct(props) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const checkSpecificationValid = () => {
    if (props.productDetails.specification) {
      const specification = props.productDetails.specification
        ? JSON.parse(props.productDetails.specification)
        : null;
      const specificationArray = specification
        ? props.language === keyLang[0]
          ? specification.en
          : specification.ar
        : null;
      const result = Object.entries(specificationArray).every(
        ([key, value]) => key === "" && (value === "" || value == null)
      );

      return !result;
    }

    return false;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          className="product-tab-details"
        >
          <Tab
            label={<Translation data="store.description_product" />}
            {...a11yProps(0)}
          />
          <Tab
            className={checkSpecificationValid() ? "" : "hidden"}
            label={<Translation data="store.specification_product" />}
            {...a11yProps(1)}
          />
          <Tab
            className={
              props.readyForShowAttr &&
              props.productDetails.tags_array?.length > 0
                ? ""
                : "hidden"
            }
            label={<Translation data="store.tags" />}
            {...a11yProps(2)}
          />
          <Tab
            className={props.productDetails?.warranty > 0 ? "" : "hidden"}
            label={<Translation data="store_seller.warranty" />}   
            {...a11yProps(3)}
          />
          <Tab
            className={props.productDetails?.replaceable ? "" : "hidden"}
            label={<Translation data="store_seller.ability_replaceable" />}
            {...a11yProps(4)}
          />
          <Tab
            className={props.productDetails?.returnable ? "" : "hidden"}
            label={<Translation data="store_seller.ability_returnable" />}
            {...a11yProps(5)}
          />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <Typography variant="b" component="h3" sx={{ mb: 1 }}>
          {<Translation data="store.description" />}
        </Typography>
        <Typography variant="body1" className="product-description-single-page">
          {removeHtmlCharacter(
            getValByLang(props.productDetails?.description, props.language)
          )}
        </Typography>
      </CustomTabPanel>

      {checkSpecificationValid() && (
        <CustomTabPanel value={value} index={1}>
          <Specification
            obj={props.productDetails.specification}
            language={props.language}
          />
        </CustomTabPanel>
      )}

      {props.readyForShowAttr &&
        props.productDetails.tags_array?.length > 0 && (
          <CustomTabPanel value={value} index={2}>
            <Typography variant="p" className="mt-1">
              {props.productDetails.tags_array.map((tag, idx) => {
                return (
                  <span className="tag-style" key={idx}>
                    {getValByLang(tag?.name, props.language)}
                  </span>
                );
              })}
            </Typography>
          </CustomTabPanel>
        )}

      {
        <CustomTabPanel value={value} index={3}>
          <Box>
            <Translation data="store.warranty_valid_for" /> <b>{props.productDetails?.warranty} <Translation data="store.month" /></b>
            {props.productDetails.special_warranty ? (
              <Typography variant="body1" className="mt-1 mb-2">
                {removeHtmlCharacter(props.productDetails.special_warranty)}
              </Typography>
            ) : (
              <Button
                variant="contained"
                target="_blank"
                component={Link}
                to="https://deliverit.ae/warranty-policy"
              >
                <Translation data="store_seller.details" />
              </Button>
            )}
          </Box>
        </CustomTabPanel>
      }

      {
        <CustomTabPanel value={value} index={4}>
          <Box>
            <Button
              variant="contained"
              target="_blank"
              component={Link}
              to="https://deliverit.ae/return-policy"
            >
              <Translation data="store_seller.details" />
            </Button>
          </Box>
        </CustomTabPanel>
      }

      {
        <CustomTabPanel value={value} index={5}>
          <Box>
            <Button
              variant="contained"
              target="_blank"
              component={Link}
              to="https://deliverit.ae/return-policy"
            >
              <Translation data="store_seller.details" />
            </Button>
          </Box>
        </CustomTabPanel>
      }
    </Box>
  );
}

export default memo(DescriptionProduct);
