import React, {useState} from 'react';
import {APIProvider, ControlPosition, Map  , InfoWindow } from '@vis.gl/react-google-maps';
import { googleMapsApiKey } from '../../utils/helpers';
import {CustomMapControl} from './MapControl';
import MapHandler from './MapHandler';
import Translation from '../../utils/Translation';

const API_KEY = googleMapsApiKey();
const defaultCenter = {
  lat: 23.4241,
  lng: 53.8478
};

const SelectAddress = (props) => {
 
  const [selectedPlace, setSelectedPlace] =useState(null);

  const getPlaceSelected = async (lat, lng) => {

      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`;

      try {
        const response = await fetch(geocodeUrl);
        const data = await response.json();
        if (data.status === "OK") {
  
          // change place
          const placeDetails = data.results[0];
          const formattedAddress = placeDetails.formatted_address;

          const components = placeDetails.address_components;


          let emirate = null;

          if(components){
              emirate = components.find(component => 
              component.types.includes("administrative_area_level_1")
            )?.long_name;
          }
        
    
           // set city
           props.handleCity(formattedAddress);
        
           // set Emarites
           props.handleEmarites(emirate || "Unknown Emirate");
        
           // set Coordinates
           props.handleCoordinates({lat: lat, lng: lng});
  
           document.getElementById("searchInput").value = formattedAddress;
  
        } else {
          console.error("Geocode API error:", data.status);
        }
      } catch (error) {
        console.error("Error fetching city name:", error);
      }
  
      return "Unknown City";
  };

  return (
    <>
    <APIProvider apiKey={API_KEY}>
      <Map
        onClick={(e)=>getPlaceSelected(e.detail.latLng.lat,e.detail.latLng.lng)}
        defaultZoom={7.5}
        defaultCenter={defaultCenter}
        gestureHandling={'greedy'}
        draggableCursor =  {'pointer'} // Change cursor on hover
        draggingCursor ={'grabbing'} // Change cursor when dragging
      >
         
        {props.coordinates !=='' && typeof props.coordinates === 'object' && (
          <>
          <InfoWindow position={{ lat: Number(props.coordinates.lat), lng: Number(props.coordinates.lng) }}>
              <Translation data={"address.coordinates"}   />  { `(Lat : ${Number(props.coordinates.lat)}, Lng : ${Number(props.coordinates.lng)})` }
          </InfoWindow> 
          </>
        )}

      </Map>

      <CustomMapControl
        handleEmarites = {props.handleEmarites}
        handleCity = {props.handleCity}
        handleCoordinates = {props.handleCoordinates}
        controlPosition={ControlPosition.TOP}
        onPlaceSelect={setSelectedPlace}
      />

      <MapHandler place={selectedPlace}
       handleEmarites = {props.handleEmarites}
       handleCity= {props.handleCity} handleCoordinates={props.handleCoordinates}
        />
    </APIProvider>
    </>

  );
};

export default SelectAddress;


