import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch } from 'react-redux';
import Translation from '../../utils/Translation';
import { memo } from 'react';
import { setStatusOrder } from '../../services/order/orderSlice';

function OrderStatus({statusOrder}) {
  
  const dispatch = useDispatch()
  const handleChange = (event) =>  dispatch(setStatusOrder(event.target.value)); // reset limit

  return (
    <Box>
      <FormControl className='limit' fullWidth>
        <InputLabel id="demo-simple-select-label"><Translation data="order_seller.order_status" /></InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={statusOrder}
          label="Status"
          onChange={handleChange}
        >
          <MenuItem value={"all"}><Translation data="order_seller.status_all" /></MenuItem>
          <MenuItem value={"pending"}><Translation data="order_seller.status_pending" /></MenuItem>
          <MenuItem value={"in-progress"}><Translation data="order_seller.status_in_progress" /></MenuItem>
          <MenuItem value={"delivered"}><Translation data="order_seller.status_delivered" /></MenuItem>
          <MenuItem value={"canceled"}><Translation data="order_seller.status_canceled" /></MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

// static OrderStatus pending = OrderStatus._('pending');
//   static OrderStatus pendingReview = OrderStatus._('pending-review');
//   static OrderStatus confirmed = OrderStatus._('confirmed');
//   static OrderStatus inProgress = OrderStatus._('in-progress');
//   static OrderStatus collected = OrderStatus._('collected');
//   static OrderStatus partiallyCollected = OrderStatus._('partially-collected');
//   static OrderStatus deliveryInProgress = OrderStatus._('delivery-in-progress');   //        
//   static OrderStatus delivered = OrderStatus._('delivered');    
//   static OrderStatus cancelled = OrderStatus._('cancelled');
//   static OrderStatus canceledExpired = OrderStatus._('canceled-expired');
//   static OrderStatus rejected = OrderStatus._('rejected');

export default memo(OrderStatus)
