import React, { memo, useState } from 'react';
import {Box,Button,Collapse,IconButton,Stack,Table,TableBody,TableCell,TableHead,TableRow,Typography,} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import Translation from '../../../../utils/Translation';
import { StyledTableCell, StyledTableRow } from './OrderCustomer';
import {getValByLang, truncateText } from '../../../../utils/helpers';
import { convertDateProduct } from '../../../../utils/helpers';
import OrderView from '../../../../components/order/OrderView';
import OrderCancel from '../../../../components/order/OrderCancel';
import { Link } from 'react-router-dom';
import ProductReview from '../../../../components/order/ProductReview';
import StoreReview from '../../../../components/order/StoreReview';
import { useSelector } from 'react-redux';

const StyledTableCellDetails = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e5e2e2",
    color: "#000000",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

function TableRows(props) {
  const { order, language} = props;
  const [open, setOpen] = useState(false);
  const {productReviewList} = useSelector(state => state.store);
  const {storeReviewList} = useSelector(state => state.seller);

  return (
    <>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>{order?.order_status}</StyledTableCell>
        <StyledTableCell>{order?.order_total}  <Translation data="store_seller.aed" /></StyledTableCell>
        <StyledTableCell>{order?.delivery_value}  <Translation data="store_seller.aed" /></StyledTableCell>
        <StyledTableCell>{order?.discount_amount}  <Translation data="store_seller.aed" /></StyledTableCell>
        <StyledTableCell>{order?.vat_amount}  <Translation data="store_seller.aed" /></StyledTableCell>
        <StyledTableCell>{order?.total_amount}  <Translation data="store_seller.aed" /></StyledTableCell>
        <StyledTableCell>{order?.total_invoice_value}  <Translation data="store_seller.aed" /></StyledTableCell>
        <StyledTableCell>{convertDateProduct(order?.completed_at)}</StyledTableCell>
        <StyledTableCell>
          <Box>
            {
             <img loading="lazy" className="order-img" src={order?.proof_image  ? 
                order?.proof_image :require('../../../../assets/image/product/default-product.webp')} 
                alt="order" />
            } 
          </Box>
        </StyledTableCell>
        <StyledTableCell>{order?.canceled_by}</StyledTableCell>
        <StyledTableCell>
            {order?.cancelation_comment}
        </StyledTableCell>
        <StyledTableCell>{convertDateProduct(order?.created_at)}</StyledTableCell>
        <StyledTableCell>{convertDateProduct(order?.updated_at)}</StyledTableCell>
        <StyledTableCell>
        {(order?.order_status === "delivery-in-progress")  && order?.order_status  &&
          <Link component="button"
           variant="contained" 
           target='_blank' 
           to={order?.tracking_link}
           className='tracking-button'>
              <Translation data="order_customer.tracking"  />
          </Link>
        }
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className="order-details">
              <Typography className='text-right' variant="h6" gutterBottom>
                <Translation data="order_seller.details" />
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCellDetails><Translation data="order_seller.order_id"  /> </StyledTableCellDetails>
                        <StyledTableCellDetails><Translation data="order_seller.reference"  /> </StyledTableCellDetails>
                        <StyledTableCellDetails><Translation data="order_seller.product_name"  /> </StyledTableCellDetails>
                        <StyledTableCellDetails><Translation data="order_seller.product_image"  /> </StyledTableCellDetails>
                        <StyledTableCellDetails><Translation data="order_seller.price"  /> </StyledTableCellDetails>
                        <StyledTableCellDetails><Translation data="order_seller.item_status"  /> </StyledTableCellDetails>
                        <StyledTableCellDetails><Translation data="order_seller.quantity"  /> </StyledTableCellDetails>
                        <StyledTableCellDetails><Translation data="order_seller.action"  /> </StyledTableCellDetails>
                      </StyledTableRow>
                </TableHead>
                <TableBody>
                {order?.order_details  &&  order?.order_details.length  && 
                    order?.order_details.map((detail, idx) => (
                     <StyledTableRow key={idx}>
                        <StyledTableCell component="th" scope="row"> {detail?.detail_id} </StyledTableCell>
                        <StyledTableCell component="th" scope="row"> {detail?.reference}  </StyledTableCell>
                        <StyledTableCell component="th" scope="row">{ truncateText(getValByLang(detail?.product_name,language),5)}  </StyledTableCell>
                        <StyledTableCell>
                        {
                          <img loading="lazy" className="order-img" src={detail?.product_image ? 
                            detail?.product_image :require('../../../../assets/image/product/default-product.webp')} 
                          alt="order" />
                        } 
                        </StyledTableCell>
                        <StyledTableCell >{detail?.price}  <Translation data="store_seller.aed" /> </StyledTableCell>
                        <StyledTableCell className='font-bold'>{detail?.item_status} </StyledTableCell>
                        <StyledTableCell >{detail?.quantity} </StyledTableCell>
                        <StyledTableCell>
                            <Stack direction="row" spacing={2}>
                              <OrderView language={language} detail={detail} />
                              {(["pending","pending-review","confirmed","in-progress","partially-collected","collected"].includes(detail?.item_status) )  &&
                                <OrderCancel  order_id={order?.id} detail_id ={detail?.detail_id} />
                              }

                              {detail?.item_status  === "delivered"  &&
                                <>
                                  {!detail.has_reviewed_product && !productReviewList.includes(detail.product_id)  && 
                                    <ProductReview  detail={detail} />
                                  } 

                                  {!detail.has_reviewed_store  && !storeReviewList.includes(detail.store_id) && 
                                    <StoreReview  store_id={detail?.store_id}   /> 
                                  } 
                                </>
                              }
                            </Stack>
                        </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

    </>
  );
}

export default memo(TableRows);
