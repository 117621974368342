import { Box, Rating, Typography } from '@mui/material'
import React, { memo } from 'react'
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Translation from '../../../utils/Translation';
import { getValByLang, removeHtmlCharacter } from '../../../utils/helpers';

 function SellerProduct({sellerInfo,language}) {

  return (
    <Box className="attribute-product">

        <Typography component="p" className='sold_by'>      
            <Translation data="store.sold_by" />
        </Typography>

        <Stack direction="row" spacing={2} className='mt-1' >
            <Avatar
                alt={getValByLang(sellerInfo?.name,language)}
                src={sellerInfo?.img_url}
                sx={{ width: 56, height: 56 }}
            />

            <Typography className='sold_info'  variant="body2"> 
               <Typography component="b"  variant="b">{getValByLang(sellerInfo?.name,language)}</Typography>  
               <Typography component="p"  variant="p" className='sold_by_description'>
                    { removeHtmlCharacter(getValByLang(sellerInfo?.description,language))}
                </Typography>  
               <Typography component="p" variant='b'> 
                  <Rating  name="size-medium" className='starts_ar' value= {Number(sellerInfo?.rating)} precision={0.1} readOnly/> 
               </Typography>
            </Typography>
        </Stack>
        

    </Box>
  )
}

export default memo(SellerProduct)


