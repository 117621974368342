import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SellerService from "./seller_service";

const initialState = {
  statusSeller: 'idle',
  isLoading:false,
  errorSeller: null,
  sellerInfo : null,
  findErrorSeller:null,
  businessTypes:[],
  sellerReport :{},
  storeReviewList:[]
};


export const getSellerReportAsync = createAsyncThunk("type/getSellerReportAsync",async ({ auth, dynamicState }, 
  { rejectWithValue }) => {

    try {
      const storeReport = await SellerService.getSellerReport({auth,dynamicState});

      return storeReport;

    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);

export const getStoreAsync = createAsyncThunk("type/getStoreAsync",async ({ auth, dynamicState }, 
  { rejectWithValue }) => {

    try {
      const sellerInfo = await SellerService.getStore({
        auth,
        dynamicState
      });

      return sellerInfo;

    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);


export const getBusinessTypesAsync = createAsyncThunk("type/getBusinessTypesAsync",async ({ auth, dynamicState }, 
  { rejectWithValue }) => {

    try {
      const businessTypes = await SellerService.getBusinessTypes({auth, dynamicState });

      return businessTypes;
    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);


export const createOrUpdateStoreAsync = createAsyncThunk("type/createOrUpdateStoreAsync",async ({ auth, dynamicState,data }, 
  { rejectWithValue }) => {

    try {
      const store = await SellerService.createOrUpdateStore({auth,dynamicState,data});

      return store;

    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);


export const reviewStoreAsync = createAsyncThunk("type/reviewStoreAsync",async ({ auth, dynamicState,data }, 
  { rejectWithValue }) => {

    try {
      const review = await SellerService.reviewStore({auth,dynamicState,data});

      return review;

    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);

const sellerSlice = createSlice({
  name: "seller",
  initialState,
  reducers: {
     setSellerInfo : (state, action) => {
      state.sellerInfo = action.payload;
    },setError : (state, action) => {
      state.errorSeller = action.payload;
    },setFindErrorSeller: (state, action) => {
      state.findErrorSeller = action.payload;
    },setStatusSeller: (state, action) => {
      state.statusSeller = action.payload;
    }
  },
  extraReducers: builder => {
      builder
            .addCase(getStoreAsync.pending, (state, action) => {
              state.sellerInfo = null;
              state.isLoading = true;
              state.errorSeller = null;
              state.findErrorSeller = false;
              state.statusSeller = 'loading';
            })
            .addCase(getStoreAsync.fulfilled, (state, action) => {
              state.sellerInfo = action.payload;
              state.isLoading = false;
              state.statusSeller = 'succeeded';
              })
            .addCase(getStoreAsync.rejected, (state, action) => {
              state.statusSeller = 'failed';
              state.isLoading = false;
              state.errorSeller = action.payload.error;
              state.findErrorSeller = true;
              console.log("Error in Get Store : "+ action.payload.error);
            })
            .addCase(getBusinessTypesAsync.pending, (state, action) => {
              state.statusSeller = 'loading';
              state.isLoading = true;
              state.errorSeller = null;
              state.findErrorSeller = false;
              state.businessTypes = [];
            })
            .addCase(getBusinessTypesAsync.fulfilled, (state, action) => {
              state.statusSeller = 'succeeded';
              state.isLoading = false;
              state.businessTypes =  action.payload;
              })
            .addCase(getBusinessTypesAsync.rejected, (state, action) => {
              state.statusSeller = 'failed';
              state.isLoading = false;
              state.errorSeller = action.payload.error;;
              state.findErrorSeller = true;
              console.log("Error in Get Store : "+ action.payload.error);
            })
            .addCase(createOrUpdateStoreAsync.pending, (state, action) => {
              state.statusSeller = 'loading';
              state.isLoading = true;
              state.errorSeller = null;
            })
            .addCase(createOrUpdateStoreAsync.fulfilled, (state, action) => {
              state.statusSeller = 'succeeded';
              state.isLoading = false;
              state.sellerInfo = action.payload;
              })
            .addCase(createOrUpdateStoreAsync.rejected, (state, action) => {
              state.statusSeller = 'failed';
              state.isLoading = false;
              state.errorSeller = action.payload.error;
              console.log("Error in Get Store : "+ action.payload.error);
            })

            .addCase(getSellerReportAsync.pending, (state, action) => {
              state.statusSeller = 'loading';
              state.isLoading = true;
              state.errorSeller = null;
              state.findErrorSeller = false;
              state.sellerReport = {};
            })
            .addCase(getSellerReportAsync.fulfilled, (state, action) => {
              state.statusSeller = 'succeeded';
              state.isLoading = false;
              state.sellerReport = action.payload;
              })
            .addCase(getSellerReportAsync.rejected, (state, action) => {
              state.statusSeller = 'failed';
              state.isLoading = false;
              state.errorSeller = action.payload.error;
              state.findErrorSeller = true;
              console.log("Error in Get Store : "+ action.payload.error);
            })

            .addCase(reviewStoreAsync.pending, (state, action) => {
              state.isLoading = true;
              state.errorSeller = null;
              state.findErrorSeller = false;
            })
            .addCase(reviewStoreAsync.fulfilled, (state, action) => {
              state.isLoading = false;
              state.storeReviewList = [state.storeReviewList,action.payload.storeRating?.id];
              console.log( state.storeReviewList )
              })
            .addCase(reviewStoreAsync.rejected, (state, action) => {
              state.isLoading = false;
              state.errorSeller = action.payload.error;
              state.findErrorSeller = true;
              console.log("Error in Get Store : "+ action.payload.error);
            })
          }
});

export const {
  setSellerInfo,setError,setFindErrorSeller,setStatusSeller
} = sellerSlice.actions;
export default sellerSlice.reducer;
