import {
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { getShipmentValue } from "../../../utils/helpers";
import { memo } from "react";
import { useTranslation } from "react-i18next";

export  function DeliveryOptions({setDeliveryOption,deliveryOption,setShippingFee,deliveryOptions,items,address,language}) {

  const { t, i18n } = useTranslation();
  const handleShipmentChange = async(event) => {
    const  id = Number(event.target.value);
    setDeliveryOption(id);
  };

  useEffect(()=> {
    
    const fetchData = async () =>{
      const shipmentValue  = await getShipmentValue(deliveryOptions,items,deliveryOption,address);
      setShippingFee(shipmentValue);
    }
    
    if(!deliveryOption) setDeliveryOption(deliveryOptions.delivery_options[0].id);
    else  fetchData();
    
  },[deliveryOption,address]);


  return (
    <CardContent>
      <Typography variant="h6" gutterBottom>
        { t("checkout.delivery_options") }
      </Typography>

      {
      deliveryOptions?.delivery_options && deliveryOptions?.delivery_options.length > 0  &&
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={deliveryOption}
          name="radio-buttons-group">
        
          {deliveryOptions?.delivery_options.map((val,idx) => {
            return (<FormControlLabel key={idx} value={val.id} control={<Radio />} label={language === "ar" ? val.text.ar:val.text.en} onChange={(event) => handleShipmentChange(event)} />)
          })}

        </RadioGroup>
      </FormControl>
      }
  </CardContent>
  )
}

export default memo(DeliveryOptions);
