import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import {
    Box,
    Button,
    InputLabel,
    Typography,
    FormGroup,
  } from "@mui/material";
  import CloudUploadIcon from "@mui/icons-material/CloudUpload";
  import Translation from "../../../utils/Translation";
  import { styled } from "@mui/material/styles";
  
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

export default function UploadImg({image,setImage,croppedImage,setCroppedImage,register,handleFileChange,setFileImg,imagePreview,setImagePreview}) {


const blobUrlToFile = async (blobUrl, fileName) => {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return new File([blob], fileName, { type: "image/webp" });
};

 const handleCropDone = async (croppedAreaPixels) => {

    const croppedImgUrl = await crop(image, croppedAreaPixels);
    setCroppedImage(croppedImgUrl);
    setImagePreview(croppedImgUrl)

    const timestamp = new Date().toISOString().replace(/[:.-]/g, ""); // Remove special characters
    const fileName = `cropped-image-${timestamp}.webp`;
    
    const croppedFile = await blobUrlToFile(croppedImgUrl, fileName);

    setFileImg(croppedFile)
 };
  
  return (
    <Box className="img-section" sx={{ mt: 3 }}>
    <InputLabel className="img-title" shrink htmlFor="upload-image">
      <Typography  component="span" variant="b">
        <Translation data="store_seller.main_img" />

          <Typography  component="b" variant="b" color="textSecondary" sx={{ m:2,fontSize:"16px"}}>
            <Translation data="store_seller.recommended_img_size" />
          </Typography>

      </Typography>
    </InputLabel>


     
      {image && !croppedImage && <CropperComponent croppedImage = {croppedImage} image={image} onCropDone={handleCropDone} />}
      
      <FormGroup className="icon-arabic">
        {imagePreview && (
          <Box>
            <img
            loading="lazy"
            src={
            imagePreview ||
            require("../../../assets/image/product/default-product.webp")
            }
            alt="Product"
            className="max-height-img"
        />
          </Box>
        )}
        <Button
            className="mt-1"
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
        >
            <Translation data="store_seller.upload_img" />
            <VisuallyHiddenInput
            type="file"
            id="upload-image"
            {...register("image", { required: "File is required" })}
            onChange={handleFileChange}
            />
        </Button>

      </FormGroup>
    </Box>
  )
}


const CropperComponent = ({ image, onCropDone , croppedImage }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  
    const onCropComplete = useCallback((_, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    }, []);
  
    return (
      <div className="crop-container">
  
        {!croppedImage && 
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={11 / 15}  // Set aspect ratio
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
  
        }
  
        <button
          type="button"  
          className="crop-btn"
          onClick={() => onCropDone(croppedAreaPixels)}
        >
          <Translation data="store_seller.crop_img"  />
        </button>
      </div>
    );
  };
  
const crop = async function getCroppedImg(imageSrc, pixelCrop) {
const image = await createImage(imageSrc);
const canvas = document.createElement("canvas");
const ctx = canvas.getContext("2d");

canvas.width = pixelCrop.width;
canvas.height = pixelCrop.height;

ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
);

return new Promise((resolve) => {
    canvas.toBlob((blob) => {
    resolve(URL.createObjectURL(blob));
    }, "image/webp");
});
}
  
function createImage(url) {
return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "Anonymous";
    image.src = url;
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
});
}

  
  
