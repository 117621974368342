import React from "react";
import ImageZoom from "react-image-zooom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";

export default function ImageProduct({ productImg,detailsSelected,galleryImage,setGalleryImage }) {
  
  const handleClick = (url) => setGalleryImage(url);

  return (
    <>
      {galleryImage && (
        <ImageZoom
          className="FullImageZoom"
          src={galleryImage}
          alt="A image to apply the ImageZoom plugin"
          zoom="250"
        />
      )}

      <Swiper
        modules={[Pagination]}
        pagination={{ clickable: true }}
        spaceBetween={10}
        slidesPerView={4} // Adjust based on your design
        loop={true} // Makes it continuous
        className="gallery"
      >
        {/* Main Product Image */}
        <SwiperSlide>
          <img
            src={productImg}
            key={0}
            alt="product img"
            className={galleryImage === productImg ? "img-active" : ""}
            onClick={() => handleClick(productImg)}
          />
        </SwiperSlide>

        {/* Other Images */}
        {detailsSelected?.images.length > 0 &&
          detailsSelected?.images.map((val, idx) => (
            <SwiperSlide key={idx+1}>
              <img
                src={val.url}
                alt="product img"
                className={galleryImage === val.url ? "img-active" : ""}
                onClick={() => handleClick(val.url)}
              />
            </SwiperSlide>
          ))}
      </Swiper>

    </>
  );
}
