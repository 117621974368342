import React from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../services/cart/cartSlice";
import Button from "@mui/material/Button";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import Translation from "../../../utils/Translation";
import { Box, FormControl, FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { checkAddToCart, getPrice } from "../../../utils/helpers";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
const AddToCartButton = ({ product, quantity, setQuantity, type, detail }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { items } = useSelector((store) => store.cart);
  const { colors, sizes } = useSelector((store) => store.store);
  const checkAddToCartNewItem = checkAddToCart(detail, items);

  const handleAddToCart = () => {
    // get details
    const color_name = detail.color_id
      ? colors.filter((val) => val.id === detail.color_id)[0].name
      : null;
    const size_name = detail.size_id
      ? sizes.filter((val) => val.id === detail.size_id)[0].name
      : null;

    dispatch(
      addToCart({
        product:product,
        product_id: product.id,
        id: detail?.id,
        slug: product.slug,
        detail: detail,
        color: color_name,
        size: size_name,
        img: product.img,
        name: product.name,
        price: getPrice(detail),
        quantity: Number(quantity),
      })
    );
    type === "single_product" && setQuantity(1);
  };

  return type === "single_product" ? (
    <Box
      className="single_product_add_to_Cart"
      sx={{ display: "block", width: "60%" }}
    >
      <motion.div
        whileTap={{ scale: 0.9 }} // Shrinks slightly when clicked
        animate={checkAddToCartNewItem ? { y: [-5, 0], scale: [1.1, 1] } : {}} // Bounce effect
        transition={{ duration: 0.3 }}
      >
        <Button
          className={
            type === "single_product" &&
            "add_to_cart w-add-cart-single-product w-100"
          }
          component="label"
          role={undefined}
          disabled={!checkAddToCartNewItem}
          variant="contained"
          tabIndex={-1}
          startIcon={<ShoppingCartIcon />}
          endIcon={<AddIcon />}
          onClick={() => handleAddToCart()}
          sx={{
            textTransform: "none",
          }}
        >
          <Typography variant="b" component="b">
            <Translation data="store.add" />
          </Typography>
        </Button>
      </motion.div>
      {!checkAddToCartNewItem && (
        <FormHelperText className="sold_out">
          {t("store.sold_out")}
        </FormHelperText>
      )}
    </Box>
  ) : (
    <FormControl>
      <motion.div
        whileTap={{ scale: 0.9 }} // Shrinks slightly when clicked
        animate={checkAddToCartNewItem ? { y: [-5, 0], scale: [1.1, 1] } : {}} // Bounce effect
        transition={{ duration: 0.3 }}
      >
        <Button
          className={
            type === "single_product"
              ? "add_to_cart w-add-cart-single-product"
              : ""
          }
          component="label"
          role={undefined}
          disabled={!checkAddToCartNewItem}
          variant="contained"
          tabIndex={-1}
          startIcon={<ShoppingCartIcon />}
          endIcon={<AddIcon />}
          onClick={() => handleAddToCart()}
          sx={{
            textTransform: "none",
          }}
        >
          <Typography variant="b" component="b">
            <Translation data="store.add" />
          </Typography>
        </Button>
      </motion.div>
      {!checkAddToCartNewItem && (
        <FormHelperText className="sold_out">
          {t("store.sold_out")}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default AddToCartButton;
