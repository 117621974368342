import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
    totalQuantity: 0,
    totalPrice: 0,
  },
  reducers: {
    addToCart: (state, action) => {
      const item = action.payload;
      const existingItem = state.items.find((i) => i.id === item.id);
      
      if (existingItem) {
        existingItem.quantity += item.quantity;
        existingItem.totalPrice += item.price * item.quantity;
      } else {
        state.items.push({
          ...item,
          id:item.id,
          img:item.img,
          quantity: item.quantity,
          totalPrice: item.price * item.quantity,
          cannotAdd:false,
          cannotRemove:false
        });
      }
      state.totalQuantity += item.quantity;
      state.totalPrice += item.price * item.quantity;
    }, 
    updateToCart: (state, action) => { 
      const index = action.payload.idx;
      const newVal = action.payload.newVal;
      let newItem = action.payload.itemCard;

      state.items[index].cannotAdd = false;
      state.items[index].cannotRemove = false;
    
      // remove last item 
      state.totalQuantity -= state.items[index].quantity;
      state.totalPrice -= state.items[index].price * state.items[index].quantity;
      
      // calculate new item
      const maxQuantity =   parseInt(state.items[index].detail.count - state.items[index].detail.sold_amount);
      
      if(newVal === -1){

        if((state.items[index].quantity - 1) <= 0)
          state.items[index].quantity = 1
        else
          state.items[index].quantity -= 1;

      }
      else if( maxQuantity < (newItem.quantity + 1)){
        state.items[index].quantity = maxQuantity;
        state.items[index].cannotAdd = true;
      }else {
        state.items[index].quantity +=1;
      }

      state.items[index].cannotRemove =  state.items[index].quantity === 1? true:false;
      
      state.items[index].totalPrice = state.items[index].price * state.items[index].quantity;

      state.totalQuantity +=  state.items[index].quantity;
      state.totalPrice += state.items[index].price * state.items[index].quantity;
    },
    removeFromCart: (state, action) => {
      const id = action.payload;
      const itemIndex = state.items.findIndex((i) => i.id === id);
      const item = state.items[itemIndex];

      if (item) {
        state.totalQuantity -= item.quantity;
        state.totalPrice -= item.totalPrice;
        state.items.splice(itemIndex, 1);
      }
    },
    clearCart: (state) => {
      state.items = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
    },
    updateCartMismatching:(state,action) => {
      let cartData = action.payload;
      let totalQuantity = 0;
      let totalPrice =0;
      let itemsAfterUpdated = [];

      console.log("cartData :",cartData);

      cartData.forEach((valMatch, idx) => {

        const updatedItems = state.items
          .filter((val) => val.id === cartData[idx].variant_id)
          .map((item) => ({
            ...item,
            quantity: (item.quantity > valMatch.count)? valMatch.count : item.quantity ,
            price: valMatch.price,
            totalPrice: valMatch.price * ((item.quantity > valMatch.count)? valMatch.count : item.quantity),
          }));
        
        itemsAfterUpdated.push(...updatedItems);
      });
    
      console.log("itemsAfterUpdated :",itemsAfterUpdated);

      itemsAfterUpdated.forEach((val) => {
        totalQuantity += val.quantity;
        totalPrice += val.totalPrice;
      });

      console.log("totalQuantity :",totalQuantity);
      console.log("totalPrice :",totalPrice);

      state.items =itemsAfterUpdated;
      state.totalQuantity =totalQuantity;
      state.totalPrice =totalPrice;
    }
  },
});

export const { addToCart,updateToCart ,removeFromCart, clearCart,updateCartMismatching } = cartSlice.actions;
export default cartSlice.reducer;
