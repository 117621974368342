import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Container from '@mui/material/Container';
import SideBarStore from './SideBarStore';
import ProductStore from './ProductStore';
import React, { useCallback, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {loadProductAsync } from '../../../services/store/storeSlice';
import { checkScreenLaptop, checkValidString,showMainPageStore } from '../../../utils/helpers';
import axios from "axios";
import MainPage from './MainPage/MainPage';

function Store() {
 
  const dispatch = useDispatch();
  const {
    limit,
    offset,
    products,
    search_term,
    sortBy,
    CategorySelected,
    attributesSelected,
    changePrice,
    minPrice,
    maxPrice,
    isLoading
    ,attributesAvailable
    ,categoriesAvailable
  } = useSelector((state) => state.store);
  const auth = useSelector((state) => state.auth);
  const dynamicState = useSelector((state) => state.dynamicConfig);
  const {screenWidth,mediumWidthScreen} = useSelector((state) => state.global);

  const getProducts = useCallback((source) => {
    let data = {
      limit: limit,
      offset: offset,
      extra: "",
      cancelToken: source.token,
    };

    if (checkValidString(search_term))  data.extra += `&search_term=${search_term}`;
    else if (CategorySelected) data.extra += `&catg_id=${CategorySelected}`;

    if (data.extra !== "") {
      if (sortBy) {
        if (sortBy === 0) data.extra += `&order_by_created=true`;
        else if (sortBy === 1) data.extra += `&order_by_created=false`;
        else if (sortBy === 2) data.extra += `&order_by_price=true`;
        else if (sortBy === 3) data.extra += `&order_by_price=false`;
        else if (sortBy === 4) data.extra += `&best_rated=false`;
      }

      if (attributesSelected.Store.length > 0)
        data.extra += `&store_ids=${attributesSelected.Store.join(",")}`;

      if (attributesSelected.Brand.length > 0)
        data.extra += `&brand_ids=${attributesSelected.Brand.join(",")}`;

      if (attributesSelected.Size.length > 0)
        data.extra += `&size_ids=${attributesSelected.Size.join(",")}`;

      if (attributesSelected.Color.length > 0)
        data.extra += `&color_ids=${attributesSelected.Color.join(",")}`;

      if (attributesSelected.Tag.length > 0)
        data.extra += `&tag_ids=${attributesSelected.Tag.join(",")}`;

      if (minPrice && minPrice > 0) data.extra += `&min_price=${minPrice}`;

      if (maxPrice && maxPrice > 0) data.extra += `&max_price=${maxPrice}`;

      if (attributesSelected.Custom_Value.length > 0)
        data.extra += `&attributes=${attributesSelected.Custom_Value.join(
          ","
        )}`;

        
      dispatch(loadProductAsync({ auth, dynamicState, data }));
    }
  });


  useEffect(() => {
    const source = axios.CancelToken.source();
    
    // check product is empty
    if (products.length === 0) 
        getProducts(source);

    return () => {
      source.cancel();
    };
  }, [
    dispatch,
    search_term,
    CategorySelected,
    attributesSelected,
    changePrice,
    limit,
    offset,
    sortBy
  ]);

  
  return (
     <Box className="mt-6-percent store_page">
        <Container className='no-padding  page_size'>
          {showMainPageStore(CategorySelected,search_term) ?
          <Box>
            <MainPage />
          </Box> :

          <Grid className="main-store"  container>
          {checkScreenLaptop(screenWidth,mediumWidthScreen)  ?   
             <>
              <Grid  size={{lg:2,md:4,sm:4,xs:6}} >
                <SideBarStore className="store_sidebar"
                  isLoading={isLoading} 
                  attributesAvailable={attributesAvailable} 
                  categoriesAvailable={categoriesAvailable} 
                />
              </Grid>
              <Grid  size={{lg:10,md:8,sm:8,xs:6}}>
                <ProductStore />
              </Grid>
             </>
          :
           <Grid  size={{lg:12,md:12,sm:12,xs:12}}>
                <ProductStore />
           </Grid>
        }
        </Grid>
     
          }
        </Container>
     </Box>
  )
}
export default  Store
