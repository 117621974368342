import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Dropdown from "react-multilevel-dropdown";
import { useSelector } from "react-redux";
import { getValByLang , allLinks, isEqual } from "../../../utils/helpers";
import { Box, Tab, Tabs } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { setCategorySelected } from "../../../services/store/storeSlice";
import { useDispatch } from "react-redux";
import Translation from "../../../utils/Translation";
import { useNavigate } from "react-router-dom";
import { memo } from "react";

function AppBarCategory() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { language } = useSelector((store) => store.global);
  const {categories,CategorySelected,CategoryActive} = useSelector((state) => state.store);

  const handleSelectCategory = (val) =>{
    dispatch(setCategorySelected(isEqual(val,CategorySelected) ? 0 : val));
    navigate(`/${allLinks[4]}`); // redirect to store page
  }

  return (
    <AppBar position="static" className="appbar-store">
      <Container maxWidth="xl" className="appbar-store-container">
        <Toolbar disableGutters className="menu">
        <Dropdown
            className="all-category"
            title={<Translation data="store.all_categories" />}
            openOnHover={false}
            position="right"
          >
            {categories.map((catg, idx) => {
              return (
                <Dropdown.Item
                  onClick={() => handleSelectCategory([catg.id, ...catg.all_child])}
                  position="right"
                  key={idx}
                  className={
                    CategoryActive === catg.id ? "category_active" : ""
                  }
                >
                  <Box className="category-name">
                    {" "}
                    {getValByLang(catg.name, language)}
                  </Box>
                  <Box className="category-arrow">
                    {" "}
                    {catg.child.length > 0 && <KeyboardArrowRightIcon />}
                  </Box>

                  {catg.child.length > 0 && (
                    <Dropdown.Submenu position="right">
                      {catg.child.map((child, chIdx) => {
                        return (
                          <Dropdown.Item
                            onClick={(event) => {
                              event.stopPropagation(); // Prevent event from propagating to parent
                              handleSelectCategory([child.id,...child.all_child]);
                            }}
                            position="right"
                            key={chIdx}
                            className={
                              CategoryActive === child.id
                                ? "category_active"
                                : ""
                            }
                          >
                            {getValByLang(child.name, language)}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Submenu>
                  )}
                </Dropdown.Item>
              );
            })}
          </Dropdown>


          <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
              <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="category tabs"
                  className="category-gap-column"
                  sx={{
                      "& .MuiTabs-indicator": {
                        display: "none", // Hide the active indicator line
                      },
                    }}
                  >
                  {categories.map((val, idx) =>
                    <Tab
                        key={idx}
                        label={getValByLang(val.name, language).toUpperCase()}
                        onClick={() => handleSelectCategory([val.id, ...val.all_child])}
                        className= {CategoryActive === val.id  ? "category_active" : ""}
                               
                               
                      />
                  )}
                </Tabs>
         </Box>


        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default memo(AppBarCategory);
