import { useEffect,useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MainTitle from '../../../components/headline/MainTitle';
import PackageDescription from './PackageDescription';
import DeliveryLocations from './DeliveryLocations';
import DeliveryTime from './DeliveryTime';
import PaymentMethods from './PaymentMethods';
import Details from './Details';
import { useDispatch, useSelector } from 'react-redux';
import {setActiveStep ,setSkipped,createRequestAsync,resetValues,setImgUrl } from '../../../services/request/requestSlice';
import ResultSend from '../../../components/alert/ResultSend';
import {  getDownloadURL, ref as storageRef,  uploadBytes } from 'firebase/storage';
import {storage} from '../../../config/firebase-config';
import Translation from '../../../utils/Translation';
import { useNavigate } from 'react-router-dom';

const steps = [ 'request.step_0', 'request.step_1', 'request.step_2','request.step_3','request.step_4'];
const msgSuccessfully = <Translation data="request.msg_added"  />;

export default function AddRequest() {

  const request = useSelector(state=>state.request);
  const auth = useSelector(state => state.auth);
  const dynamicState = useSelector(state => state.dynamicConfig);
  const dispatch = useDispatch();
  const [msg,setMsg] = useState('');
  const [submit,setSubmit] = useState(false);
  const [image, setImage]  = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleUpload = (data) => {
      

    const imageRef = storageRef(storage,`user/${auth.user.uid}/requests/${data.req_id}/${image.name}`);

     uploadBytes(imageRef, image)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref)
          .then((imgUrl) => {

            dispatch(setImgUrl(imgUrl)); 
            data.photo_url = imgUrl;

            dispatch(createRequestAsync({auth,dynamicState,data}));

          })
          .catch((error) => {
            console.log(error)
          });
      })
      .catch((error) => {
        console.log(error)
      });
  };

  
  useEffect(() => {
 
    if(submit) {

      if(request.status === "succeeded"){
        setMsg(msgSuccessfully);
      } 
    
  
      if(request.status === "failed") 
        setMsg(request.error)
    
    }

  }, [request.status,dispatch]);


  const isStepOptional = (step) => {
    return step === 4;
  };

  const isStepSkipped = (step) => {
    return request.skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = request.skipped;
    if (isStepSkipped(request.activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(request.activeStep);
    }

    dispatch(setActiveStep(request.activeStep+1));

    dispatch(setSkipped(newSkipped));
  };

  const handleBack = () => {
    dispatch(setActiveStep(request.activeStep-1));
  };

  const handleSkip = () => {
    if (!isStepOptional(request.activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    dispatch(setActiveStep(request.activeStep+1));  
    const newSkipped = new Set(request.skipped.values());

    newSkipped.add(request.activeStep);

    dispatch(setSkipped(newSkipped));
  };

  const handleReset = () => {
    // locale state
    setSubmit(false);
    setMsg("");

    // global state
    dispatch(setActiveStep(0));
    dispatch(resetValues());
  };

  const backToHomePage = () => {

    handleReset();
    navigate('/');
  }

  const handleSubmitForm=() => {

    setSubmit(true);

    let data = {
      'user_id': auth.user.uid,
      'req_id': '',
      "pickup_address_data": JSON.stringify((request.addressPickup[0])?request.addressPickup[0].address_data:request.addressPickup),
      "drop_address_data": JSON.stringify((request.addressDrop[0])?request.addressDrop[0].address_data:request.addressDrop),
      'description': request.description,
      'photo_url':  request.imgUrl,
      'country': "1",// default value
      'city': "1",// default value
      'est_value': "",
      'size': request.packageSize,
      'status': '0',// waiting for driver
      'package_size': request.packageSize,
      "delivery_date":request.deliveryDate,
      'only_visible_to_driver_id': (request.driverId === "l7iTLW4t6zMcpny7c5p3ivAjz8n1")?request.driverId:'',// for test
      'with_service': '0', //  later
      'service_meta': null, //  later  
      "payment_method": request.paymentMethodId,// [0,1,2]
      "collect_payment_from": (request.paymentMethodId === 2)? "1": "0", //["sender" : 0, "receiver":1] 
      "special_care" : (request.skipped.size===1) ? "":request.specialCare,
      "instructions": (request.skipped.size===1) ? "":request.instructions,
      "deilvery_window":request.deliveryTime, // 0,1,2,4,5 /  5 not today or tomorrow And  same city    ||| 4 for same day
      'path':request.path,
      'estimated_duration': request.estimatedDuration
    };

    if(image) 
       handleUpload(data);
    else
       dispatch(createRequestAsync({auth,dynamicState,data}));
  }


  const handleNextButton = () => {

    if(request.activeStep === 0 && request.packageSize!=='' && request.description!=='')
      return false;


    if(request.activeStep === 1 && request.pickupAddressId !==null  && request.dropAddressId !==null &&
       request.path !== '' && request.estimatedDuration !== '' && request.distance !== ''
    )
      return false;


    if(request.activeStep === 2  && request.deliveryDate !== ''  && request.deliveryTime !== '')
      return false;

    if(request.activeStep === 3 && request.paymentMethodId !== null)
      return false;

    if(request.activeStep === 4) // skip case
      return false
    

    return true;
  }

  return (

    <Box
    className="add-request"
    sx={{
      maxWidth: '100%',
    }}>
    
        <Box>
            <MainTitle  title='request.main-title'  />
        </Box>
      
      {msg!=="" &&
        <Box>
             <ResultSend msg={msg}  error={ request.error?true:false} />
        </Box>
      }
        <Box sx={{ width: '100%', marginTop:"2%" }}>
          <Stepper activeStep={request.activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption"> "<Translation data="request.optional" />"</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps} className='bold'> <Translation data={label}  />  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
            {request.activeStep === steps.length ? (
              <>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  <Translation data="request.step_complete" />
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 , gap:2 }}>
                  <Box sx={{ flex: '1 1 auto' }} className="button-reset" />
                  <Button variant="outlined" onClick={handleReset}>
                     <Translation data="request.reset" /> 
                   </Button>
                  {!request.addedNewRequest ? 
                    <Button variant="contained"  
                      onClick={handleSubmitForm}>
                      <Translation data="request.submit" />
                    </Button>:
                    <Button variant="contained"  
                     onClick={() => backToHomePage()}>
                      <Translation data="request.back_to_home" />
                    </Button>
                  }
                </Box>
              </>) : (
              <>
                <Box>
                    {request.activeStep === 0 && <PackageDescription />}
                    {request.activeStep === 1 && <DeliveryLocations />}
                    {request.activeStep === 2 && <DeliveryTime />}
                    {request.activeStep === 3 && <PaymentMethods />}
                    {request.activeStep === 4 && <Details handleChange={handleChange} />}
                </Box>
              
                 <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
    
                  <Button variant="outlined"
                    disabled={request.activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                     >
                    <Translation data="request.back"  />
                  </Button>

                  <Box sx={{ flex: '1 1 auto' }} />
                    {isStepOptional(request.activeStep) && (
                      <Button variant="outlined" onClick={handleSkip} sx={{ mr: 1 }}>
                        <Translation data="request.skip"  />
                      </Button>
                    )}

                    <Button disabled={handleNextButton()} variant="contained" onClick={handleNext}>
                      {request.activeStep === steps.length - 1 ?     <Translation data="request.finish"  /> 
                      :  <Translation data="request.next"  /> }
                  </Button>
                </Box>
              </>
            )}
        </Box>

    </Box>
  );
}




