import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import Translation from "../../../utils/Translation";
import SpecificationList from "../../../components/store_seller/product/SpecificationList";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { memo } from "react";
import ChangeLanguage from "./ChangeLanguage";
import UploadImg from "../../../components/store_seller/product/UploadImg";
import { keyLang } from "../../../utils/helpers";
import ProductTypeCheckbox from "../../../components/store_seller/product/ProductTypeCheckbox";
import ProductAbilityCheckbox from "../../../components/store_seller/product/ProductAbilityCheckbox";
import ProductWarranty from "../../../components/store_seller/product/ProductWarranty";

const modules = {
  toolbar: [
    // Font Family & Size
    [{ size: ["small", false, "large"] }],

    // Text Formatting
    ["bold", "italic", "underline", "strike"],

    // Headers
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    // List & Indent
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],

    // Remove Formatting
    ["clean"]
  ],
};

function BasicInfo({
  control,
  register,
  errors,
  imagePreview,
  setImagePreview,
  setValue,
  getValues,
  setFileImg,
  fields,
  remove,
  append,
  fieldsAttr,
  removeAttr,
  appendAttr,
  language,
  displaySpecialWarranty,
  setdisplaySpecialWarranty
}) {

  const [languageTap, setLanguageTap] = useState(language); // Default languageTap: English

  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const handleFileChange = (e) => {

    e.preventDefault();
    setCroppedImage(null);

    const file = e.target.files[0];

    if (file) {
      setFileImg(file);
      setImage(URL.createObjectURL(file));
      setImagePreview(URL.createObjectURL(file));
    }
  };

  return (
    <Box
      sx={{
        width: 800,
        maxWidth: "100%",
        paddingTop: "3%",
        paddingBottom: "3%",
        paddingLeft: "2%",
      }}
    >
      <ChangeLanguage languageTap={languageTap} setLanguageTap={setLanguageTap} />
      {/* Product Name */}

      <TextField
        fullWidth
        required
        className={languageTap === keyLang[1] ? "hidden" : ""}
        label={"Product Name (English)"}
        {...register(`productName_en`, {
          required: `English name is required`,
          onChange: (e) => {
            setValue(`productName_en`, e.target.value); // Sync Arabic with English
          },
        })}
        error={!!errors.productName?.[languageTap]}
        helperText={errors.productName?.[languageTap]?.message}
        sx={{ mb: 2, width: 500 }}
      />

      <TextField
        fullWidth
        required
        className={languageTap === keyLang[0]  ? "hidden" : ""}
        label={"اسم المنتج (عربي)"}
        {...register(`productName_ar`, {
          required: `Arabic name is required`,
          onChange: (e) => {
            setValue(`productName_ar`, e.target.value); // Sync Arabic with English
          },
        })}
        error={!!errors.productName?.[languageTap]}
        helperText={errors.productName?.[languageTap]?.message}
        sx={{ mb: 2, width: 500 }}
      />

      {/* Description */}
      <Box
        className={
          languageTap === keyLang[1]  ? "hidden editor-description" : "editor-description"
        }
        sx={{ mt: 2 }}
      >
        <Typography component="p" mb={2}>
          <Translation data="store_seller.product_description" />
        </Typography>

        <Controller
          name="description_en"
          control={control}
          render={({ field }) => (
            <ReactQuill
              {...field}
              theme="snow"
              modules={modules}
              onChange={(content) => field.onChange(content)}
            />
          )}
        />
      </Box>

      <Box
        className={
          languageTap === keyLang[0]  ? "hidden editor-description" : "editor-description"
        }
        sx={{ mt: 2 }}
      >
        <Typography component="p" mb={2}>
          <Translation data="store_seller.product_description" />
        </Typography>

        <Controller
          name="description_ar"
          control={control}
          render={({ field }) => (
            <ReactQuill
              {...field}
              theme="snow"
              modules={modules}
              onChange={(content) => field.onChange(content)}
            />
          )}
        />
      </Box>

      {/* Image Upload */}
      <UploadImg  
        image={image}
        setImage={setImage}
        croppedImage = {croppedImage}
        setCroppedImage={setCroppedImage}
        register ={register}
        handleFileChange= {handleFileChange}
        setFileImg={setFileImg}
        imagePreview = {imagePreview}
        setImagePreview={setImagePreview}
      />

      <Box sx={{ mt: 5 }}>
         <ProductTypeCheckbox 
             control={control}
             setValue = {setValue}
          />
      </Box>

      <Box sx={{ mt: 5 }}>
        <ProductAbilityCheckbox
            setValue = {setValue}
            control={control}
          />
      </Box>

      <Box sx={{ mt: 5 }}>
        <ProductWarranty
          register={register}
          setValue = {setValue}
          displaySpecialWarranty = {displaySpecialWarranty}
          setdisplaySpecialWarranty = {setdisplaySpecialWarranty}
         />
      </Box>

      {/* Dynamic SpecificationList */}
      <Box>
        <SpecificationList
          register={register}
          errors={errors}
          language={languageTap}
          fields={fields}
          remove={remove}
          append={append}
          fieldsAttr={fieldsAttr}
          removeAttr={removeAttr}
          appendAttr={appendAttr}
        />
      </Box>
    </Box>
  );
}

export default memo(BasicInfo);
