import { useState } from "react";
import { Box} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { memo } from 'react';
import { keyLang } from "../../../utils/helpers";


function Props(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
 function ChangeLanguage({languageTap,setLanguageTap}) {
  
    const [value, setValue] = useState(languageTap === keyLang[0] ?  0 :1);
  
    const handleChange = (event, newValue) => {
      setLanguageTap((prev) => (newValue === 0 ? keyLang[0] : keyLang[1]));
      setValue(newValue);
    };
  
    return (
      <Box sx={{ width: '100%' ,mb:4 }}>
        <Box sx={{ borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="English" {...Props(0)} />
            <Tab label="Arabic" {...Props(1)} />
          </Tabs>
        </Box>
      </Box>
    );
  }

  export default memo(ChangeLanguage)
  