import React, { useCallback } from "react";
import { getValByLang } from "../../../utils/helpers";
import { Box, Typography, Button, CardContent, Alert } from "@mui/material";
import Translation from "../../../utils/Translation";
import ShowPrice from "./ShowPrice";
import DescriptionProduct from "./DescriptionProduct";
import SellerProduct from "./SellerProduct";

export default function AllDetails({
  readyForShowAttr,
  handleChangeAttr,
  productDetails,
  localDetailsSelected,
  attributesSelected,
  priorityAttr,
  attributesAvailable,
  language,
  sellerInfo,
}) {
  const checkFindValueID = useCallback((valueId) => {
    let result = false;

    for (let idx = 0; idx < productDetails.details.length; idx++) {
      result =
        productDetails.details[idx].custom_attribute_values.includes(valueId);
      if (result) {
        break; // Exit the loop
      } else {
        continue; // Skip to the next iteration
      }
    }

    return result;
  }, []);

  return (
    <CardContent>
      {readyForShowAttr && productDetails.brands_array?.length > 0 && (
        <Typography className="brand-title" variant="h6" gutterBottom>
          {getValByLang(productDetails.brands_array[0]?.name, language)}
        </Typography>
      )}

      <Typography variant="h4" gutterBottom>
        {getValByLang(productDetails?.name, language)}
      </Typography>

      <Typography variant="h6" color="text.secondary" gutterBottom>
        <ShowPrice Details={localDetailsSelected} page="single-product" />
      </Typography>
      
      {readyForShowAttr && (
        <Box
          className={
            (productDetails.colors_array?.length > 0 ||
            productDetails.sizes_array?.length > 0 ||
            productDetails.details[0].custom_attribute_keys?.length > 0)
              ? "attribute-product"
              : ""
          }
        >
          {productDetails.colors_array?.length > 0 && (
            <Box className="mt-1 single-attribute">
              <Typography component="p">
                {" "}
                {<Translation data="store.color" />}{" "}
              </Typography>
              <Box sx={{ "& button": { m: 1 } }}>
                <div>
                  {productDetails.colors_array.map((val, idx) => {
                    return val ? (
                      <Button
                        variant={
                          attributesSelected.Color.includes(val.id)
                            ? "contained"
                            : "outlined"
                        }
                        disabled={
                          priorityAttr !== "color"
                            ? attributesAvailable.Color.includes(val.id)
                              ? false
                              : true
                            : false
                        }
                        size="small"
                        onClick={() =>
                          handleChangeAttr(attributesSelected, "color", val.id)
                        }
                        key={idx}
                      >
                        {getValByLang(val.name, language)}
                      </Button>
                    ) : (
                      <></>
                    );
                  })}
                </div>
              </Box>
            </Box>
          )}

          {productDetails.sizes_array?.length > 0 && (
            <Box className="mt-1 single-attribute">
              <Typography component="p">
                {" "}
                {<Translation data="store.size" />}{" "}
              </Typography>
              <Box sx={{ "& button": { m: 1 } }}>
                <div>
                  {productDetails.sizes_array.map((val, idx) => {
                    return val ? (
                      <Button
                        variant={
                          attributesSelected.Size.includes(val.id)
                            ? "contained"
                            : "outlined"
                        }
                        disabled={
                          priorityAttr !== "size"
                            ? attributesAvailable.Size.includes(val.id)
                              ? false
                              : true
                            : false
                        }
                        size="small"
                        onClick={() =>
                          handleChangeAttr(attributesSelected, "size", val.id)
                        }
                        key={idx}
                      >
                        {getValByLang(val.name, language)}
                      </Button>
                    ) : (
                      <></>
                    );
                  })}
                </div>
              </Box>
            </Box>
          )}

          {productDetails.attributes_array?.length > 0 &&
            productDetails.attributes_array.map((array_1, idx_1) => {
              return array_1.map((array_2, idx_2) => {
                // for not duplicated attributes
                if (idx_1 !== 0) return <></>;

                return (
                  <Box className="mt-1 single-attribute" key={idx_1 + idx_2}>
                    <Typography component="p">
                      {" "}
                      {getValByLang(array_2.name, language) + ":"}{" "}
                    </Typography>
                    <Box sx={{ "& button": { m: 1 } }}>
                      <div>
                        {array_2.values.map((array_3, idx_3) => {
                          if (!checkFindValueID(array_3.value_id)) return <></>;

                          return (
                            <Button
                              size="small"
                              variant={
                                attributesSelected.Custom_Value.includes(
                                  array_3.value_id
                                )
                                  ? "contained"
                                  : "outlined"
                              }
                              disabled={
                                parseInt(priorityAttr) !== parseInt(array_2.id)
                                  ? attributesAvailable.Custom_Value.includes(
                                      array_3.value_id
                                    )
                                    ? false
                                    : true
                                  : false
                              }
                              onClick={() =>
                                handleChangeAttr(
                                  attributesSelected,
                                  array_2.id,
                                  array_3.value_id
                                )
                              }
                              key={idx_1 + idx_2 + idx_3}
                            >
                              {getValByLang(array_3.value, language)}
                            </Button>
                          );
                        })}
                      </div>
                    </Box>
                  </Box>
                );
              });
            })}
        </Box>
      )}

      <Box>
        <Typography component="h5" variant="h5" className="mb-1">
          <Translation data="store.product_details_title" />
        </Typography>

        {productDetails?.productType !== "new" && 
         <Box>
            <Alert severity="warning"> <Translation data="store.product_is"  />  <Translation data={`store_seller.product_type_${productDetails?.productType}`}  /> </Alert>
         </Box>
        }

        {sellerInfo && sellerInfo?.length > 0 && (
          <SellerProduct sellerInfo={sellerInfo[0]} language={language} />
        )}

        <DescriptionProduct
          language={language}
          readyForShowAttr={readyForShowAttr}
          productDetails={productDetails}
        />
      </Box>
    </CardContent>
  );
}
