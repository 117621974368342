import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CancelOrderAsync } from '../../services/order/orderSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Translation from '../../utils/Translation';

export default function OrderCancel({order_id,detail_id}) {

  const  dispatch  =useDispatch();
  const auth = useSelector(state => state.auth);
  const dynamicState = useSelector(state => state.dynamicConfig);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  

  return (
    <React.Fragment>
      <Button className="cancel-order" variant="contained" onClick={handleClickOpen}>
        <Translation data="order_customer.cancel" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          sx: { minWidth: '400px' }, // Set the minWidth here
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const reasonVal = formJson.reason;
            const data = {
                "cancelWholeOrder":false,
                "order_id": order_id,
                "items": [detail_id],
                "reason": reasonVal
            }

            dispatch(CancelOrderAsync({auth, dynamicState,data}))
            console.log(reasonVal);
            handleClose();
          },
        }}
      >
        <DialogTitle> <Translation data="order_customer.cancel_order" /></DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            name="reason"
            label={<Translation data="order_customer.reason" />}
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> <Translation data="order_customer.close" /></Button>
          <Button type="submit"><Translation data="order_customer.send" /></Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
