import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import StoreService from "./store_service";
import { getValByLang } from "../../utils/helpers";
import { keyLang } from "../../utils/helpers";

const initialState = {
  isLoading: false,
  errorStore: null,
  statusStore: "idle",
  findErrorStore: false,
  resultSearch: [],

  // store for seller
  productIdDeleted: null,
  statusStoreCrudProduct: "idle",
  activeStep: 0,
  addedNewProduct: false,

  // store for customer
  products: [],
  productsSeller: [],
  productDetails: {},
  detailsSelected: {},
  categories: [],
  categories_without_arrange: [],
  brands: [],
  stores: [],
  sizes: [],
  colors: [],
  tags: [],
  attributesLoaded: false,
  custom_attributes: [],
  categoriesAvailable: null,
  attributesSelected: {
    Store: [],
    Brand: [],
    Size: [],
    Color: [],
    Tag: [],
    Custom_Key: [],
    Custom_Value: [],
  },
  attributesAvailable: {
    Store: [],
    Brand: [],
    Size: [],
    Color: [],
    Tag: [],
    Custom_Key: [],
    Custom_Value: [],
    CategoryChild: [],
  },
  search_term: "",
  limit: 20,
  offset: 0,
  page: 1,
  productCount: 0,
  productReviews: [],
  sortBy: 0, //0,1,2,3
  CategorySelected: 0,
  CategoryActive: 0,
  minPrice: 0,
  maxPrice: 0,
  changePrice: "",
  deliveryOptions: null,
  storePage: null,

  productReviewList: [],
  newAttr: null,
  attrName: null,
};

export const searchProductAsync = createAsyncThunk(
  "type/searchProductAsync",
  async ({ auth, dynamicState, inputValue, source }, { rejectWithValue }) => {
    try {
      const products = await StoreService.searchProduct({
        auth,
        dynamicState,
        inputValue,
        source,
      });

      return products;
    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);

export const loadProductAsync = createAsyncThunk(
  "type/loadProductAsync",
  async ({ auth, dynamicState, data }, { rejectWithValue }) => {
    try {
      const products = await StoreService.loadProduct({
        auth,
        dynamicState,
        data,
      });

      return products;
    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);

export const loadProductByStoreIdAsync = createAsyncThunk(
  "type/loadProductByStoreIdAsync",
  async ({ auth, dynamicState, data }, { rejectWithValue }) => {
    try {
      const products = await StoreService.loadProduct({
        auth,
        dynamicState,
        data,
      });

      return products;
    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);

export const loadSingleProductAsync = createAsyncThunk(
  "type/loadSingleProductAsync",
  async ({ auth, dynamicState, id }, { rejectWithValue }) => {
    try {
      const product = await StoreService.loadSingleProduct({
        auth,
        dynamicState,
        id,
      });

      return product;
    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);

export const loadSingleProductForSellerAsync = createAsyncThunk(
  "type/loadSingleProductForSellerAsync",
  async ({ auth, dynamicState, id }, { rejectWithValue }) => {
    try {
      const product = await StoreService.loadSingleProductForSellerAsync({
        auth,
        dynamicState,
        id,
      });

      return product;
    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);

export const loadAllAttributesAsync = createAsyncThunk(
  "type/loadAllAttributesAsync",
  async ({ auth, dynamicState }, { rejectWithValue }) => {
    try {
      const attributes = await StoreService.loadAllAttributes({
        auth,
        dynamicState,
      });

      return attributes;
    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);

export const loadProductReviews = createAsyncThunk(
  "type/loadProductReviewsAsync",
  async ({ auth, dynamicState, id }, { rejectWithValue }) => {
    try {
      const reviews = await StoreService.loadProductReviews({
        auth,
        dynamicState,
        id,
      });

      return reviews;
    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);

export const loadDeliveryOptionsAsync = createAsyncThunk(
  "type/loadDeliveryOptionsAsync",
  async ({ auth, dynamicState }, { rejectWithValue }) => {
    try {
      const deliveryOption = await StoreService.loadDeliveryOptions({
        auth,
        dynamicState,
      });

      return deliveryOption;
    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);

export const loadStorePageAsync = createAsyncThunk(
  "type/loadStorePageAsync",
  async ({ auth, dynamicState }, { rejectWithValue }) => {
    try {
      const storePage = await StoreService.loadStorePage({
        auth,
        dynamicState,
      });

      return storePage;
    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);

export const deleteProductAsync = createAsyncThunk(
  "type/deleteProductAsync",
  async ({ auth, dynamicState, productId }, { rejectWithValue }) => {
    try {
      const deleteProduct = await StoreService.deleteProduct({
        auth,
        dynamicState,
        productId,
      });

      return deleteProduct;
    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);

export const reviewProductAsync = createAsyncThunk(
  "type/reviewProductAsync",
  async ({ auth, dynamicState, data }, { rejectWithValue }) => {
    try {
      const review = await StoreService.reviewProduct({
        auth,
        dynamicState,
        data,
      });

      return review;
    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);

export const createProductAsync = createAsyncThunk(
  "type/createProductAsync",
  async ({ auth, dynamicState, data }, { rejectWithValue }) => {
    try {
      const createProduct = await StoreService.createProduct({
        auth,
        dynamicState,
        data,
      });

      return createProduct;
    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);

export const updateProductAsync = createAsyncThunk(
  "type/updateProductAsync",
  async ({ auth, dynamicState, data }, { rejectWithValue }) => {
    try {
      const updateProduct = await StoreService.updateProduct({
        auth,
        dynamicState,
        data,
      });

      return updateProduct;
    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);

export const createAttributeAsync = createAsyncThunk(
  "type/createAttributeAsync",
  async ({ auth, dynamicState, attrName, dataAttr }, { rejectWithValue }) => {
    try {
      const createAttribute = await StoreService.createAttribute({
        auth,
        dynamicState,
        attrName,
        dataAttr,
      });

      return createAttribute;
    } catch (error) {
      return rejectWithValue({ error: error.getError().message });
    }
  }
);

const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setResultSearch: (state, action) => {
      state.resultSearch = action.payload;
    },
    setLimit: (state, action) => {
      state.products =
        action.payload < state.limit
          ? state.products.slice(0, action.payload)
          : [];
      state.offset = 0;
      state.page = 1;
      state.limit = action.payload;
    },
    setSortBy: (state, action) => {
      state.products = action.payload === state.sortBy ? state.products : [];
      state.offset = 0;
      state.page = 1;
      state.sortBy = action.payload;
    },
    setOffset: (state, action) => {
      state.products = action.payload === state.offset ? state.products : [];
      state.offset = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setFindErrorStore: (state, action) => {
      state.findErrorStore = action.payload;
    },
    setErrorStore: (state, action) => {
      state.errorStore = action.payload;
    },
    setProductIdDeleted: (state, action) => {
      state.productIdDeleted = action.payload;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setNewAttr: (state, action) => {
      state.newAttr = action.payload;
    },
    setAttrName: (state, action) => {
      state.attrName = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.products =
        action.payload === state.search_term ? state.products : [];
      state.offset = 0;
      state.page = 1;
      state.search_term = action.payload;
      state.attributesSelected = {
        Store: [],
        Brand: [],
        Size: [],
        Color: [],
        Tag: [],
        Custom_Key: [],
        Custom_Value: [],
      };
      state.minPrice = 0;
      state.maxPrice = 0;
      state.changePrice = "";
      state.CategoryActive = 0 ;
      state.CategorySelected =
        action.payload !== "" ? 0 : state.CategorySelected; // reset category
      state.categoriesAvailable =
        action.payload !== "" ? null : state.categoriesAvailable;
    },
    setCategorySelected: (state, action) => {
      state.products =
        action.payload === state.CategorySelected ? state.products : [];
      state.offset = 0;
      state.page = 1;
      state.search_term = ""; // reset search_term
      state.attributesSelected = {
        Store: [],
        Brand: [],
        Size: [],
        Color: [],
        Tag: [],
        Custom_Key: [],
        Custom_Value: [],
      };
      state.minPrice = 0;
      state.maxPrice = 0;
      state.changePrice = "";
      state.CategorySelected = action.payload; // reset category

      state.CategoryActive =
        state.CategoryActive === action.payload[0] ? 0 : action.payload[0];

      // get category for show on list
      if (action.payload.length > 0) {
        state.categoriesAvailable = state.categories_without_arrange.filter(
          (val) => val.id === action.payload[0]
        )[0];
        state.categoriesAvailable = {
          ...state.categoriesAvailable,
          all_child: action.payload.slice(1),
          child: [],
        };
        state.categoriesAvailable.child =
          state.categories_without_arrange.filter(
            (val) => val.parent_id === action.payload[0]
          );
      } else {
        state.categoriesAvailable = null;
      }
    },
    setCategoryActive: (state, action) => {
      state.CategoryActive = action.payload;
    },
    setCategorySelectedWithoutReset: (state, action) => {
      console.log(action.payload)
      state.products =
        action.payload === state.CategorySelected ? state.products : [];
      state.offset = 0;
      state.page = 1;
      state.search_term = ""; // reset search_term
      state.CategorySelected = action.payload; // reset category

      state.CategoryActive = 
        state.CategoryActive === action.payload[0] ? 0 : action.payload[0];

      // get category for show on list
      if (action.payload.length > 0) {
        state.categoriesAvailable = state.categories_without_arrange.filter(
          (val) => val.id === action.payload[0]
        )[0];
        state.categoriesAvailable = {
          ...state.categoriesAvailable,
          all_child: action.payload.slice(1),
          child: [],
        };
        state.categoriesAvailable.child =
          state.categories_without_arrange.filter(
            (val) => val.parent_id === action.payload[0]
          );
      } else {
        state.categoriesAvailable = null;
      }
    },
    setAttributesSelected(state, action) {
      state.products = [];
      state.offset = 0;
      state.page = 1;

      if (action.payload.key === "Store") {
        if (state.attributesSelected.Store.includes(action.payload.val))
          state.attributesSelected.Store =
            state.attributesSelected.Store.filter(
              (val) => val !== action.payload.val
            );
        else
          state.attributesSelected.Store = [
            ...state.attributesSelected.Store,
            action.payload.val,
          ];
      } else if (action.payload.key === "Brand") {
        if (state.attributesSelected.Brand.includes(action.payload.val))
          state.attributesSelected.Brand =
            state.attributesSelected.Brand.filter(
              (val) => val !== action.payload.val
            );
        else
          state.attributesSelected.Brand = [
            ...state.attributesSelected.Brand,
            action.payload.val,
          ];
      } else if (action.payload.key === "Size") {
        if (state.attributesSelected.Size.includes(action.payload.val))
          state.attributesSelected.Size = state.attributesSelected.Size.filter(
            (val) => val !== action.payload.val
          );
        else
          state.attributesSelected.Size = [
            ...state.attributesSelected.Size,
            action.payload.val,
          ];
      } else if (action.payload.key === "Color") {
        if (state.attributesSelected.Color.includes(action.payload.val))
          state.attributesSelected.Color =
            state.attributesSelected.Color.filter(
              (val) => val !== action.payload.val
            );
        else
          state.attributesSelected.Color = [
            ...state.attributesSelected.Color,
            action.payload.val,
          ];
      } else if (action.payload.key === "Tag") {
        if (state.attributesSelected.Tag.includes(action.payload.val))
          state.attributesSelected.Tag = state.attributesSelected.Tag.filter(
            (val) => val !== action.payload.val
          );
        else
          state.attributesSelected.Tag = [
            ...state.attributesSelected.Tag,
            action.payload.val,
          ];
      } else {
        if (state.attributesSelected.Custom_Value.includes(action.payload.val))
          state.attributesSelected.Custom_Value =
            state.attributesSelected.Custom_Value.filter(
              (val) => val !== action.payload.val
            );
        else
          state.attributesSelected.Custom_Value = [
            ...state.attributesSelected.Custom_Value,
            action.payload.val,
          ];
      }
    },
    setMinPrice(state, action) {
      state.minPrice = action.payload;
    },
    setMaxPrice(state, action) {
      state.maxPrice = action.payload;
    },
    setProductDetails(state, action) {
      state.productDetails = action.payload;
    },
    setDetailsSelected(state, action) {
      state.detailsSelected = action.payload;
    },
    setChangePrice(state, action) {
      state.products = [];
      state.offset = 0;
      state.page = 1;
      state.changePrice = `${action.payload.minPrice} - ${action.payload.maxPrice}`;
    },
    setAddedNewProduct(state, action) {
      state.addedNewProduct = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchProductAsync.pending, (state, action) => {
        state.resultSearch = [];
        state.findErrorStore = false;
      })
      .addCase(searchProductAsync.fulfilled, (state, action) => {
        // remove duplicated content for english language
        const uniqueArray = action.payload.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                getValByLang(t.name, keyLang[0]) ===
                  getValByLang(item.name, keyLang[0]) ||
                getValByLang(t.name, keyLang[1]) ===
                  getValByLang(item.name, keyLang[1])
            )
        );

        state.resultSearch = uniqueArray;
      })
      .addCase(searchProductAsync.rejected, (state, action) => {
        state.errorStore = action.payload.error;
        state.findErrorStore = true;
        console.log("Error in Get Products : " + action.payload.error);
      })
      .addCase(loadProductAsync.pending, (state, action) => {
        state.isLoading = true;
        state.statusStore = "loading";
        state.errorStore = null;
        state.findErrorStore = false;
        state.productCount = 0;
        state.attributesAvailable.CategoryChild = {};

        if (state.attributesSelected.Store.length === 0)
          state.attributesAvailable.Store = {};

        if (state.attributesSelected.Brand.length === 0)
          state.attributesAvailable.Brand = {};

        if (state.attributesSelected.Tag.length === 0)
          state.attributesAvailable.Tag = {};

        if (state.attributesSelected.Color.length === 0)
          state.attributesAvailable.Color = {};

        if (state.attributesSelected.Size.length === 0)
          state.attributesAvailable.Size = {};

        if (state.attributesSelected.Custom_Key.length === 0)
          state.attributesAvailable.Custom_Key = {};

        if (state.attributesSelected.Custom_Value.length === 0)
          state.attributesAvailable.Custom_Value = {};
      })
      .addCase(loadProductAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.statusStore = "succeeded";
        state.errorStore = null;
        state.products = action.payload.products;
        state.productCount = Number(action.payload.productCount);
        state.attributesAvailable.CategoryChild = action.payload.categories;

        if (state.attributesSelected.Store.length === 0)
          state.attributesAvailable.Store = state.stores.filter((val) =>
            action.payload.stores.includes(val.id)
          );

        if (state.attributesSelected.Brand.length === 0)
          state.attributesAvailable.Brand = state.brands.filter((val) =>
            action.payload.brands.includes(val.id)
          );

        if (state.attributesSelected.Tag.length === 0)
          state.attributesAvailable.Tag = state.tags.filter((val) =>
            action.payload.tags.includes(val.id)
          );

        if (state.attributesSelected.Color.length === 0)
          state.attributesAvailable.Color = state.colors.filter((val) =>
            action.payload.colors.includes(val.id)
          );

        if (state.attributesSelected.Size.length === 0)
          state.attributesAvailable.Size = state.sizes.filter((val) =>
            action.payload.sizes.includes(val.id)
          );

        if (state.attributesSelected.Custom_Key.length === 0)
          state.attributesAvailable.Custom_Key = state.custom_attributes.filter(
            (val) => action.payload.attributes_key.includes(val.id)
          );

        if (state.attributesSelected.Custom_Value.length === 0)
          state.attributesAvailable.Custom_Value =
            action.payload.attributes_value;
      })
      .addCase(loadProductAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.statusStore = "failed";
        state.errorStore = action.payload.error;
        state.findErrorStore = true;
        console.log("Error in Get Products : " + action.payload.error);
      })
      .addCase(loadSingleProductAsync.pending, (state, action) => {
        state.isLoading = true;
        state.statusStore = "loading";
        state.errorStore = null;
        state.findErrorStore = false;
        state.productDetails = {};
        state.detailsSelected = {};
      })
      .addCase(loadSingleProductAsync.fulfilled, (state, action) => {
        state.productDetails = action.payload;
        state.isLoading = false;
        state.statusStore = "succeeded";
        state.errorStore = null;
        if (state.productDetails?.id) {
          state.detailsSelected = state.productDetails.details[0];
        }
      })
      .addCase(loadSingleProductAsync.rejected, (state, action) => {
        state.isLoading = false; // for check find any request invoked on the future
        state.statusStore = "failed";
        state.errorStore = action.payload.error;
        state.findErrorStore = true;
        console.log("Error in Get Products : " + action.payload.error);
      })
      .addCase(loadSingleProductForSellerAsync.pending, (state, action) => {
        state.isLoading = true;
        state.statusStore = "loading";
        state.errorStore = null;
        state.findErrorStore = false;
        state.productDetails = {};
        state.detailsSelected = {};
      })
      .addCase(loadSingleProductForSellerAsync.fulfilled, (state, action) => {
        state.productDetails = action.payload;
        state.isLoading = false;
        state.statusStore = "succeeded";
        state.errorStore = null;
        if (state.productDetails?.id) {
          state.detailsSelected = state.productDetails.details[0];
        }
      })
      .addCase(loadSingleProductForSellerAsync.rejected, (state, action) => {
        state.isLoading = false; // for check find any request invoked on the future
        state.statusStore = "failed";
        state.errorStore = action.payload.error;
        state.findErrorStore = true;
        console.log("Error in Get Products : " + action.payload.error);
      })
      .addCase(loadAllAttributesAsync.pending, (state, action) => {
        state.statusStore = "loading";
        state.errorStore = null;
        state.findErrorStore = false;
        state.categories = [];
        state.stores = [];
        state.brands = [];
        state.sizes = [];
        state.colors = [];
        state.tags = [];
        state.custom_attributes = [];
        state.categories_without_arrange = [];
        state.attributesLoaded = false;
      })
      .addCase(loadAllAttributesAsync.fulfilled, (state, action) => {
        state.statusStore = "succeeded";
        state.errorStore = null;
        state.categories = action.payload.categories;
        state.stores = action.payload.stores;
        state.brands = action.payload.brands;
        state.sizes = action.payload.sizes;
        state.colors = action.payload.colors;
        state.tags = action.payload.tags;
        state.custom_attributes = action.payload.custom_attributes;
        state.categories_without_arrange =
          action.payload.categories_without_arrange;
        state.attributesLoaded = true;
      })
      .addCase(loadAllAttributesAsync.rejected, (state, action) => {
        state.statusStore = "failed";
        state.errorStore = action.payload.error;
        state.findErrorStore = true;
        console.log("Error in Get Attributes : " + action.payload.error);
      })
      .addCase(loadProductReviews.pending, (state, action) => {
        state.statusStore = "loading";
        state.errorStore = null;
        state.productReviews = [];
        state.findErrorStore = false;
      })
      .addCase(loadProductReviews.fulfilled, (state, action) => {
        state.statusStore = "succeeded";
        state.productReviews = action.payload;
      })
      .addCase(loadProductReviews.rejected, (state, action) => {
        state.statusStore = "failed";
        state.errorStore = action.payload;
        state.findErrorStore = true;
      })
      .addCase(loadDeliveryOptionsAsync.pending, (state, action) => {
        state.statusStore = "loading";
        state.errorStore = null;
        state.deliveryOptions = [];
        state.findErrorStore = false;
      })
      .addCase(loadDeliveryOptionsAsync.fulfilled, (state, action) => {
        state.statusStore = "succeeded";
        state.deliveryOptions = action.payload;
      })
      .addCase(loadDeliveryOptionsAsync.rejected, (state, action) => {
        state.statusStore = "failed";
        state.errorStore = action.payload;
        state.findErrorStore = true;
      })
      .addCase(loadStorePageAsync.pending, (state, action) => {
        state.statusStore = "loading";
        state.errorStore = null;
        state.storePage = null;
        state.findErrorStore = false;
      })
      .addCase(loadStorePageAsync.fulfilled, (state, action) => {
        state.statusStore = "succeeded";
        state.storePage = action.payload;
      })
      .addCase(loadStorePageAsync.rejected, (state, action) => {
        state.statusStore = "failed";
        state.errorStore = action.payload;
        state.findErrorStore = true;
      })
      .addCase(deleteProductAsync.pending, (state, action) => {
        state.statusStoreCrudProduct = "loading";
        state.errorStore = null;
        state.findErrorStore = false;
      })
      .addCase(deleteProductAsync.fulfilled, (state, action) => {
        state.statusStoreCrudProduct = "succeeded";
        state.errorStore = null;
      })
      .addCase(deleteProductAsync.rejected, (state, action) => {
        state.statusStoreCrudProduct = "failed";
        state.errorStore = action.payload.error;
        state.findErrorStore = true;
        console.log("Error in Get Products : " + action.payload.error);
      })

      .addCase(loadProductByStoreIdAsync.pending, (state, action) => {
        state.statusStore = "loading";
        state.errorStore = null;
        state.findErrorStore = false;
        state.productsSeller = [];
      })
      .addCase(loadProductByStoreIdAsync.fulfilled, (state, action) => {
        state.statusStore = "succeeded";
        state.errorStore = null;
        state.productsSeller = action.payload.products;
      })
      .addCase(loadProductByStoreIdAsync.rejected, (state, action) => {
        state.statusStore = "failed";
        state.errorStore = action.payload.error;
        state.findErrorStore = true;
        console.log("Error in Get Products : " + action.payload.error);
      })

      .addCase(reviewProductAsync.pending, (state, action) => {
        state.statusStore = "loading";
        state.errorStore = null;
        state.findErrorStore = false;
      })
      .addCase(reviewProductAsync.fulfilled, (state, action) => {
        let newProductReview = action.payload[0];
        let ProductList = [];
        state.statusStore = "succeeded";
        state.errorStore = null;
        state.products.forEach((value) => {
          ProductList.push(
            value.id === newProductReview?.id ? newProductReview : value
          );
        });
        state.products = ProductList;

        state.productReviewList = [
          state.productReviewList,
          newProductReview?.id,
        ];
      })
      .addCase(reviewProductAsync.rejected, (state, action) => {
        state.statusStore = "failed";
        state.errorStore = action.payload.error;
        state.findErrorStore = true;
        console.log("Error in Get Products : " + action.payload.error);
      })

      .addCase(createProductAsync.pending, (state, action) => {
        state.statusStore = "loading";
        state.errorStore = null;
        state.findErrorStore = false;
        state.addedNewProduct = false;
      })
      .addCase(createProductAsync.fulfilled, (state, action) => {
        state.statusStore = "succeeded";
        state.errorStore = null;
        state.addedNewProduct = true;
        state.products =state.products.length === 0? []: [...state.products, action.payload?.product];
      })
      .addCase(createProductAsync.rejected, (state, action) => {
        state.statusStore = "failed";
        state.errorStore = action.payload.error;
        state.findErrorStore = true;
        console.log("Error in Get Products : " + action.payload.error);
      })

      .addCase(updateProductAsync.pending, (state, action) => {
        state.statusStore = "loading";
        state.errorStore = null;
        state.findErrorStore = false;
        state.addedNewProduct = false;
      })
      .addCase(updateProductAsync.fulfilled, (state, action) => {
        console.log("Response :", action.payload?.product);
        state.statusStore = "succeeded";
        state.errorStore = null;
        state.addedNewProduct = true;
        state.products = state.products.filter(
          (val) => val.id !== action.payload?.product.id
        );
        state.products =  state.products.length === 0? [] : [...state.products, action.payload?.product];
      })
      .addCase(updateProductAsync.rejected, (state, action) => {
        state.statusStore = "failed";
        state.errorStore = action.payload.error;
        state.findErrorStore = true;
        console.log("Error in Get Products : " + action.payload.error);
      })

      .addCase(createAttributeAsync.pending, (state, action) => {
        state.statusStore = "loading";
        state.errorStore = null;
        state.findErrorStore = false;
      })
      .addCase(createAttributeAsync.fulfilled, (state, action) => {
        state.statusStore = "succeeded";
        state.errorStore = null;

        if (state.attrName === "brands") {
          state.brands.push({ ...state.newAttr, id: action.payload?.id });
        }

        if (state.attrName === "tags") {
          state.tags.push({ ...state.newAttr, id: action.payload?.id });
        }

        if (state.attrName === "colors") {
          state.colors.push({ ...state.newAttr, id: action.payload?.id });
        }

        if (state.attrName === "sizes") {
          state.sizes.push({ ...state.newAttr, id: action.payload?.id });
        }
      })
      .addCase(createAttributeAsync.rejected, (state, action) => {
        state.statusStore = "failed";
        state.errorStore = action.payload.error;
        state.findErrorStore = true;
        console.log("Error in Get Products : " + action.payload.error);
      });
  },
});

export const {
  setProducts,
  setLimit,
  setOffset,
  setFindErrorStore,
  setPage,
  setSearchTerm,
  setResultSearch,
  setSortBy,
  setCategorySelected,
  setCategoryActive,
  setCategorySelectedWithoutReset,
  setAttributesSelected,
  setMinPrice,
  setMaxPrice,
  setChangePrice,
  setProductDetails,
  setDetailsSelected,
  setIsLoading,
  setProductIdDeleted,
  setActiveStep,
  setNewAttr,
  setAttrName,
  setAddedNewProduct,
  setErrorStore
} = storeSlice.actions;
export default storeSlice.reducer;
