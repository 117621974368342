import React, { memo, useState } from "react";
import { CircularProgress, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import { Controller } from "react-hook-form";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { attrNameFullObject, getValByLang } from "../../../utils/helpers";
import { sleep } from "./BrandInput";
import Translation from "../../../utils/Translation";
import AddAttribute from "./AddAttribute";

function CustomAttributesName({idx,index,language,register,errors,control,setValue,getValues}) {

    const { custom_attributes } = useSelector((state) => state.store);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); // State for search term
    const [AddAttr,setAddAttr] = useState(false);

    const fetchAttrs = async (query) => {
      setLoading(true);
      await sleep(500); // Simulate delay
      const filteredAttr = custom_attributes.filter((attr) =>
        getValByLang(attr.name, language).toLowerCase().includes(query.toLowerCase())
      );

      setOptions(filteredAttr.slice(0, 25)); // Update options
      setLoading(false);

      setAddAttr(filteredAttr.length > 0 ? false:true);
    };

    const handleOpen = () => {
    setOpen(true);
    fetchAttrs(searchTerm); // Fetch brands using the current search term
    };

    const handleClose = () => {
    setOpen(false);
    };

    const handleInputChange = (_, value, reason) => {
    
    if (reason === "input" || reason === "selectOption" || reason === "clear") {
        setSearchTerm(value); // Update search term
        fetchAttrs(value); // Fetch matching brands on input change
    }
    
    };

    const handleAddNewAttr = (index) =>{
      setValue(`attributes.${idx}.customAttributes.${index}.name`,[{id:null,name:attrNameFullObject(searchTerm),new:true}]);
      setAddAttr(false);
      setSearchTerm('');
      setValue(`attributes.${idx}.customAttributes.${index}.value`, []);
    }

  return (
    <Grid size={{ lg: 5 , md:5 ,sm:5 , xs:12 }}>
    <Box>
      <Controller
          control={control}
          name={`attributes.${idx}.customAttributes.${index}.name`}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              multiple
              options={options}
              forcePopupIcon={!!options.length}
              getOptionLabel={(option) => getValByLang(option?.name,language) }
              value={Array.isArray(value) ? value : []} // Ensure value is always an array
              inputValue={searchTerm}
              onInputChange={handleInputChange}
              onChange={(_, selectedOption) => {
                console.log("selectedOption ", selectedOption);
                onChange(Array.isArray(selectedOption) ? selectedOption : []); // Ensure it's an array
                if (selectedOption.length > 0) {
                  setValue(
                    `attributes.${idx}.customAttributes.${index}.name`,
                    [selectedOption[selectedOption.length - 1]]
                  );
                } else {
                  setValue(`attributes.${idx}.customAttributes.${index}.name`, []);
                }

                setValue(`attributes.${idx}.customAttributes.${index}.value`, []);
              }}
              onOpen={handleOpen}
              onClose={handleClose}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {getValByLang(option?.name,language)}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={<Translation data="store_seller.name" />}
                  error={!!errors.attributes?.[idx]?.customAttributes?.[index]?.name}
                  helperText={errors.attributes?.[idx]?.customAttributes?.[index]?.name?.message}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
       />

        {AddAttr  && 
              <AddAttribute  handleAddNewAttr= {() => handleAddNewAttr(index)} />
          }
    </Box>
  </Grid>

  )
}

export default memo (CustomAttributesName)
