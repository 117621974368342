import * as React from "react";
import MainTitle from "../../../components/headline/MainTitle";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid2";
import Container from "@mui/material/Container";
import SellerReportCard from "../../../components/card/SellerReportCard";
import CancelIcon from "@mui/icons-material/Cancel";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SellIcon from "@mui/icons-material/Sell";
import MoneyIcon from "@mui/icons-material/Money";
import InventoryIcon from "@mui/icons-material/Inventory";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BoxOrder from "../../../components/box/BoxOrder";
import { Box, Typography } from "@mui/material";
import Translation from "../../../utils/Translation";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";

function HomeSeller() {
  const { sellerReport } = useSelector((state) => state.seller);
  const { reports, pendingReviewOrders, inProgressOrders, collectedOrders } = sellerReport;

  return (
    <>
      <MainTitle title="home.main-title" />
      {reports && (
        <Container className="page_size">
          <Grid container spacing={3}>
            <Grid size={{ lg: 4,md:6 , sm:6, xs:12 }}>
              <SellerReportCard
                icon={<LocalShippingIcon />}
                money={false}
                keyVal={"deliveredOrders"}
                value={reports?.deliveredOrders}
              />
            </Grid>
            <Grid size={{ lg: 4,md:6, sm:6, xs:12  }}>
              <SellerReportCard
                icon={<CancelIcon />}
                money={false}
                keyVal={"cancelledOrders"}
                value={reports?.cancelledOrders}
              />
            </Grid>
            <Grid size={{ lg: 4,md:6 , sm:6, xs:12 }}>
              <SellerReportCard
                icon={<SellIcon />}
                money={false}
                keyVal={"soldProducts"}
                value={reports?.soldProducts}
              />
            </Grid>
            <Grid size={{ lg: 4,md:6, sm:6, xs:12  }}>
              <SellerReportCard
                icon={<InventoryIcon />}
                money={false}
                keyVal={"totalProducts"}
                value={reports?.totalProducts}
              />
            </Grid>
            <Grid size={{ lg: 4,md:6 , sm:6, xs:12 }}>
              <SellerReportCard
                icon={<MoneyIcon />}
                money={true}
                keyVal={"totalEarnings"}
                value={reports?.totalEarnings}
              />
            </Grid>
            <Grid size={{ lg: 4 ,md:6, sm:6, xs:12 }}>
              <SellerReportCard
                icon={<ReceiptIcon />}
                money={true}
                keyVal={"totalSales"}
                value={reports?.totalSales}
              />
            </Grid>
          </Grid>
        </Container>
      )}


    {(pendingReviewOrders  || inProgressOrders || collectedOrders)  && 
      <Box className="mt-2">
        <Typography
          variant="h5"
          component="h5"
          className="font-weight-600 mb-1 request-title"
        >
          <Translation data="home_seller.orders" />
        </Typography>

        <Box className="all-request">
          {pendingReviewOrders && (
            <BoxOrder
              status={"pending"}
              icon={<PendingActionsIcon />}
              keyVal={"pendingReview"}
              values = {pendingReviewOrders}
            />
          )}

          {inProgressOrders && (
            <BoxOrder
              status={"in-progress"}
              icon={<AutorenewIcon />}
              keyVal={"inProgress"}
              values = {inProgressOrders}
            />
          )}

          {collectedOrders && (
            <BoxOrder
              status={"in-progress"}
              icon={<DeliveryDiningIcon />}
              keyVal={"collected"}
              values = {collectedOrders}
            />
          )}
        </Box>
      </Box>
    }
    </>
  );
}

export default HomeSeller;
