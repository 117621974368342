import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import {getValByLang}  from '../../../utils/helpers'
import { useDispatch } from 'react-redux';
import { setCategorySelectedWithoutReset } from '../../../services/store/storeSlice';
import Translation from '../../../utils/Translation';

export default function CategoryList() {

  const {categoriesAvailable,attributesAvailable} = useSelector(state => state.store);
  const {language} = useSelector(state => state.global);
  const dispatch = useDispatch();

  return (
      <>
      <FormGroup>
      <Typography className="display-inline-flex" component="h3" variant='b' id="input-slider" gutterBottom>
          <Translation data="store.category" />
      </Typography>
      
        <FormControlLabel
          label={ getValByLang( categoriesAvailable.name,language)}
          className='attribute-name'
          control={
            <Checkbox
              label={getValByLang(categoriesAvailable.name,language)}
              indeterminate={true}
            />
          }
        />
  
       <Box sx={{ display: 'flex', flexDirection: 'column' }} className = "category_list">
         {categoriesAvailable.child.length !== 0  && categoriesAvailable.child.map((val,idx)=>{

          return (  
             attributesAvailable.CategoryChild.length > 0 
             && attributesAvailable.CategoryChild.includes(val.id)  ?
             <FormControlLabel
              key={idx}
              label={ getValByLang(val.name,language)}
              className='attribute-name'
              control={<Checkbox checked={false} onChange={() => dispatch(setCategorySelectedWithoutReset([val.id, ...(val.all_child ?? [])]))}  />}
              />:<></>
          )

        })}
        </Box>
      
      </FormGroup>
      <Divider className='mt-2 mb-2' />
      </>
  );
}

