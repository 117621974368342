import React, { useCallback, useEffect, useState } from "react";
import { Container, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "react-image-gallery/styles/css/image-gallery.css";
import "react-rater/lib/react-rater.css";
import ImageProduct from "../../../components/store/product_detail/ImageProduct";
import { useSelector } from "react-redux";
import BreadCrumbsProduct from "../../../components/store/product_detail/BreadCrumbsProduct";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  loadSingleProductAsync,
  setDetailsSelected,
  setProductDetails,
} from "../../../services/store/storeSlice";
import { CircularProgress } from "@mui/material";
import Translation from "../../../utils/Translation";
import CartWithQuantity from "../../../components/store/product_detail/CartWithQuantity";
import AllDetails from "../../../components/store/product_detail/AllDetails";
import {
  mergeWithoutDuplicates,
  checkEqualArray,
} from "../../../utils/helpers";
import Review from "../../../components/store/product_detail/Review";
import ProductRelated from "../../../components/store/product_detail/ProductRelated";

export default function ProductDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const dynamicState = useSelector((state) => state.dynamicConfig);
  const { language } = useSelector((state) => state.global);
  const {slug} = useParams();
  const {
    productDetails,
    isLoading,
    detailsSelected,
    attributesLoaded,
    brands,
    tags,
    colors,
    sizes,
    custom_attributes,
    productReviews,
    stores,
  } = useSelector((state) => state.store);
  const id = productDetails?.id;
  const [quantity, setQuantity] = useState(1);
  const [galleryImage, setGalleryImage] = useState(null);
  const [localDetailsSelected, setLocalDetailsSelected] = useState({});
  const [attributesAvailable, setAttributesAvailable] = useState({
    Color: [],
    Size: [],
    Custom_Value: [],
  });
  const [attributesSelected, setAttributesSelected] = useState({
    Color: [],
    Size: [],
    Custom_Value: [],
  });
  const [readyForShowAttr, setReadyForShowAttr] = useState(false);
  const [priorityAttr, setPriorityAttr] = useState("color");
  const [priorityAttrIds, setPriorityAttrIds] = useState([]);

  const removeDuplicatedValue = (object) => {
    return object.filter(
      (val, index, self) =>
        index ===
        self.findIndex(
          (obj) => obj !== undefined && val !== undefined && obj.id === val.id
        )
    );
  };

  const getConditionsForPrimaryAttr = (
    obj,
    attrName,
    attributesSelectedLast
  ) => {
    if (String(attrName) === priorityAttr) return true; // clicked on primary attributes
    if (
      priorityAttr === "color" &&
      obj.color_id === attributesSelectedLast.Color[0]
    )
      return true;
    if (
      priorityAttr === "size" &&
      obj.size_id === attributesSelectedLast.Size[0]
    )
      return true;

    if (priorityAttr !== "color" && priorityAttr !== "size") {
      let primaryAttrIdObject = priorityAttrIds.values.filter((val) =>
        attributesSelectedLast.Custom_Value.includes(val.value_id)
      )[0];
      return obj.custom_attribute_values.includes(primaryAttrIdObject.value_id)
        ? true
        : false;
    }

    return false;
  };

  const getAttributesAvailable = useCallback(
    (detailsSelected, priorityAttrParam, priorityAttrIdsParam) => {
      let newDetails = [];
      let newCustomValue = [];

      if (productDetails?.id && priorityAttrParam !== undefined) {
        if (priorityAttrParam === "color") {
          newDetails = productDetails.details.filter(
            (val) => val.color_id === detailsSelected.color_id
          );
          for (var i = 0; i < newDetails.length; i++)
            newCustomValue = mergeWithoutDuplicates(
              newCustomValue,
              newDetails[i].custom_attribute_values
            );

          (newDetails.length > 0 ||
            newCustomValue.length > 0) &&
            setAttributesAvailable({
              Color: productDetails.details.map((val) => val.color_id),
              Size: newDetails.map((val) => val.size_id),
              Custom_Value: newCustomValue,
            });
        } else if (priorityAttrParam === "size") {
          newDetails = productDetails.details.filter(
            (val) => val.size_id === detailsSelected.size_id
          );
          for (var i = 0; i < newDetails.length; i++)
            newCustomValue = mergeWithoutDuplicates(
              newCustomValue,
              newDetails[i].custom_attribute_values
            );

          (newDetails.length > 0 ||
            newCustomValue.length > 0) &&
            setAttributesAvailable({
              Color: newDetails.map((val) => val.color_id),
              Size: productDetails.details.map((val) => val.size_id),
              Custom_Value: newCustomValue,
            });
        } else if(priorityAttrIdsParam) {
        
          let primaryAttrIdObject = priorityAttrIdsParam.values.filter((val) =>
            detailsSelected.custom_attribute_values.includes(val?.value_id)
          )[0];
          newDetails = productDetails.details.filter((val) =>
            val.custom_attribute_values.includes(primaryAttrIdObject?.value_id)
          );
          for (var i = 0; i < newDetails.length; i++)
            newCustomValue = mergeWithoutDuplicates(
              newCustomValue,
              newDetails[i].custom_attribute_values
            );

          (newDetails.length > 0 ||
            newCustomValue.length > 0) &&
            setAttributesAvailable({
              Color: newDetails.map((val) => val.color_id),
              Size: newDetails.map((val) => val.size_id),
              Custom_Value: newCustomValue,
            });
        }
      }
    },
    [dispatch, productDetails]
  );

  const setAllDetailsProduct = useCallback(() => {
    let productAllDetails = {
      ...productDetails,
      brands_array: [],
      tags_array: [],
      colors_array: [],
      sizes_array: [],
      attributes_array: [],
      sellerInfo: stores.filter((val) => val.id === productDetails.store_id),
    };

    productAllDetails.brands_array = brands.filter(
      (val) => productDetails.brands.includes(val.id) && val !== undefined
    );
    productAllDetails.tags_array = tags.filter(
      (val) => productDetails.tags.includes(val.id) && val !== undefined
    );

    for (var i = 0; i < productAllDetails.details.length; i++) {
      productAllDetails.colors_array[i] = colors.filter(
        (val) =>
          val.id === productAllDetails.details[i].color_id &&
          productAllDetails.details[i].color_id !== null
      )[0];
      productAllDetails.sizes_array[i] = sizes.filter(
        (val) =>
          val.id === productAllDetails.details[i].size_id &&
          productAllDetails.details[i].size_id !== null
      )[0];
      productAllDetails.attributes_array[i] = custom_attributes.filter(
        (val) =>
          productAllDetails.details[i].custom_attribute_keys.includes(val.id) &&
          productAllDetails.details[i].custom_attribute_keys.length !== 0
      );
    }

    // remove unique colors
    productAllDetails.brands_array = removeDuplicatedValue(
      productAllDetails.brands_array
    );
    productAllDetails.tags_array = removeDuplicatedValue(
      productAllDetails.tags_array
    );
    productAllDetails.colors_array = removeDuplicatedValue(
      productAllDetails.colors_array
    );
    productAllDetails.sizes_array = removeDuplicatedValue(
      productAllDetails.sizes_array
    );

    dispatch(setProductDetails(productAllDetails));
    (productAllDetails.brands_array.length > 0 || 
      productAllDetails.tags_array.length > 0  ||
      productAllDetails.colors_array.length > 0 ||
      productAllDetails.sizes_array.length > 0 ||
      productAllDetails.attributes_array.filter((val) => val.length > 0)
        .length > 0) &&
      setReadyForShowAttr(true);
      
  }, [dispatch, productDetails, attributesLoaded]);

  const handleChangeAttr = useCallback(
    (attributesSelectedLast, attrName, attrId) => {
      let newDetails = [];

      if (attrName === "color") {
        // get element based on last attributes
        newDetails = productDetails.details.filter(
          (val) =>
            val.color_id === attrId &&
            val.size_id === attributesSelectedLast.Size[0] &&
            checkEqualArray(
              val.custom_attribute_values,
              attributesSelectedLast.Custom_Value
            )
        );

        // get first element
        if (newDetails.length === 0)
          newDetails = productDetails.details.filter(
            (val) =>
              val.color_id === attrId &&
              getConditionsForPrimaryAttr(val, attrName, attributesSelectedLast)
          );
      } else if (attrName === "size") {
        // get element based on last attributes
        newDetails = productDetails.details.filter(
          (val) =>
            val.color_id === attributesSelectedLast.Color[0] &&
            val.size_id === attrId &&
            checkEqualArray(
              val.custom_attribute_values,
              attributesSelectedLast.Custom_Value
            )
        );

        // get first element
        if (newDetails.length === 0)
          newDetails = productDetails.details.filter(
            (val) =>
              val.size_id === attrId &&
              getConditionsForPrimaryAttr(val, attrName, attributesSelectedLast)
          );
      } else {
        // get element based on last attributes
        newDetails = productDetails.details.filter(
          (val) =>
            val.color_id === attributesSelectedLast.Color[0] &&
            val.size_id === attributesSelectedLast.Size[0] &&
            val.custom_attribute_values.includes(attrId) &&
            checkEqualArray(
              val.custom_attribute_values,
              attributesSelectedLast.Custom_Value
            )
        );

        // get first element
        if (newDetails.length === 0)
          newDetails = productDetails.details.filter(
            (val) =>
              val.custom_attribute_values.includes(attrId) &&
              getConditionsForPrimaryAttr(val, attrName, attributesSelectedLast)
          );
      }

      setAttributesSelected({
        Color: [newDetails[0].color_id],
        Size: [newDetails[0].size_id],
        Custom_Value: newDetails[0].custom_attribute_values,
      });

      getAttributesAvailable(
        newDetails[0],
        productDetails.priority_attr,
        priorityAttrIds
      );

      setLocalDetailsSelected(newDetails[0]);

      setGalleryImage(productDetails?.img ?? null); // set product image
      setQuantity(1); // reset Quantity
    },
    [dispatch, productDetails, priorityAttrIds]
  );

  useEffect(() => {
    // get product from server
    const fetchData = async () => {
      const id = slug.split("-").pop();
      await dispatch(loadSingleProductAsync({ auth, dynamicState, id }));
    }
     
    if (!productDetails?.id) fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (productDetails?.id && attributesLoaded) {

      let priorityAttrIdsParam = custom_attributes.filter(
        (val) => val.id === parseInt(productDetails.priority_attr)
      )[0];

      setAllDetailsProduct();
      setPriorityAttr(productDetails.priority_attr);
      setPriorityAttrIds(priorityAttrIdsParam);
      setGalleryImage(productDetails?.img);
      setLocalDetailsSelected(detailsSelected);

      setAttributesSelected({
        Color: [detailsSelected.color_id],
        Size: [detailsSelected.size_id],
        Custom_Value: detailsSelected.custom_attribute_values,
      });

      getAttributesAvailable(
        detailsSelected,
        productDetails.priority_attr,
        priorityAttrIdsParam
      );

      setQuantity(1); // reset Quantity
    }
  }, [dispatch, detailsSelected, attributesLoaded]);


  return isLoading ? (
    <CircularProgress className="product-preloader-position" />
  ) : !productDetails?.id ? (
    <Box sx={{ p: 8 }}>
      <Translation data="store.not_found_any_product" />{" "}
    </Box>
  ) : (
    <Container className="page_size">
      <Box sx={{ my: 12 }}>
        <Grid container spacing={4}>
          {productDetails?.id && localDetailsSelected?.id > 0 && (
            <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6 }}>
              <ImageProduct
                galleryImage={galleryImage}
                setGalleryImage={setGalleryImage}
                productImg={productDetails?.img}
                detailsSelected={localDetailsSelected}
              />

              <CartWithQuantity
                product={productDetails}
                quantity={quantity}
                setQuantity={setQuantity}
                localDetailsSelected={localDetailsSelected}
              />

              {productDetails.review !== 0 && (
                <Review
                  review={productDetails.review}
                  productReviews={productReviews}
                  auth={auth}
                  dynamicState={dynamicState}
                  id={id}
                />
              )}
            </Grid>
          )}

          <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6 }}>
            <Box sx={{ display: "inline-flex" }}>
              <BreadCrumbsProduct
                navigate={navigate}
                name={productDetails.name}
                language={language}
              />
            </Box>

            <AllDetails
              readyForShowAttr={readyForShowAttr}
              handleChangeAttr={handleChangeAttr}
              productDetails={productDetails}
              localDetailsSelected={localDetailsSelected}
              attributesSelected={attributesSelected}
              priorityAttr={priorityAttr}
              language={language}
              attributesAvailable={attributesAvailable}
              sellerInfo={productDetails.sellerInfo}
            />
          </Grid>

          <ProductRelated
            setReadyForShowAttr={setReadyForShowAttr}
            auth={auth}
            dynamicState={dynamicState}
            store_id={productDetails?.store_id}
            language={language}
          />
        </Grid>
      </Box>
    </Container>
  );
}
