import * as React from 'react';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ProductSearch from '../../../../components/store/action/ProductSearch';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import { ShoppingCart } from '@mui/icons-material';
import {useNavigate } from 'react-router-dom';
import { keyLang } from '../../../../utils/helpers';
import Translation from '../../../../utils/Translation';
import {Typography } from '@mui/material';
import { allLinks } from '../../../../utils/helpers';

export default function HeaderMainPage() {

const {resultSearch,search_term} =  useSelector(state => state.store);
const {language} =  useSelector(state => state.global);
const auth = useSelector(state => state.auth);
const dynamicState = useSelector(state => state.dynamicConfig);
const navigate = useNavigate();

  return (
    <Grid  container>

        <Grid  size={{lg:2,md:2,sm:2,xs:0}}  className='search_by_name'>
          <Typography variant='h6' component="b">
              <Translation data="store.search_by_deliverit" />
          </Typography>
        </Grid>

        <Grid  size={{lg:4,md:5,sm:5,xs:12}} >
            <ProductSearch  
                resultSearch={resultSearch} 
                search_term={search_term} 
                language={language} 
                auth={auth} 
                dynamicState={dynamicState} 
            />
        </Grid>
        <Grid  size={{lg:4,md:1,sm:1,xs:0}} ></Grid>
        <Grid  size={{lg:1,md:2,sm:2,xs:4}} className="align-content-center">
            <Button
            component="label"
            className='icon-main-page'
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={ language === keyLang[0] && <ShoppingCart />} 
            endIcon={ language === keyLang[1] &&  <ShoppingCart />}
            onClick={() => navigate(`/${allLinks[6]}`)}>
              <Translation data="cart.main_page"  /> 
            </Button>
        </Grid>
        <Grid  size={{lg:1,md:2,sm:2,xs:4}} className="align-content-center">
            <Button
            component="label"
            className='icon-main-page align-content-center'
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            startIcon={  language === keyLang[0] &&  <ListAltIcon />}
            endIcon={ language === keyLang[1] && <ListAltIcon />}
            onClick={() => navigate(`/${allLinks[15]}`)}>
                <Translation data="order.orders"  /> 
            </Button>
        </Grid>
    </Grid>
   
  );
}
