import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Box, CircularProgress } from "@mui/material";
import Translation from "../../../utils/Translation";
import { Controller } from "react-hook-form";
import { getValByLang } from "../../../utils/helpers";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { keyLang } from "../../../utils/helpers";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useState, useEffect } from "react";
import CategoryBreadCrumb from "./CategoryBreadCrumb";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

const getMarkParent = (language) => {
  if (language === keyLang[0]) {
    return (
      <ArrowForwardIosIcon
        style={{ fontWeight: "bold", fontSize: "14px", marginRight: "5px" }}
      />
    );
  } else {
    return (
      <ArrowBackIosNewIcon
        style={{ fontWeight: "bold", fontSize: "14px", marginLeft: "5px" }}
      />
    );
  }
};


export default function CategoryInput({
  setValue,
  control,
  translate,
  language,
  categories,
  getValues
}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [categoryList, setCategoryList] = useState(categories);
  const [beforeIsParent, setbeforeIsParent] = useState(false);

  const bfsFilterCategories = (categoryList, query, language) => {
    const result = [];
    const queue = [...categoryList]; // Initialize the queue with top-level categories

    while (queue.length > 0) {
      const current = queue.shift(); // Dequeue the first element

      // Check if the current category matches the query
      if (
        getValByLang(current.name, language)
          .toLowerCase()
          .includes(query.toLowerCase())
      ) {
        result.push(current); // Add the matching category to the result
      }

      // If the current category has children, enqueue them
      if (current.child && current.child.length > 0) {
        queue.push(
          ...current.child.map((child) => ({
            ...child,
            parent: getValByLang(current.name, language),
          }))
        );
      }
    }

    return result;
  };

  const fetchCategories = async (query) => {

    setLoading(true);
    setOpen(false);
    setOptions([]);
    
    await sleep(500); // Simulate delay

    let filteredCategories = [];

    if (query)
      filteredCategories = bfsFilterCategories(categoryList, query, language);
    else
      filteredCategories = categoryList.filter((cat) =>
        getValByLang(cat.name, language)
          .toLowerCase()
          .includes(query.toLowerCase())
      );

    setOptions(filteredCategories); // Update options
    setOpen(true);
    setLoading(false);
  };

  const handleOpen = () => {
    setOpen(true);
    fetchCategories(searchTerm); // Fetch all categories on open
  };

  const handleClose = () => {
    setOpen(false);
    setOptions([]); // Clear options on close
  };

  const handleInputChange = (_, value,reason) => {
      setSearchTerm(value); // Update search term state
      if (open) fetchCategories(value); // Fetch matching categories
  };

  const setCategoryValid = (selectedOptions) => {

    let childs = [];
    let child = [];
    let result =[]

    if (selectedOptions.length > 1) {

        childs = selectedOptions[selectedOptions.length - 2].child;
        child = selectedOptions[selectedOptions.length - 1];
        result = childs.filter((val) => val.id ===  child.id);

        if(result && result.length === 0 ){
          let newObject = selectedOptions[selectedOptions.length - 1];
          selectedOptions = selectedOptions.slice(0, selectedOptions.length - 2);
          selectedOptions.push(newObject);
          setValue("categories", selectedOptions);
        }
    }
  };

  const handleSelection = (selectedOptions) => {

    if (selectedOptions.length) {
      setCategoryValid(selectedOptions);

      // set search value
      setSearchTerm(getValByLang(selectedOptions[selectedOptions.length - 1].name, language)); 

      // choose children
      if (selectedOptions[selectedOptions.length - 1].child.length === 0) {
        // remove last element for choose one option from children
        if (beforeIsParent) {
          let newObject = selectedOptions[selectedOptions.length - 1];
          selectedOptions = selectedOptions.slice(
            0,
            selectedOptions.length - 2
          );
          selectedOptions.push(newObject);

          setValue("categories", selectedOptions);
        }

        setbeforeIsParent(true);
      } else {
        // choose parents
        setCategoryList(selectedOptions[selectedOptions.length - 1].child); // set new list for parents
        setbeforeIsParent(false);
      }
    } else {
      // reset all list
      setCategoryList(categories); // set new list for parents
      console.log("No categories selected");
    }

  };

  //reset list
  useEffect(() => {
    fetchCategories(''); // get data based on category
  }, [categoryList]);


  return (
     <>
      <Box>
      {getValues("categories_update").length > 0 &&
          
          <CategoryBreadCrumb
            category ={getValues("categories_update")}
            language ={language}
        />
      }
      </Box>
 
      <Box display="-webkit-inline-box" gap={2} className="mt-1">
  
      <Controller
        name="categories"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState }) => (
          <Autocomplete
            multiple
            id="checkboxes-categories-demo"
            options={options}
            disableCloseOnSelect
            forcePopupIcon={!!options.length} // Show dropdown only if options exist
            getOptionLabel={(option) => getValByLang(option?.name, language)}
            value={value || []}
            inputValue={searchTerm} // Bind searchTerm to inputValue
            onInputChange={handleInputChange} // Update search term
            onChange={(_, selectedOptions) => {
              onChange(selectedOptions); // Update React Hook Form value
              handleSelection(selectedOptions); // Call custom action
            }}
            onOpen={handleOpen}
            onClose={handleClose}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key || option.id} {...optionProps}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.child.length > 0 ? (
                    <>
                      {option?.parent ? `${option.parent}` : ""}
                      {getMarkParent(language)}
                      {getValByLang(option?.name, language)}
                    </>
                  ) : (
                    <>
                      {option?.parent ? `${option.parent}` : ""}
                      {option?.parent && getMarkParent(language)}
                      {getValByLang(option?.name, language)}
                    </>
                  )}
                </li>
              );
            }}
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={<Translation data="store_seller.category" />}
                placeholder={translate("store_seller.search")}
                error={!!fieldState.error}
                helperText={
                  fieldState.error ? "Please select at least one category." : ""
                }
                slotProps={{
                  input: {
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  },
                }}
              />
            )}
          />
        )}
      />
      </Box>
     </>
  );
}
