import React from "react";
import Container from "@mui/material/Container";
import { memo } from "react";
import { Alert, Stack } from "@mui/material";
import { sellerIsDisabled, sellerIsPendding, sellerIsRejected } from "../../../utils/helpers";
import Translation from "../../../utils/Translation";


function SellerStatus({sellerInfo}) {

  return (
    <Container maxWidth="xl">

      <Stack sx={{ width: '100%'}} className="store-status">
              {sellerIsPendding(sellerInfo)  &&  <Alert severity="info">  <Translation data="store_seller.seller_pendding" /> </Alert>}
              {sellerIsRejected(sellerInfo)  && <Alert severity="warning"> <Translation data="store_seller.seller_rejected" />  </Alert>}
              {sellerIsDisabled(sellerInfo)  &&  <Alert severity="error"> <Translation data="store_seller.seller_disabled" />  </Alert>}
      </Stack>
       
    </Container>
  );
}
export default memo(SellerStatus);

