import React, { memo} from "react";
import { Add, CloudUploadOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { Button, Box } from "@mui/material";
import Translation from "../../../utils/Translation";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddCustomAttr({ appendAttr, setValue, idx, getValues,selectedImages,setSelectedImages }) {


  const handleFileChange = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to an array

    if(selectedImages.length !==0){ // add multiple images when open window more than one
      setSelectedImages((prev) => [...prev, ...files]); // Append new images
      let allImages = getValues(`attributes.${idx}.images`).concat(files);
      setValue(`attributes.${idx}.images`, allImages);
    }else{ // add new images
      setSelectedImages((prev) => [...prev, ...files]); // Append new images
      setValue(`attributes.${idx}.images`, files);
    }

  };

console.log(selectedImages)
  return (
    <Box>
      {/* Preview Selected Images */}
      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
        {selectedImages.map((file, index) => (
          <img
            key={index}
            src={URL.createObjectURL(file)}
            alt={`preview-${index}`}
            style={{
              width: "auto",
              height: 150,
              objectFit: "cover",
              borderRadius: 8,
            }}
          />
        ))}
        {/* {show last images} */}
        {selectedImages.length === 0 &&
          getValues(`attributes.${idx}.images`).map((imgUrl, index) => (
            <img
              key={index}
              src={imgUrl.url}
              alt={`preview-${index}`}
              style={{
                width: "auto",
                height: 150,
                objectFit: "cover",
                borderRadius: 8,
              }}
            />
          ))}
      </Box>

      <Box sx={{ display: "block", mt: 4 }}>
        <Button
          className="add-img-attribute"
          variant="contained"
          startIcon={<CloudUploadOutlined />}
          component="label"
          sx={{ mr: 1, ml: 1 }}
        >
          <Translation data="store_seller.upload_imgs" />
          <VisuallyHiddenInput
            type="file"
            multiple
            onChange={handleFileChange}
          />
        </Button>
        <Button
          variant="contained"
          className="add-custom-attribute limit-responsive"
          onClick={() => appendAttr({ name: "", value: "" })} // Append an empty attribute
          startIcon={<Add />}
        >
          <Translation data="store_seller.add_custom_attribute" />
        </Button>
      </Box>
    </Box>
  );
}

export default memo(AddCustomAttr);
