import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Typography } from "@mui/material";
import { getValByLang } from "../../../../utils/helpers";
import Translation from "../../../../utils/Translation";
import { memo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

function SliderBrand({brands,language}) {

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "30px",
    slidesToShow: 4,
    speed: 500,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 100000
  };

  return (
    <>
    <Box>
        <Typography variant="h5" component="h5" className="font-weight-600 mb-1">
        <Translation data="store.all_brands" />
        </Typography>
    </Box>
    <div className="brand-slider-container">
        <Slider {...settings}>
        {brands.slice(0,100).map((brand) => (
            <Box
            key={brand.id}
            className="brand-slider-item"
            >
             <LazyLoadImage             
                height={225}
                width={300}
                effect="blur"
                src={brand?.img ? brand.img : require('../../../../assets/image/seller/store_default.jpg')}
                alt={brand.name}
                className="brand-image"
              />
               <p className="brand-name">{getValByLang(brand.name, language)}</p>
            </Box>
        ))}
        </Slider>
    </div>
   </>
  );
}

export default memo(SliderBrand);
