import {
  Box,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddAddress from "./AddAddress";
import { memo } from "react";

function Address({data,setAddress}) {

  const { t, i18n } = useTranslation();
  const [index,setIndex] = useState(0);

  const handleAddressChange = (event) => {
    let idx = Number(event.target.value);
    setIndex(idx);
    setAddress({
      idx: idx,
      fav_id: data[idx].fav_id,
      area: data[idx].address_data.area,
      city: data[idx].address_data.city,
      mobile: data[idx].address_data.mobile,
      street: data[idx].address_data.street,
      coordinates: data[idx].address_data.coordinates,
      friendly_name: data[idx].address_data.friendly_name,
      villa_bldng_no: data[idx].address_data.villa_bldng_no,
      location_address: data[idx].address_data.location_address,
      emirate: data[idx].address_data?.emirate,
      formatted_address: data[idx].address_data?.formatted_address
    });
  };

  useEffect(()=> {

    if(data && data.length > 0) {
      setIndex(0);
      setAddress({
        idx: 0,
        fav_id: data[0].fav_id,
        area: data[0].address_data.area,
        city: data[0].address_data.city,
        mobile: data[0].address_data.mobile,
        street: data[0].address_data.street,
        coordinates: data[0].address_data.coordinates,
        friendly_name: data[0].address_data.friendly_name,
        villa_bldng_no: data[0].address_data.villa_bldng_no,
        location_address: data[0].address_data.location_address,  
        emirate: data[0].address_data?.emirate,
        formatted_address: data[0].address_data?.formatted_address
      });
    }

  },[data])

  return (
    <>
        <CardContent>
          <Box sx={{display:"flex",paddingBottom:"20px"}}>
            <Typography variant="h6" sx={{width:"90%"}}  gutterBottom>
              {t("checkout.select_address")}
            </Typography>
            <AddAddress sx={{width:"10%"}}  />
          </Box>
          {data && data.length > 0 && (
              <Box  className="address-section">
                <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={index}
                      name="radio-buttons-group">
                      {data.map((val,idx) => {
                        return (<FormControlLabel key={idx} value={idx} control={<Radio />} checked={index === idx ?true:false}
                        label={
                          <Box className="address-border">
                            <Typography variant="p" component="b" >{val?.address_data?.friendly_name}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              <b> {t("checkout.city")} </b>{val?.address_data?.city ? (
                                <>
                                  {val?.address_data?.city}
                                </>
                              ) : t("checkout.no_additional_details_provided") 
                              }
                          </Typography>

                            <Typography variant="body2" color="textSecondary">
                              <b>{t("checkout.area")}</b> {val?.address_data?.area ? (
                                <>
                                {val?.address_data?.area}
                                </>
                              ) : t("checkout.no_additional_details_provided") 
                              }
                            </Typography>

                          <Typography variant="body2" color="textSecondary">
                              <b>{t("checkout.mobile")} </b> {val?.address_data?.mobile ? (
                                <>
                                {val?.address_data?.mobile}
                                </>
                              ) :  t("checkout.no_additional_details_provided") 
                              }
                          </Typography>

                          </Box>
                          }
                            onChange={(event) => handleAddressChange(event)} />)
                      })}
            
                    </RadioGroup>
                  </FormControl>
              </Box>
            )}
        </CardContent>
     
    </>
  );
}

export default memo(Address);
