import React, { memo, useEffect, useState } from "react";
import { CircularProgress, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import { Controller } from "react-hook-form";
import { Box } from "@mui/material";
import { attrNameFullObject, getValByLang } from "../../../utils/helpers";
import { sleep } from "./BrandInput";
import Translation from "../../../utils/Translation";
import AddAttribute from "./AddAttribute";


function CustomAttributesValue({
  idx,
  index,
  language,
  register,
  errors,
  control,
  setValue,
  getValues
}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [AddAttr, setAddAttr] = useState(false);

  const fetchAttrs = async (query) => {

    setOptions([]); // Delete All Options

    setLoading(true);
    await sleep(500); // Simulate delay

    let filteredAttr = [];
    if (
      getValues(`attributes.${idx}.customAttributes.${index}.name`)?.length > 0 &&
      getValues(`attributes.${idx}.customAttributes.${index}.name`)[0]?.values?.length > 0
    ) {
      filteredAttr = getValues(`attributes.${idx}.customAttributes.${index}.name`)[0]?.values.filter((attr) =>
        getValByLang(attr.value, language).toLowerCase().includes(query.toLowerCase()) 
      );
      setOptions(filteredAttr.slice(0, 25)); // Update options
    }
    setLoading(false);

    setAddAttr(filteredAttr.length > 0 || query === '' ? false : true);
  };

  const handleOpen = () => {
    setOpen(true);
    fetchAttrs(searchTerm); // Fetch brands using the current search term
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (_, value, reason) => {
    if (reason === "input" || reason === "selectOption" || reason === "clear") {
      setSearchTerm(value); // Update search term
      fetchAttrs(value); // Fetch matching brands on input change
    }
  };

  const handleAddNewAttr = (index) => {
    setValue(`attributes.${idx}.customAttributes.${index}.value`, [
      { value_id: null, value: attrNameFullObject(searchTerm), new: true },
    ]);
    setAddAttr(false);
    setSearchTerm('');
  };

  return (
    <Grid size={{ lg: 5 , md:5 ,sm:5 , xs:12 }}>
      <Box>
        <Controller
          control={control}
          name={`attributes.${idx}.customAttributes.${index}.value`}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              multiple
              options={options}
              forcePopupIcon={!!options.length}
              getOptionLabel={(option) => getValByLang(option?.value, language)}
              value={Array.isArray(value) ? value : []} // Ensure value is always an array
              inputValue={searchTerm}
              onInputChange={handleInputChange}
              onChange={(_, selectedOption) => {
                console.log("selectedOption ", selectedOption);
                onChange(Array.isArray(selectedOption) ? selectedOption : []); // Ensure it's an array
                if (selectedOption.length > 0) {
                  setValue(
                    `attributes.${idx}.customAttributes.${index}.value`,
                    [selectedOption[selectedOption.length - 1]]
                  );
                } else {
                  setValue(
                    `attributes.${idx}.customAttributes.${index}.value`,
                    []
                  );
                }
              }}
              onOpen={handleOpen}
              onClose={handleClose}
              isOptionEqualToValue={(option, value) =>
                option.value_id === value.value_id
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {getValByLang(option?.value, language)}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={<Translation data="store_seller.value" />}
                  error={
                    !!errors.attributes?.[idx]?.customAttributes?.[index]?.value
                  }
                  helperText={
                    errors.attributes?.[idx]?.customAttributes?.[index]?.value
                      ?.message
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
        />

        {AddAttr &&
          getValues(`attributes.${idx}.customAttributes.${index}.name`)
            ?.length > 0 && (
            <AddAttribute handleAddNewAttr={() => handleAddNewAttr(index)} />
          )}
      </Box>
    </Grid>
  );
}

export default memo(CustomAttributesValue);
