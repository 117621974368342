import { Box } from "@mui/material";
import SliderAds from "./SliderAds";
import HeaderMainPage from "./HeaderMainPage";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import SliderCategory from "./SliderCategory";
import SliderBrand from "./SliderBrand";
import ProductList from "./ProductList";

export default function MainPage() {
  const {
    isLoading,
    storePage,
    categories,
    brands,
    categories_without_arrange,
  } = useSelector((state) => state.store);
  const { language } = useSelector((state) => state.global);

  return (
    <>
      {isLoading ? (
        <CircularProgress className="full-preloader-position" />
      ) : (
        <Box className="main-store">
          <HeaderMainPage />

          {storePage?.slider && (
            <Box className="slider-ads">
              <SliderAds sliders={storePage?.slider} />
            </Box>
          )}

          {categories && (
            <Box className="slider-category">
              <SliderCategory categories={categories} language={language} />
            </Box>
          )}

          {storePage?.productOrderByPrice.length > 0 && (
            <Box className="product-border">
              <ProductList
                title="store.deals"
                products={storePage?.productOrderByPrice}
                list_of_catg={categories_without_arrange}
                language={language}
              />
            </Box>
          )}

          {storePage?.productReview.length > 0 && (
            <Box className="product-border">
              <ProductList
                title="store.best_rated"
                products={storePage?.productReview}
                list_of_catg={categories_without_arrange}
                language={language}
              />
            </Box>
          )}

          {storePage?.productNewArrivers.length > 0 && (
            <Box className="product-border">
              <ProductList
                title="store.last_products"
                products={storePage?.productNewArrivers}
                list_of_catg={categories_without_arrange}
                language={language}
              />
            </Box>
          )}

          {brands && (
            <Box className="slider-brands">
              <SliderBrand brands={brands} language={language} />
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
