import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from "react-router-dom";
import ProductCard from "../store/ProductCard";


function SliderProduct({ products, setReadyForShowAttr }) {
  const { id } = useParams();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 5
  };

  return (
    <div style={{ width: "100%", maxWidth: "1400px", margin: "auto" }}>
      <Slider className="slider-product-card" {...settings}>
        {products.slice(0, 20).map((slide,index) => (
          slide.id !== id && <ProductCard key={index} setReadyForShowAttr={setReadyForShowAttr} product={slide} />
        ))}
      </Slider>
    </div>
  );
}

export default SliderProduct;
