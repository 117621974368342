import { Box } from '@mui/material'
import TagInput from '../../../components/store_seller/product/TagInput';
import BrandInput from '../../../components/store_seller/product/BrandInput';
import CategoryInput from '../../../components/store_seller/product/CategoryInput';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { memo } from 'react';

function Attributes({control,language,setValue,getValues,register }) {

  const { t, i18n } = useTranslation();
  const {categories,brands,tags}  = useSelector((state) => state.store);

  return (
     <Box sx={{ 
       width: 600,
       maxWidth: '100%',
       paddingTop:'3%',
       paddingBottom:'3%',
       paddingLeft:'2%'
       }} className = "attribute-form"
       >

        <CategoryInput setValue={setValue}  getValues={getValues} language={language} categories={categories} control={control} translate={t} />
      
        <BrandInput register={register} getValues={getValues} setValue={setValue} language={language}  brands={brands}  control={control} translate={t}  />

        <TagInput getValues={getValues}  setValue={setValue}  language={language}  tags={tags}  control={control} translate={t}    /> 
        
     </Box>
  )
}

export default memo(Attributes)
