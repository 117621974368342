import * as React from 'react';
import SliderHome from './SliderHome';
import Request from './Request';
import Order from './Order';
import MainTitle from '../../../components/headline/MainTitle';

export default function CustomerHome() {
   

  return (
  <>
    <MainTitle   title="home.main-title"  />
    <SliderHome />
    <Order />
    <Request />
  </>
  );
}
