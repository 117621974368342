import React, { useEffect } from "react";
import { Box, Typography, Button, Card, CardContent, Container } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";
import { allLinks } from "../../../utils/helpers";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PaymentStatus() {

  const location = useLocation();
  const previousPage = location.state?.from || "unKnow Url";
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const handleGoToStore = () => navigate(`/${allLinks[4]}`); // Replace "/" with your desired route

  useEffect(()=>{
    if(previousPage !== allLinks[7]) handleGoToStore()
  },[previousPage]);

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", marginTop: "8rem" }}>
      <Card>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

            {location.state?.error === true  &&  <ErrorOutlineIcon sx={{ fontSize: 80, color: "red", marginBottom: 2 }} />  }
            {location.state?.error === false &&   <CheckCircleOutlineIcon sx={{ fontSize: 80, color: "green", marginBottom: 2 }} /> }
            {location.state?.title && 
              <Typography variant="h4" gutterBottom>
              {location.state?.title}
              </Typography>
            }
            {location.state?.body && 
              <Typography variant="body1" color="textSecondary" gutterBottom>
              {location.state?.body}
            </Typography>
            }
            <Button variant="contained" color="primary" onClick={handleGoToStore}  sx={{ marginTop: "1rem" }}>
             
              {t(`payment_status.go_to_store`)}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  )
  
}

