import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { useDispatch } from 'react-redux';
export default function DeliveryTimeBox({value,action,deliveryTimesOption}) {

  const dispatch = useDispatch();


  return (
    <>
        {deliveryTimesOption.map((obj,idx)=>{
        return (
                    <Link to='/add_request'    
                    className='text-undecoration' key={idx} onClick={() => dispatch(action(obj.value))}>
                    <Box >
                        <Container  className={`box-request ${value === obj.value ? 'active':''}`}>
                        <Grid container spacing={3}>
                            <Grid>
                               { obj.icon}
                            </Grid>

                            <Grid size={{xs:10}}  className="address-name"  >
                            
                                <Typography variant="b" component="h3">
                                    {obj.title}
                                </Typography>
                            
                            </Grid>

                        </Grid>
 

                        <Box className='delivery-time'>
                            <Box component='span'>

                            {obj.description}

                            </Box>
                        </Box>
                     
                        
                        </Container>  
                    </Box>
                </Link>
                )
        })}
    </>
  )
}
