import { memo } from "react";
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Translation from "../../../utils/Translation";
import { Controller } from "react-hook-form";

const ProductTypeCheckbox = ({ setValue, control }) => {
  const handleChange = (selectedValue) => {
    setValue("productType", selectedValue); // Update form state with only one value
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <Translation data="store_seller.product_type" />
      </FormLabel>
      <FormGroup sx={{ display: "flow" }}>
        {["new", "used", "refurbished"].map((type) => (
          <Controller
            key={type}
            name="productType"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value === type} // Check only if this is the selected value
                    onChange={() => handleChange(type)} // Update selection
                  />
                }
                label={<Translation data={`store_seller.product_type_${type}`} />}
              />
            )}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default memo(ProductTypeCheckbox);
