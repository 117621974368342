
import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import ProfileService from "./profile_service";
import Translation from "../../utils/Translation";

const initialState = {
    isLoading:false,
    userProfile: null,
    errorProfile: null,
    statusProfile: 'idle',
    statusUpdateProfile: 'idle',
    statusVerifyEmail:'idle',
    verifyEmail:false,
    isDriver:false,
    nickName:null,
    email:null,
    profile_url:'',
    userType:0,
    findErrorProfile:false
};

// Async action creator
export const  loadProfileAsync = createAsyncThunk("type/loadProfileAsync", async ({auth,dynamicState},{rejectWithValue}) => {

  try{

  const profile = await ProfileService.loadProfile({auth,dynamicState}); // Assuming loadProfile is an async function
 
  return profile;

  }catch (error){

    return rejectWithValue({error:error.getError().message})
  }
});


export const  refreshProfileAsync = createAsyncThunk("type/refreshProfileAsync", async ({auth,dynamicState},{rejectWithValue}) => {

  try{

  const profile = await ProfileService.loadProfile({auth,dynamicState}); // Assuming loadProfile is an async function
 
  return profile;

  }catch (error){

    return rejectWithValue({error:error.getError().message})
  }
});


export const  updateProfileAsync = createAsyncThunk("type/updateProfileAsync", async ({auth,dynamicState,nickName,email,profile_url,userFormType},{dispatch,rejectWithValue}) => {
      
  try{
    const updatedProfile = await ProfileService.updateProfile({auth,dynamicState,nickName,email,profile_url,userFormType}); // Assuming updateProfile is an async function
    dispatch( updateUserProfile({nickName,email,profile_url,userFormType}));

    return updatedProfile;
  }catch(error){
  
    return rejectWithValue({error:error.getError().message})
  }
   
});


export const sendVerifyEmailAsync =  createAsyncThunk("type/sendVerifyEmailAsync", async ({auth,dynamicState},{rejectWithValue}) => {
      
  try{ 
    const sendVerifyEmail = await ProfileService.sendVerifyEmail({auth,dynamicState}); // Assuming updateProfile is an async function
   
    return sendVerifyEmail;
  }catch(error){
    return rejectWithValue({error:error.getError().message})
  }
   
});

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setLoading: (state, action) => {
        state.isLoading = action.payload.isLoading;
        },
        setStatusProfile: (state, action) => {
          state.statusProfile = action.payload.statusProfile;
        },
        setUserProfile: (state,action) => {
           state.userProfile = action.payload.userProfile;
        },
        updateUserProfile:(state,action) => {
          state.nickName = action.payload.nickName;
          state.email = action.payload.email;
          state.profile_url = action.payload.profile_url;
          state.userType = action.payload.userFormType;
        },setStatusVerifyEmail:(state,action) => {
          state.statusVerifyEmail = action.payload;
        },setVerifyEmail : (state,action) => {
          state.verifyEmail = action.payload;
        },setProfile_url : (state,action) => {
          state.profile_url = action.payload
        },setFindErrorProfile : (state,action) => {
          state.findErrorProfile = action.payload
        },setErrorProfile : (state,action) => {
          state.errorProfile = action.payload
        }
    },
    extraReducers: builder => {
        builder
          .addCase(loadProfileAsync.pending, (state, action) => {
            state.isLoading = true;
            state.statusProfile = 'loading';
            state.errorProfile = null;
            state.findErrorProfile = false;
          })
          .addCase(loadProfileAsync.fulfilled, (state, action) => {
            state.userProfile = action.payload;
            state.verifyEmail = action.payload.email_verified;
            state.isLoading = false;
            state.statusProfile = 'succeeded';
            state.errorProfile = null;
          })
          .addCase(loadProfileAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.statusProfile = 'failed';
            state.errorProfile = action.payload.error;
            state.findErrorProfile = true;
            console.log("Error in Get Profile : "+ action.payload.error);
          })
          .addCase(refreshProfileAsync.pending, (state, action) => {
            state.errorProfile = null;
            state.findErrorProfile = false;
          })
          .addCase(refreshProfileAsync.fulfilled, (state, action) => {
            state.userProfile = action.payload;
            state.verifyEmail = action.payload.email_verified;
            state.errorProfile = null;
          })
          .addCase(refreshProfileAsync.rejected, (state, action) => {
            state.errorProfile = action.payload.error;
            state.findErrorProfile = true;
            console.log("Error in Get Profile : "+ action.payload.error);
          })
          .addCase(updateProfileAsync.pending, (state, action) => {
            // state.isLoading = true;
            if(state.userProfile === 0) state.statusProfile = "loading"
            else  state.statusUpdateProfile = 'loading';
            state.errorProfile = null;
          })
          .addCase(updateProfileAsync.fulfilled, (state, action) => {
            // state.isLoading = false;
            if(state.userProfile === 0) state.statusProfile = "succeeded"
            else  state.statusUpdateProfile = 'succeeded';
            state.userProfile = {...state.userProfile,nickname:state.nickName,email:state.email,
              mobile_number: localStorage.getItem("phoneNumber")
              ,profile_image_url:state.profile_url,user_type: state.userType}
            state.errorProfile = null;
          })
          .addCase(updateProfileAsync.rejected, (state, action) => {
            // state.isLoading = false;
            if(state.userProfile === 0) state.statusProfile = "failed"
            else  state.statusUpdateProfile = 'failed';
            state.errorProfile = action.payload.error;
            console.log("Error in Update Profile : "+ action.payload.error);
          })
          
          .addCase(sendVerifyEmailAsync.pending, (state, action) => {
            state.statusVerifyEmail = 'loading';
            state.errorProfile = null;
          })
          .addCase(sendVerifyEmailAsync.fulfilled, (state, action) => {
            state.statusVerifyEmail = 'succeeded';
            state.errorProfile = null;
          })
          .addCase(sendVerifyEmailAsync.rejected, (state, action) => {
            state.statusVerifyEmail = 'failed';
            state.errorProfile = action.payload.error === undefined ?  <Translation data={"profile.cannot_send_request"}  />  :action.payload.error;
            console.log("Error in Verify Email Profile : "+ action.payload.error);
          })
      }
});


export const {setStatusProfile,setUserProfile,updateUserProfile,
  setStatusVerifyEmail,setVerifyEmail,setLoading,setProfile_url,setFindErrorProfile,setErrorProfile} = profileSlice.actions;
export default profileSlice.reducer;