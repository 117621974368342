import React, { memo } from "react";
import { Box, Button, TextField, IconButton, Typography } from "@mui/material";

import { Add, Delete } from "@mui/icons-material";
import Translation from "../../../utils/Translation";
import Grid from "@mui/material/Grid2";
import { keyLang } from "../../../utils/helpers";

function SpecificationList({
  register,
  errors,
  language,
  fields,
  remove,
  append,
  fieldsAttr,
  removeAttr,
  appendAttr,
}) {


  return (
    <>
      <Box
        className={
          language === keyLang[1]
            ? "specification-section hidden"
            : "specification-section"
        }
      >
        <Typography component="p">
          <Translation data="store_seller.specifications" />
        </Typography>

        <Grid container spacing={2} className="form-specification">
          {fields.map((item, index) => (
            <React.Fragment key={item.id}>
              {/* Specification Name */}
              <Grid size={{ lg: 3 }}>
                <TextField
                  label={"Specification Name (English)"}
                  variant="outlined"
                  fullWidth
                  {...register(`specifications_en.${index}.key`)}
                  error={!!errors.specifications_en?.[index]?.key?.[language]}
                  helperText={
                    errors.specifications_en?.[index]?.key?.[language]?.message
                  }
                />
              </Grid>

              {/* Specification Description */}
              <Grid size={{ lg: 7 }}>
                <TextField
                  label={"Specification Description (English)"}
                  variant="outlined"
                  fullWidth
                  {...register(`specifications_en.${index}.value`)}
                  error={!!errors.specifications_en?.[index]?.value?.[language]}
                  helperText={
                    errors.specifications_en?.[index]?.value?.[language]
                      ?.message
                  }
                />
              </Grid>

              {/* Delete Button */}
              <Grid size={{ lg: 2 }} className="delete-specifications">
                <IconButton color="error" onClick={() => remove(index)}>
                  <Delete />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>

        {/* Add Specification Button */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
          <Button
            className="icon-arabic"
            variant="outlined"
            onClick={() => append({ key: "", value: "" })}
            endIcon={<Add />}
          >
            <Translation data="store_seller.add_specifications" />
          </Button>
        </Box>
      </Box>

      <Box
        className={
          language === keyLang[0]
            ? "specification-section hidden"
            : "specification-section"
        }
      >
        <Typography component="p">
          <Translation data="store_seller.specifications" />
        </Typography>

        <Grid container spacing={2} className="form-specification">
          {fieldsAttr.map((item, index) => (
            <React.Fragment key={item.id}>
              {/* Specification Name */}
              <Grid size={{ lg: 3 }}>
                <TextField
                  label={"اسم المواصفات (بالعربية)"}
                  variant="outlined"
                  fullWidth
                  {...register(`specifications_ar.${index}.key`)}
                  error={!!errors.specifications_ar?.[index]?.key?.[language]}
                  helperText={
                    errors.specifications_ar?.[index]?.key?.[language]?.message
                  }
                />
              </Grid>

              {/* Specification Description */}
              <Grid size={{ lg: 7 }}>
                <TextField
                  label={"وصف المواصفات (بالعربية)"}
                  variant="outlined"
                  fullWidth
                  {...register(`specifications_ar.${index}.value`)}
                  error={!!errors.specifications_ar?.[index]?.value?.[language]}
                  helperText={
                    errors.specifications_ar?.[index]?.value?.[language]
                      ?.message
                  }
                />
              </Grid>

              {/* Delete Button */}
              <Grid size={{ lg: 2 }} className="delete-specifications">
                <IconButton color="error" onClick={() => removeAttr(index)}>
                  <Delete />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>

        {/* Add Specification Button */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
          <Button
            className="icon-arabic"
            variant="outlined"
            onClick={() => appendAttr({ key: "", value: "" })}
            startIcon={<Add />}
          >
            <Translation data="store_seller.add_specifications" />
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default memo(SpecificationList);
