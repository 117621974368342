import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { useSelector, useDispatch } from 'react-redux';
import Translation from '../../../utils/Translation';
import { StyledTableCell, StyledTableRow } from './StoreSeller';
import { convertDateProduct, getValByLang, removeHtmlCharacter, truncateText } from '../../../utils/helpers';
import { deleteProductAsync, setProductIdDeleted } from '../../../services/store/storeSlice';
import { useNavigate } from 'react-router-dom';
import { allLinks } from '../../../utils/helpers';

const StyledTableCellDetails = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e5e2e2",
    color: "#000000",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

function TableRows(props) {
  const { product, language, dynamicState, auth } = props;
  const { categories_without_arrange, brands, colors, sizes } = useSelector((state) => state.store);
  const { data } = useSelector((state) => state.address);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [storeName, setStoreName] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [brandName, setBrandName] = useState("");
  const [colorName, setColorName] = useState([]);
  const [sizeName, setSizeName] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAttrName = useCallback(() => {
    let colorNameArray = [];
    let sizeNameArray = [];
    let storageNameArray = [];

    setCategoryName(getValByLang(categories_without_arrange.filter((val) => product.categories.includes(val.id))[0]?.name,language));
    setBrandName(getValByLang(brands.filter((val) => product.brands.includes(val.id))[0]?.name,language));

    if (product?.details.length > 0) {
      product.details.forEach((value, idx) => {
        colorNameArray[idx] = getValByLang(colors.filter((val) => value.color_id === val.id)[0]?.name,language);
        sizeNameArray[idx] = getValByLang(sizes.filter((val) => value.size_id === val.id)[0]?.name,language);
        storageNameArray[idx] = data.filter((val) => value.storage_id  ===  val.fav_id)[0]?.address_data?.friendly_name
      });

      setColorName(colorNameArray);
      setSizeName(sizeNameArray);
      setStoreName(storageNameArray);
    }
  }, [product, data, categories_without_arrange, brands, colors, sizes]);

  const handleProductDelete = (productId) => {
    dispatch(setProductIdDeleted(productId));
    dispatch(deleteProductAsync({ auth, dynamicState, productId }));
    setConfirmOpen(false); // Close the dialog after deletion
  };

  const handleOpenConfirm = () => {
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
  };

  useEffect(() => {
    getAttrName();
  }, [getAttrName]);


  const handleUpdateProductPage = (id) =>{ 
    navigate(`/${allLinks[17]}/${id}`)
  }

  return (
    <>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {product?.id}
        </StyledTableCell>
        <StyledTableCell>{getValByLang(product?.name, language)}</StyledTableCell>
        <StyledTableCell>
          <Box>
            <img loading="lazy" className="product-img" src={product?.img} alt="Product" />
          </Box>
        </StyledTableCell>
        <StyledTableCell>{product?.review}</StyledTableCell>
        <StyledTableCell>
           <Box className={`product_${product?.status}`}>
              {product?.status}
           </Box>
        </StyledTableCell>
        <StyledTableCell>{product?.rejected_reason}</StyledTableCell>
        <StyledTableCell>{convertDateProduct(product?.created_at?product?.created_at:new Date())}</StyledTableCell>
        <StyledTableCell>
          <Box display="flex" gap={2}>
            <Button onClick={() => handleUpdateProductPage(product?.id)} component="label" variant="outlined">
              <Translation data="offer.edit" />
            </Button>
            <Button
              onClick={handleOpenConfirm}
              component="label"
              variant="contained"
              className="delete-address"
              color="error"
            >
              <Translation data="offer.delete" />
            </Button>
          </Box>
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className="product-details">
              <Typography className='text-right' variant="h6" gutterBottom>
                <Translation data="store_seller.details" />
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCellDetails><Translation data="store_seller.product_id"  /> </StyledTableCellDetails>
                        <StyledTableCellDetails><Translation data="store_seller.inventory"  /> </StyledTableCellDetails>
                        <StyledTableCellDetails><Translation data="store_seller.category"  /> </StyledTableCellDetails>
                        <StyledTableCellDetails><Translation data="store_seller.brand"  /> </StyledTableCellDetails>
                        <StyledTableCellDetails><Translation data="store_seller.color"  /> </StyledTableCellDetails>
                        <StyledTableCellDetails><Translation data="store_seller.size"  /> </StyledTableCellDetails>
                        <StyledTableCellDetails><Translation data="store_seller.quantity"  /> </StyledTableCellDetails>
                        <StyledTableCellDetails><Translation data="store_seller.sold_amount"  /> </StyledTableCellDetails>
                        <StyledTableCellDetails><Translation data="store_seller.regular_price"  /> </StyledTableCellDetails>
                        <StyledTableCellDetails><Translation data="store_seller.sale_price"  /> </StyledTableCellDetails>
                      </StyledTableRow>
                </TableHead>
                <TableBody>
                  {product?.details  &&  product?.details.length > 0  &&  product?.details.map((detail, idx) => (
                    <StyledTableRow key={idx}>
                      <StyledTableCell component="th" scope="row"> {detail.id} </StyledTableCell>
                      <StyledTableCell component="th" scope="row"> {storeName[idx]} </StyledTableCell>
                      <StyledTableCell component="th" scope="row"> {categoryName} </StyledTableCell>
                      <StyledTableCell component="th" scope="row"> {brandName}</StyledTableCell>
                      <StyledTableCell >{colorName[idx]}</StyledTableCell>
                      <StyledTableCell >{sizeName[idx]}</StyledTableCell>
                      <StyledTableCell >{detail.count}</StyledTableCell>
                      <StyledTableCell >{detail.sold_amount}</StyledTableCell>
                      <StyledTableCell >{detail.default_price}  <Translation data="store_seller.aed" /></StyledTableCell>
                      <StyledTableCell >{detail.price} <Translation data="store_seller.aed" /></StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {/* Confirmation Dialog */}
      <Dialog open={confirmOpen} onClose={handleCloseConfirm}>
        <DialogTitle>
          <Translation data="store_seller.title" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Translation data="store_seller.message" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm} color="primary">
            <Translation data="store_seller.cancel" />
          </Button>
          <Button
            onClick={() => handleProductDelete(product.id)}
            color="error"
            autoFocus>
            <Translation data="store_seller.delete" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default memo(TableRows);
