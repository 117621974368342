import { useState,useEffect } from 'react'; 
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import { Link } from 'react-router-dom';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import AddressModal from '../modal/AddressModal';
import { deleteAddressAsync, setDataAddress,setStatusAddress } from '../../services/address/addressSlice';
import { useDispatch,useSelector } from 'react-redux';
import { updateAddressAsync } from '../../services/address/addressSlice';
import Translation from '../../utils/Translation';

export default function BoxAddress() {

  const [open, setOpen] = useState(false);
  const [addressId,setAddressId]=useState(null);
  const handleClose = () =>  setOpen(false);
  const auth = useSelector(state => state.auth);
  const dynamicState = useSelector(state => state.dynamicConfig);
  const address = useSelector(state => state.address);
  const dispatch= useDispatch();
  const [msg ,setMess] = useState(null);
  const [submit,setSubmit] = useState(false);
  const handleOpen = (fav_id) => {

    setOpen(true);
    setStatusAddress({status:"idle"})
    setSubmit(false);
    setMess(null);

    setAddressId(fav_id);
    let data =address.data.filter((val) => val.fav_id === fav_id);
    dispatch(setDataAddress({data}));
  };

  const handleOnSubmitDelete = (e) => {

    e.preventDefault();
    let data = { fav_id : addressId};
    dispatch(deleteAddressAsync({auth,dynamicState,data}));
    setSubmit(true);
  }

  const handleOnSubmit =((e,object) => {

    e.preventDefault();
    let point = typeof object.coordinates === 'object' ? object.coordinates.lat + "," + object.coordinates.lng : object.coordinates
    let data = {
      coordinates: point,
      mobile: object.mobile,
      friendly_name: object.friendlyName,
      villa_bldng_no: object.buildingNo,
      street: object.street,
      area: object.area,
      location_address: "("+point+")",
      city: object.city,
      formatted_address: object.city,
      emirate : object.emarites    
    };
   
    dispatch(updateAddressAsync({auth,dynamicState,data,addressId}));
    setSubmit(true);

  });


  
  useEffect(()=>{ 

    if(submit === true){

      if(address.status === "succeeded"){
        setMess("Add Successfully");
        handleClose();
      }
      
      address.status === "failed" &&  setMess(address.errorAddress);
    }
   
  },[address.status]);

  
  return (
    <>
         <AddressModal 
            open={open} 
            handleClose={handleClose} 
            handleOnSubmitDelete={handleOnSubmitDelete} 
            addressData={address.addressData} 
            update={true}
            error={address.errorAddress}
            status={address.status}
            msg={msg}
            handleOpen={handleOpen}
            handleOnSubmit={handleOnSubmit}
            submit={submit}   
            />
           
          {address?.data?.length === 0 && 
            <Typography component="p">
                <Translation data="address.address_not_found"  />
            </Typography>
          }

          {address.data && address.data.map((address,idx)=>{
            return  <Link to='/address' id={address.fav_id}  key={idx}  
               className='text-undecoration' onClick={()=>handleOpen(address.fav_id)}>
        
            <Box >
                <Container className='box-request page_size' >
                  <Grid container spacing={3}>
                      <Grid>
                          <ContactsOutlinedIcon className='size-address'  />
                      </Grid>

                      <Grid  size={{md:9 ,sm:8 , xs:4}} className="address-name"  >
                    
                        <Typography variant="b" component="h3">
                           {address.address_data?.friendly_name??''}
                        </Typography>
                    
                      </Grid>


                      <Grid  size={{md:2 ,sm:2 , xs:5}} className='dir-rtl'>
                          <CreateOutlinedIcon   />
                      </Grid>
                  </Grid>

                  <Box component='ul' className='line-height'>
                  {address.address_data?.city && 
                    <Typography variant="li" component="li">
                     <Translation data="address.city"  />  {address.address_data?.city??''}
                    </Typography>
                  }
                  {address.address_data?.villa_bldng_no &&
                    <Typography variant="li" component="li">
                    <Translation data="address.building"  />   {address.address_data?.villa_bldng_no??''}
                    </Typography>
                  }
                  {
                    address.address_data?.street && 
                    <Typography variant="li" component="li">
                    <Translation data="address.street"  />  {address.address_data?.street??''}
                    </Typography>
                  }
                  {address.address_data?.area  && 
                    <Typography variant="li" component="li">
                    <Translation data="address.area"  />  {address.address_data?.area??''}
                    </Typography>
                  }
                  {address.address_data?.mobile && 
                    <Typography variant="li" component="li">
                    <Translation data="address.mobile_number"  />  {address.address_data?.mobile??''}
                    </Typography>
                  }
                  </Box>
                </Container>  
            </Box>
          </Link>
        })}
    </>
   
  
  )
}
