
import React, {useEffect,useState } from 'react';
import { setPath,setEstimatedDuration,setDistance } from '../../services/request/requestSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@mui/material';
import Translation from '../../utils/Translation';

const CalculateTimePath = () => {
  
  const {estimatedDuration,distance,addressPickup,addressDrop} = useSelector(state => state.request);
  const [directions, setDirections] = useState(null);
  const [done, setDone] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const fetchDistanceMatrix = async() => {

    setError("");
    setDone(false);
    dispatch(setEstimatedDuration(''));
    dispatch(setDistance(''));
    dispatch(setPath(''));

    if(window.google.maps){
      const servicePath = new window.google.maps.DirectionsService();

      await servicePath.route(
        {
          origin: addressPickup[0]?.address_data?.coordinates,
          destination: addressDrop[0]?.address_data?.coordinates,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK') {
            setDirections(response)
            const resultTime = response.routes[0].legs[0].duration.text;
            dispatch(setEstimatedDuration(resultTime));
            const resultDistance = response.routes[0].legs[0].distance.text;
            dispatch(setDistance(resultDistance))
            const resultPath = response.routes[0].overview_polyline;
            dispatch(setPath(resultPath));

            setDone(true);
          } else {
            setError(`Error fetching directions: ${status}`);
          }
        }
      );
    }

  };


  useEffect(()=>{

    fetchDistanceMatrix();

  },[addressPickup[0]?.address_data?.coordinates,addressDrop[0]?.address_data?.coordinates])


  return (
    <>
    {done && 
      <>
        <Alert severity="info" className='path-alert'>  
          <p className='map-info'>
          <Translation data="request.estimated_travel" />  {estimatedDuration}
          </p>
        </Alert>

        <Alert severity="info" className='mt-1 path-alert'>  
          <p className='map-info'>
           <Translation data="request.distance" />  {distance}
          </p>
        </Alert>
      </>
    }

    {error !== "" && 
    
      <Alert severity="error" className='mt-1'>  
          <p className='map-info'> {error}</p>
      </Alert>
    }
    </>
  );
};

export default CalculateTimePath;
