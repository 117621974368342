import React, { memo, useState } from "react";
import Grid from "@mui/material/Grid2";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useFieldArray } from "react-hook-form";
import AddCustomAttr from "./AddCustomAttr";
import CustomAttributesName from "./CustomAttributesName";
import CustomAttributesValue from "./CustomAttributesValue";

function CustomAttributes({
  idx,
  language,
  register,
  errors,
  control,
  setValue,
  getValues,
  watch,
  selectedImages,
  setSelectedImages
}) {


  const {
    fields: fieldsAttr,
    append: appendAttr,
    remove: removeAttr,
  } = useFieldArray({
    control,
    name: `attributes.${idx}.customAttributes`,
  });


  const handleRemoveCustomAttr = (index) => {

    let customAttr = getValues(`attributes.${idx}.customAttributes`);
    removeAttr(index); // Remove the selected item

    // update another element
    if(idx !== customAttr.length -1)
      for (let i = idx; i < customAttr.length - 1; i++) {
        setValue(
          `attributes.${idx}.customAttributes.${index}.name`,
          getValues(`attributes.${idx}.customAttributes.${index}.name`)
        );
        setValue(
          `attributes.${idx}.customAttributes.${index}.value`,
          getValues(`attributes.${idx}.customAttributes.${index}.value`)
        );
      }
  }

  return (
    <>
      {/* Buttons for Adding Custom Attributes and Uploading Images */}
      <Grid container spacing={2} className="icon-arabic">
        <AddCustomAttr
          idx = {idx}
          setValue={setValue}
          appendAttr={appendAttr}
          watch={watch}
          register={register}
          getValues={getValues}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
        />
      </Grid>

      {/* Render Custom Attributes */}
      {fieldsAttr.length > 0 &&
        fieldsAttr.map((field, index) => (
          <Grid container key={index} spacing={2} className="custom-attr-list">
            {/* Name Field */}
            <CustomAttributesName
              idx={idx}
              index={index}
              language={language}
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
              getValues={getValues}
            />

            {/* Value Field */}
            <CustomAttributesValue
              idx={idx}
              index={index}
              language={language}
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
              getValues={getValues}
            />

            {/* Delete Button */}
            <Grid size={{ lg: 2,md:2,sm:2 , xs:12 }} className="delete-details">
          
           
              <IconButton
                color="error"
                onClick={() => {
                  handleRemoveCustomAttr(index)
                }}
              >
                <Delete />
              </IconButton>
          
            </Grid>
          </Grid>
        ))}
    </>
  );
}

export default memo(CustomAttributes);
