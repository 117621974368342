import {PaymentElement} from '@stripe/react-stripe-js';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import {useStripe, useElements} from '@stripe/react-stripe-js';
import { useState } from 'react';
import Box from '@mui/material/Box';
import ResultSend from '../../../alert/ResultSend';
import Translation from '../../../../utils/Translation';

const FieldPayment = ({handleClose,setPaymentDone}) => {

const stripe = useStripe();
const elements = useElements();
const [error, setError] = useState(null);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
    

        if (!stripe || !elements) {
          // Stripe.js hasn't yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
    
        const result = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: window.location.href,
          },
          redirect: 'if_required' 
        });
    
    
        if (result.error) {
          // Show error to your customer (for example, payment details incomplete)
          setError(result.error.message);
          console.log(result.error.message);
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
          setError(null);
          setPaymentDone(true);
          handleClose(); // close stripe
        }
      };

  return (
    <form onSubmit={(event)=>handleSubmit(event)}>
      <PaymentElement />

      {error &&
        <Box className="pt-2">
            <ResultSend msg={error}  error={error?true:false} />
        </Box>
       }

      
      <Stack direction="row" spacing={2} className='mt-2'>
                          
        <Button variant="contained" className='font-weight-600' type='submit' disabled={!stripe}>
             <Translation  data="request.payment"  />
        </Button>
        <Button variant="outlined" className='font-weight-600 button-outlined' onClick={handleClose}>
            <Translation  data="request.cancel"  />
        </Button>

       </Stack>
    </form>
  )
};

export default FieldPayment;