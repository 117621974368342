import axios from 'axios';
import {ApiException} from '../../utils/exceptions';
import { auth } from '../../config/firebase-config';

let axiosInstance = null;

const ApiService = {

  async _axiosInstance(headersParams,options) {
  
   if(!axiosInstance){
    
      axiosInstance =  axios.create({
        // baseURL: options.dynamicState.prodApiServerBaseUrl,
        // baseURL: 'http://localhost:3005', // local
        // baseURL: 'http://192.168.1.47:3000',//live
        // baseURL: 'http://192.168.1.71:3000',//wasseem
        // baseURL: 'https://lb.trykemosaas.com', // test
        baseURL: 'https://next.deliveritapp-apis.xyz', // live
        headers: {
          'Content-Type':('contentType' in headersParams)? headersParams.contentType:''
        }
      });

      axiosInstance.interceptors.request.use(async (config) => {
        const currentUser = auth.currentUser; // Get the current user
        if (currentUser) {
          const token = await currentUser.getIdToken();
          config.headers.Authorization = ('authorization' in headersParams)? `Bearer ${token}`:'';
        }
        return config;
      });

    }

    return axiosInstance
  },
    
  reformatData(contentType,data) {
    
    if(contentType === "application/json")
      return  JSON.stringify(data)
    
      return data
   },

  async patch(path,headers,data,options) {
   
    const axiosInstance =  await this._axiosInstance(headers,options);

    if(options.reformatData)
         data = this.reformatData(headers.contentType,data);

    try {

        const response = await axiosInstance.patch(path, data);
        return response;

    } catch (error) {

        throw new ApiException({
          name:"Patch Request Error",
          message: error.response ? error.response.data.error.message : null,
          errorCode: error.response ? error.response.status : null
        });
    }

  },

  async delete(path,headers,data,options) {
  
    const axiosInstance =  await this._axiosInstance(headers,options);
    
    if(options.reformatData)
        data = this.reformatData(headers.contentType,data);

    try {
        const response = await axiosInstance.delete(path,data);
        return response;

    } catch (error) {

        throw new ApiException({
          name:"Delete Request Error",
          message: error.response ? error.response.data.error.message : null,
          errorCode: error.response ? error.response.status : null
        });
    }
    
  },

  async put(path,headers,data,options) {

    const axiosInstance =  await this._axiosInstance(headers,options);

    if(options.reformatData)
         data = this.reformatData(headers.contentType,data);

    try {
        const response = await axiosInstance.put(path, data);
        return response;

    } catch (error) {

        throw new ApiException({
          name:"Put Request Error",
          message: error.response ? error.response.data.error.message : null,
          errorCode: error.response ? error.response.status : null
        });
    }
  },

  async post(path,headers,data,options) {
  
    const axiosInstance =  await this._axiosInstance(headers,options);
    
    if(options.reformatData)
        data = this.reformatData(headers.contentType,data);

    try {

        const response = await axiosInstance.post(path, data);
        return response;

    } catch (error) {

        throw new ApiException({
          name:"Post Request Error",
          message: error.response ? error.response.data.error.message : null,
          errorCode: error.response ? error.response.status : null
        });
    }
    
  },

  async get(path,headers,data,options) {

    const axiosInstance =  await this._axiosInstance(headers,options);
    if(options.reformatData)
       data = this.reformatData(headers.contentType,data);
  
    try {
      const response = (data.cancelToken)? 
           await axiosInstance.get(path, {cancelToken: data.cancelToken} ):await axiosInstance.get(path, data);
  
      return response;

    } catch (error) {
      throw new ApiException({
        name:"Get Request Error",
        message: error.response ? error.response.data.error.message : null,
        errorCode: error.response ? error.response.status : null
      });
    }
  }


};

export default ApiService;