import * as React from 'react';
import { useEffect } from 'react';
import Login from './pages/auth/Login';
import ConfirmOtp from './pages/auth/ConfirmOtp';
import  {Routes,Route, useNavigate} from 'react-router-dom';
import './assets/scss/layout.scss';
import './assets/scss/CreateStore.scss';
import './assets/scss/StoreSeller.scss';
import './assets/scss/Order.scss';
import './assets/scss/Auth.scss';
import './assets/scss/Profile.scss';
import './assets/scss/Address.scss';
import './assets/scss/AddRequest.scss';
import './assets/scss/HomeCustomer.scss';
import './assets/scss/HomeSeller.scss';
import './assets/scss/Seller.scss';
import './assets/scss/Global.scss';
import './assets/scss/Store.scss';
import './assets/scss/Chat.scss';
import './assets/scss/Setting.scss';
import './assets/scss/Arabic.scss';
import './assets/scss/Responsive.scss';
import Base from './pages/Base'
import PageNotFound from './pages/others/PageNotFound ';
import { useDispatch, useSelector } from 'react-redux';
import {fetchDynamicConfigurations} from './services/dynamic_config/dynamic_config_service'; // Assuming you have a service to fetch dynamic configurations
import { setDynamicConfigurations } from './services/dynamic_config/dynamicConfigSlice'; // Assuming you have a slice for dynamic configurations
import AddRequest from './pages/customer/add_request/AddRequest';
import Address from './pages/address/Address';
import Setting from  './pages/setting/Setting';
import Profile from './pages/profile/Profile';
import { Box, CircularProgress } from '@mui/material';
import PageOffline from './pages/others/PageOffline';
import { setIsOnline, setScreenWidth } from './services/globalSlice';
import {setPageNotFound} from './services/globalSlice';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { checkUserIsCustomer, checkUserIsSeller, loadStyle } from './utils/helpers';
import Store from './pages/customer/store/Store';
import ProductDetail from './pages/customer/store/ProductDetails';
import Cart from './pages/customer/store/Cart'
import Checkout from './pages/customer/store/Checkout';
import { allLinks } from './utils/helpers';
import PaymentStatus from './pages/customer/store/PaymentStatus';
import StoreSeller from './pages/seller/store/StoreSeller';
import SellerInfo from './pages/seller/create_store/SellerInfo';
import CreateOrUpdateProduct from './pages/seller/product/CreateOrUpdateProduct';
import OrderSeller from './pages/seller/order/OrderSeller';
import OrderCustomer from './pages/customer/store/order/OrderCustomer';
import HomeCustomer from './pages/customer/home/CustomerHome';
import HomeSeller from './pages/seller/home/HomeSeller';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const useNetworkStatus = () => {
  const {isOnline} =  useSelector(state => state.global);
  const dispatch = useDispatch();

  const updateNetworkStatus = () => {
    dispatch(setIsOnline(navigator.onLine));
  };

  // sometimes, the load event does not trigger on some browsers, that is why manually calling updateNetworkStatus on initial mount
  useEffect(() => {
    updateNetworkStatus();
  }, []);

  useEffect(() => {
    window.addEventListener("load", updateNetworkStatus);
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    return () => {
        window.removeEventListener("load", updateNetworkStatus);
        window.removeEventListener("online", updateNetworkStatus);
        window.removeEventListener("offline", updateNetworkStatus);
    };
  }, [navigator.onLine]);

  return { isOnline };
};


export default function  App() {

  const {isLoading} = useSelector(state => state.auth);
  const {pageNotFound} =  useSelector(state => state.global);
  const {userProfile} = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const { isOnline } = useNetworkStatus();
  const [language, setLanguage] = useState(localStorage.getItem("i18nextLng"));
  const navigate = useNavigate()
  let location = useLocation(); 

  useEffect(() => {

    fetchDynamicConfigurations()
    .then(configurations => {
      dispatch(setDynamicConfigurations(configurations));
    })
    .catch(error => {
      console.error('Error fetching dynamic configurations:', error);
    });


    // check service notification is worker
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        }).catch((error) => {
          console.log('Service Worker registration failed:', error);
        });
    }

     // check find page in app
     dispatch(setPageNotFound(false)); // reset page 404
    if(!allLinks.includes(location.pathname.split('/')[1]))
      dispatch(setPageNotFound(true));

      // load style arabic or english
      loadStyle(language);

      // set Screen Size
      dispatch(setScreenWidth(window.innerWidth))

    }, [dispatch]);

    const theme = createTheme({
      typography: {
        fontFamily: "system-ui"
      },
      palette: {
        primary: {
          main: "#005d6d"
        },
        secondary: {
          main: "#0db2ad"
        }
      }
    });

  return(
    <ThemeProvider theme={theme}>
      {isOnline?
        <>
          {pageNotFound ? <PageNotFound /> : 
          <Box className="App">
            <div id='recaptcha-container' style={{padding:"1rem"}}></div>
          { isLoading === true &&  <CircularProgress className='full-preloader-position' /> }
          { isLoading === false &&
          <Routes>
            <Route path={`/${allLinks[0]}`} element={<Login />} />
            <Route path={`/${allLinks[1]}`} element={<ConfirmOtp />} />
            <Route  element={<Base />}>
          
              {checkUserIsCustomer(userProfile) && 
                <>
                  <Route path={`/${allLinks[2]}`} index  element={<HomeCustomer/>} />
                  <Route path={`/${allLinks[3]}`}  element={<AddRequest/>} />
                  <Route path={`/${allLinks[4]}`}  element={<Store/>} />
                  <Route path={`/${allLinks[5]}/:slug`}  element={<ProductDetail/>} />
                  <Route path={`/${allLinks[6]}`}  element={<Cart/>} />
                  <Route path={`/${allLinks[7]}`}  element={<Checkout/>} />
                  <Route path={`/${allLinks[12]}`}  element={<PaymentStatus/>} />
                  <Route path={`/${allLinks[15]}`}  element={<OrderCustomer/>} />
                </>
              }

              {checkUserIsSeller(userProfile) && 
                <>
                <Route path={`/${allLinks[2]}`} index  element={<HomeSeller/>} />
                <Route path={`/${allLinks[4]}`}  element={<StoreSeller/>} />
                <Route path={`/${allLinks[16]}`}  element={<CreateOrUpdateProduct/>} />
                <Route path={`/${allLinks[17]}/:id`}  element={<CreateOrUpdateProduct/>} />
                <Route path={`/${allLinks[13]}`}  element={<SellerInfo/>} />
                <Route path={`/${allLinks[14]}`}  element={<OrderSeller/>} />
                </>
              }
              
              <Route path={`/${allLinks[8]}`}  element={<Address/>} />
              <Route path={`/${allLinks[9]}`}  element={<Setting/>} />
              <Route path={`/${allLinks[10]}`}  element={<Profile/>} />
          
              <Route path="*" element={<PageNotFound/>} />
            </Route>
          </Routes>}
          </Box>
          }
        </>
        :
        <PageOffline />
      }
    </ThemeProvider>
  )
}



