import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Rating } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadProductReviews } from '../../../services/store/storeSlice';
import { Box } from "@mui/material";
import Translation from '../../../utils/Translation';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { memo } from 'react';

function Review({review,productReviews,auth,dynamicState,id}) {

  const numberReviews = 5;
  const [limit,setLimit] = useState(numberReviews)
  const dispatch = useDispatch();
  
  useEffect(()=>{
    dispatch(loadProductReviews({ auth, dynamicState, id }));
  },[dispatch]);

  return (
    <>
    <Typography component="h6" variant="h6" className="product_review_section">
        <Translation data="store.product_reviews_title" />
    </Typography>
    <Box>
        <Typography component="p" variant='b'> 
            <Rating  name="size-medium" className='starts_ar' value={review} precision={0.1} readOnly/> 
            <span className='out_of_5'> {review} <Translation data="store.out_of_5"  /> </span>
        </Typography>
        <Typography component="p" variant='h6'>
             {productReviews.length}  <Translation data="store.global_ratings"  />
        </Typography>
    </Box>

    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {productReviews.length > 0 && 
          productReviews.slice(0, limit).map((val , idx ) => {
            return(  
                    <>
                      <ListItem alignItems="flex-start" key={idx}>
                        <ListItemAvatar>
                          <Avatar alt="Remy Sharp" src={val?.profile_image_url} />      
                        </ListItemAvatar>
                        <ListItemText
                          primary={val?.nickname} 
                          className='customer_review_name'
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="p"
                                sx={{ color: 'text.primary', display: 'block' }}>
                                <Rating  name="size-medium" className='starts_ar' value={val?.number_stars} readOnly/>
                              </Typography>

                              {val?.comment && val?.status ===1  && 
                                <Typography
                                  component="span"
                                  variant="body2"
                                  sx={{ color: 'text.primary', display: 'block' }}>
                                    {val?.comment}
                                </Typography>
                              }
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" key={idx} component="li" />
                    </>
              )})
        }
    </List>
    {limit < productReviews.length &&
      <Stack spacing={2} direction="row">
        <Button variant="outlined" className='show_more_review' onClick={() =>setLimit(limit + numberReviews)}>  <Translation data="store.show_more"  /> </Button>
      </Stack>
    }
    </>
  );
}

export default memo(Review);
