import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { memo } from 'react';
import Translation from '../../utils/Translation';
import { useCallback } from 'react';
import { getValByLang, removeHtmlCharacter } from '../../utils/helpers';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function OrderView({detail,language}) {

  const {custom_attributes} = useSelector(state => state.store);
  const [open, setOpen] = useState(false);
  const [colorName,setColorName] = useState('');
  const [sizeName,setSizeName] = useState('');
  const [attr,setAttr] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleView = useCallback((detail) => {
      
    setColorName(detail.color? getValByLang(detail.color.name,language):null);
    setSizeName(detail.size? getValByLang(detail.size.name,language):null);

    let attrKey = [];

    if(detail.attributes)
      for(var i =0 ; i < detail.attributes.length  ; i++) {

        attrKey[i] = custom_attributes.filter((val) => val.id === detail.attributes[i].attribute_key)[0];

        if(attrKey[i])
          attrKey[i] = {...attrKey[i] , value : detail.attributes[i].attribute_value }

      }

    setAttr(attrKey);
    setOpen(true);
  });


  
  return (
      <React.Fragment>
        <Button variant="outlined" onClick={() => handleView(detail)}>
          <Translation data="order_seller.view" />
        </Button>

        <BootstrapDialog  className='order-view'  onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            <Translation data="order_seller.details" />
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            {detail ? (
              <>
                {detail.product_image && (
                  <img src={detail.product_image} alt="Product" className='product-img-view' style={{ borderRadius: 8, marginTop: 10 }} />
                )}
                <Typography className='mt-1' gutterBottom>
                  <strong><Translation data="order_seller.product_name" />:</strong>{' '}
                  {getValByLang(detail.product_name, language)}
                </Typography>
                <Typography gutterBottom>
                  <strong><Translation data="order_seller.product_description" />:</strong>{' '}
                  { removeHtmlCharacter(getValByLang(detail.product_description, language))}
                </Typography>
                <Typography gutterBottom>
                  <strong><Translation data="order_seller.price" />:</strong> {detail.price}
                </Typography>
                <Typography gutterBottom>
                  <strong><Translation data="order_seller.quantity" />:</strong> {detail.quantity}
                </Typography>
                <Typography gutterBottom>
                  <strong><Translation data="order_seller.item_status" />:</strong> <Translation data={`status_${detail.item_status}`} />
                </Typography>
                <Typography gutterBottom>
                  <strong><Translation data="order_seller.reference" />:</strong> {detail.reference}
                </Typography>
                {colorName && (
                  <Typography gutterBottom>
                      <strong><Translation data="store_seller.color" />:</strong> {colorName}
                  </Typography>
                )}
                {sizeName && (
                  <Typography gutterBottom>
                      <strong><Translation data="store_seller.size" />:</strong> {sizeName}
                  </Typography>
                )}
              </>
            ) : (
              <Typography gutterBottom>
                <Translation data="order_seller.no_details_available" />
              </Typography>
            )}

            {attr && attr.length > 0  && 
              <Attributes rows= {attr} language ={language} />
            } 

          </DialogContent>
          <DialogActions>
            <Button variant="contained"  autoFocus onClick={handleClose}>
              <Translation data="order_seller.close" />
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    );
}


 function Attributes({rows,language}) {


  return (
    <TableContainer className='mt-1' component={Paper}>
       <Typography gutterBottom>
           <strong><Translation data="store_seller.attributes" />:</strong>
       </Typography>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">  <Translation data="store_seller.name" /></TableCell>
            <TableCell  align="center"><Translation data="store_seller.value" /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows.length > 0 && rows.map((row,idx) => (
            row && (
              <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align="center">{  getValByLang( row.name , language)} </TableCell>
                <TableCell align="center">{getValByLang(row.values.filter((val) => val.value_id ===  row.value)[0]?.value , language)} </TableCell>
             </TableRow> 
            )

          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


export default memo(OrderView);
