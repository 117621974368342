import * as React from 'react';
import Box from '@mui/material/Box';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import Grid from '@mui/material/Grid2';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Translation from '../../utils/Translation';
import { allLinks } from '../../utils/helpers';
import { setLimit, setStatusOrder } from '../../services/order/orderSlice';
import { useDispatch } from 'react-redux';

export default function BoxOrder(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOrderToShow = (event,val) => {
    event.preventDefault(); // Prevent default navigation behavior

    if(props?.customer){
      dispatch(setStatusOrder(val));
      dispatch(setLimit(20));
      navigate(`/${allLinks[15]}`);
    }else{
      dispatch(setStatusOrder(val));
      dispatch(setLimit(100));
      navigate(`/${allLinks[14]}`);
    }
 
  }

  return (
      <Link component="button" onClick = {(event) => handleOrderToShow(event,props.status)} className='text-undecoration'  >
          <Container className='box-request page_size'>
            <Grid container spacing={3}>
                <Grid>
                    {props.icon}
                </Grid>

                <Grid  size={{lg:10 ,sm:8 , xs:7}}  className='update-request'>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="b" component="h3">

                        {props?.customer  ?
                          <Translation data={`home.${props.keyVal}`} />    
                          :<Translation data={`home_seller.${props.keyVal}`} />    
                        }
                            
                        </Typography>
                        <Typography variant="b" component="h3">
                            {props.values.length}
                        </Typography>
                    </Box>
                </Grid>

                <Grid  size={{lg:1 ,sm:3 , xs:3}} className='dir-rtl'>
                    <ArrowCircleRightOutlinedIcon   />
                </Grid>
            </Grid>
          </Container>  
      </Link>
  );

}