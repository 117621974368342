import * as React from 'react';
import { Pagination } from "@mui/material";
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import { setOffset, setPage } from '../../../services/store/storeSlice';
import { memo } from 'react';

function PaginationRounded( {limit,productCount,page}) {

  const dispatch = useDispatch();

  const handleChange =  (event, value) => {
      dispatch(setOffset((limit * value) - limit)); // calculate offset
      dispatch(setPage(value));
  };

  return (
    <Stack spacing={2} className='product-pagination'>
      <Pagination  onChange={handleChange}  count={ limit < productCount ?  Math.ceil(productCount / limit)   : 1}  page={page}  variant="outlined" shape="rounded" />
    </Stack>
  );
}


export default  memo(PaginationRounded)