/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Link from '@mui/material/Link';
import Translation from '../../../utils/Translation'

export default function ReadMore({limit,counter,setLimit}) {
  return (
    <Link
      component="button"
      variant="body2"
      onClick={() => {
        setLimit(limit+counter)
      }}
      className='show-more'
    >
     <Translation data="store.show_more" />
    </Link>
  );
}
