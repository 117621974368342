import React, { useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import MainTitle from '../../../../components/headline/MainTitle';
import { Button, CircularProgress, TablePagination } from '@mui/material';
import Translation from '../../../../utils/Translation';
import TableRows from './TableRows';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid2';
import Container from '@mui/material/Container';
import { GetUserOrdersAsync, reloadOrder, setOffset, setPage } from '../../../../services/order/orderSlice';
import OrderStatus from '../../../../components/order/OrderStatus';
import OrderLimit from '../../../../components/order/OrderLimit';
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { useNavigate } from 'react-router-dom';
import { allLinks } from '../../../../utils/helpers';
import RefreshIcon from '@mui/icons-material/Refresh';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#005d6d",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


 function OrderCustomer() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const dynamicState = useSelector((state) => state.dynamicConfig);
  const {language} = useSelector((state) => state.global);
  const {limit,offset,isLoading,orders,statusOrder,page,invoked} = useSelector((state) => state.order);

  const handleChangePage =  (event, value) => {

      if(value > 0){
        dispatch(setOffset((limit * value) - limit)); // calculate offset
        dispatch(setPage(value));
      }
  
  };

  const GetOrderStore = useCallback(() => {
    
    let attr = {
      limit: limit,
      offset: offset,
      status:statusOrder
    };

    dispatch(GetUserOrdersAsync({ auth, dynamicState, attr }));
  });


  useEffect(() => {

    // check product is empty and reset loading
    if (!invoked) 
      GetOrderStore();
  
  },[limit,offset,page,statusOrder,invoked,dispatch]);

  
  return (
    <Box className="mt-6-percent store_page main-store">

      <Container className='no-padding  page_size'>

          <Box sx={{display:"inline-flex",mb:2}}>
            <Button  onClick={() => navigate(`/${allLinks[4]}`)}> 
                  <ArrowCircleLeftOutlinedIcon className="back-to-home" />
            </Button>
            <MainTitle  title='order_seller.order_page'  />
          </Box>

          <Grid   container>

              <Grid  size={{lg:2,md:4,sm:5,xs:12}} >
                <OrderStatus statusOrder={statusOrder} />    
              </Grid> 
              
              <Grid  size={{lg:1,md:1,sm:1}} className="align-content-center">
                <Button variant="contained" onClick={()=> dispatch(reloadOrder())}>
                 <Translation  data="order_seller.refresh"  />
                </Button>
              </Grid>  

              <Grid  size={{lg:8,md:3,sm:1}} >
              
              </Grid>  

              <Grid  size={{lg:1,md:4,sm:5,xs:12}} className="align-content-center">
                <OrderLimit limit={limit} />    
              </Grid>  

          </Grid>

      </Container>

      {isLoading ? 
        <CircularProgress className='full-preloader-position' /> 
        :<Box className="order-table">      
              <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell />
                        <StyledTableCell><Translation data="order_customer.order_status" />  </StyledTableCell>
                        <StyledTableCell><Translation data="order_customer.order_total" />  </StyledTableCell>
                        <StyledTableCell><Translation data="order_customer.delivery_value" />  </StyledTableCell>
                        <StyledTableCell><Translation data="order_customer.discount_amount" />  </StyledTableCell>
                        <StyledTableCell><Translation data="order_customer.vat_amount" />  </StyledTableCell>
                        <StyledTableCell><Translation data="order_customer.total_amount" />  </StyledTableCell>
                        <StyledTableCell><Translation data="order_customer.total_invoice_value" />  </StyledTableCell>
                        <StyledTableCell ><Translation data="order_seller.completed_at" /> </StyledTableCell>
                        <StyledTableCell ><Translation data="order_seller.proof_image" /> </StyledTableCell>
                        <StyledTableCell ><Translation data="order_seller.canceled_by" /> </StyledTableCell>
                        <StyledTableCell ><Translation data="order_seller.cancelation_comment" /> </StyledTableCell>
                        <StyledTableCell ><Translation data="order_seller.created_at" /> </StyledTableCell>
                        <StyledTableCell ><Translation data="order_seller.updated_at" /> </StyledTableCell>
                        <StyledTableCell ><Translation data="order_seller.action" /> </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {orders.length > 0 && orders.map((row,idx) => (
                        <TableRows key={idx} order={row} language={language} auth={auth} dynamicState = {dynamicState}  /> 
                      ))}
                    </TableBody>
                  </Table>

                  <TablePagination
                    page={page}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={({ page }) => `${Translation({ data: "order_seller.rows_per_page" })} ${page}`}
                    count={-1}
                    rowsPerPage={10}
                  />

              </TableContainer>
        </Box>
      }
  
    </Box>
  );
}

export default OrderCustomer;