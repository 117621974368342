import React, { memo, useMemo } from 'react'
import Grid from '@mui/material/Grid2';
import Container from '@mui/material/Container';
import ProductCard from '../../../../components/store/ProductCard';
import Translation from '../../../../utils/Translation';
import { Typography } from '@mui/material';
import { getValByLang } from '../../../../utils/helpers';

 function ProductList({title,products,list_of_catg,language}) {

  return (
    <Container className='no-padding page_size products'>
       
      <Typography variant="h5" component="h5" className="font-weight-600 mb-1">
        <Translation data={title}   /> 
       </Typography>

      <Grid  size={{lg:12,md:12,sm:12,xs:12}}  container> 
      {list_of_catg.length > 0 &&
          products.map((val,idx)=> {       
          return (
            <React.Fragment key={idx}> {/* Use React.Fragment with a key */}
              {(idx === 0 || products[idx].categories[0] !== products[idx-1].categories[0]) && 
                <Grid size={{lg:12,md:12,sm:12,xs:12}}> {/* Forces a new row */}
                  <Typography variant="h6" component="p" className="category-title-store-page">
                        {getValByLang( list_of_catg.filter((ctg) => ctg.id ===val.categories[0])[0].name,language) }:
                  </Typography>
                </Grid>
              }
              <Grid key={idx}  size={{lg:3,md:4,sm:6,xs:12}} >
                <ProductCard product={val} />
              </Grid>
            </React.Fragment>
            )
          })
      }
      </Grid>
      
   </Container>
  )
}

export default memo(ProductList)
