import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Translation from '../../utils/Translation';
import { reviewProductAsync } from '../../services/store/storeSlice';
import { Box, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

export default function ProductReview({detail}) {

  const  dispatch  =useDispatch();
  const auth = useSelector(state => state.auth);
  const dynamicState = useSelector(state => state.dynamicConfig);
  const [open, setOpen] = React.useState(false);
  const [star , setStar] = React.useState(1);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handelStar = (val) => setStar(val)


  return (
    <React.Fragment>
      <Button className="product-review" variant="contained" onClick={handleClickOpen}>
        <Translation data="order_customer.product_review" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          sx: { minWidth: '400px' }, // Set the minWidth here
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const commentVal = formJson.comment;
            const data = {
                "user_id": auth?.user?.uid,
                "product_id": detail?.product_id,
                "number_stars": star,
                "comment": commentVal,
                "status":false
            }

            dispatch(reviewProductAsync({auth, dynamicState,data}))
            handleClose();
          },
        }}
      >
        <DialogTitle> <Translation data="order_customer.review" /></DialogTitle>
        <DialogContent>

         <Box className='product-review-stars'>
              <Typography  variant="p">
                <Button size='medium' onClick={() =>handelStar(1)}>
                    <StarIcon  />
                </Button>

                <Button size='medium'  onClick={() =>handelStar(2)}>
                     {star >= 2 ? <StarIcon  />:  <StarBorderIcon  />}
                </Button>

                <Button size='medium' onClick={() =>handelStar(3)}>
                    {star >= 3 ? <StarIcon  />:  <StarBorderIcon  />}
                </Button>

                <Button size='medium'  onClick={() =>handelStar(4)}>
                    {star >= 4 ? <StarIcon  />:  <StarBorderIcon  />}
                </Button>

                <Button size='medium' onClick={() =>handelStar(5)}>
                    {star >= 5 ? <StarIcon  />:  <StarBorderIcon  />}
                </Button>
              </Typography>
         </Box>
    

          <TextField
            autoFocus
            name="comment"
            label={<Translation data="order_customer.comment" />}
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> <Translation data="order_customer.close" /></Button>
          <Button type="submit"><Translation data="order_customer.send" /></Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
