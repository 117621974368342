
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import * as React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { setDeliveryDate,setDeliveryTime } from '../../../services/request/requestSlice';
import { useEffect } from 'react';

import DeliveryTimeBox from '../../../components/box/DeliveryTimeBox';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import SpeedIcon from '@mui/icons-material/Speed';
import { useState } from 'react';
import Translation from '../../../utils/Translation';
import { formatDate } from '../../../utils/helpers';

export default function DeliveryTime() {

  const dispatch = useDispatch();
  const options=[
  {
  "title": "6AM-12PM",
  "description":"",
  "value" : 0,
  "icon": <AccessTimeFilledIcon className='size-address' />
  },
  {
  "title": "12AM-6PM",
  "description":"",
  "value" : 1,
  "icon": <AccessTimeIcon className='size-address' />
  },
  {
  "title":  "6PM-12AM",
  "description":"",
  "value" : 2,
  "icon": <AccessTimeFilledIcon className='size-address' />
  },
  {
  "title": <Translation  data="request.urgent"  />,
  "description":<Translation  data="request.urgent_desc"  />,
  "value" : 4,
  "icon": <SpeedIcon className='size-address' />
  }
];
 const {deliveryDate,deliveryTime} = useSelector(state => state.request)
 const [deliveryTimesOption,setDeliveryTimeOption ] = useState (options); 

const removeOption = (id) => {
  const updatedItems = deliveryTimesOption.filter(item => item.value !== id);
  setDeliveryTimeOption(updatedItems);
};

const resetOption = ()=>{

  return options;
}


 useEffect(() => {

  setDeliveryTimeOption(resetOption());
  
  const today = formatDate(new Date(),0);

  //remove urgent
  if(today !== deliveryDate)
    removeOption(4);


 },[deliveryDate])

 
  return (
    <Box
     sx={{
      width: 500,
      maxWidth: '100%',
      paddingBottom:'3%',
      paddingLeft:'2%'
    }}
    >
      <Typography variant="h6" component="h6" className='font-weight-600 mb-1 mt-2'>
          <Translation data="request.delivery_date"   />
      </Typography>
      
      <TextField fullWidth  type='date' value={deliveryDate} 
        onChange={(e) => dispatch(setDeliveryDate(e.target.value))} 
        inputProps={{
          min: formatDate(new Date(),0),
          max: formatDate(new Date(),4)
        }}
       />

      {deliveryTimesOption.length > 0 && 
        <>
         <Typography variant="h6" component="h6" className='font-weight-600 mb-1 mt-2'>
             <Translation data="request.delivery_time"   />
          </Typography>

        <Box className='all-payment'>
        
        <DeliveryTimeBox value={deliveryTime} action={setDeliveryTime} deliveryTimesOption={deliveryTimesOption} />

        </Box>
        </>
        }
  
  </Box>
  )
}
