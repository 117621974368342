import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Button,
  TextField,
  InputLabel,
  InputAdornment,
  Alert,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Translation from "../../../utils/Translation";
import { Controller } from "react-hook-form";
import ColorInput from "./ColorInput";
import SizeInput from "./SizeInput";
import InventoryInput from "./InventoryInput";
import { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

function ProductVariant({
  idx,
  inventories,
  language,
  register,
  colors,
  sizes,
  errors,
  showDimension,
  setShowDimension,
  control,
  setValue,
  getValues,
  watch,
}) {
  const { deliveryOptions } = useSelector((state) => state.store);
  const [profit, setProfit] = useState(0);

  const updatePriceValues = useCallback(() => {
    const regularPrice =
      watch(`attributes.${idx}.regular_price`) > 0
        ? watch(`attributes.${idx}.regular_price`)
        : 0;
    const salePrice =
      watch(`attributes.${idx}.sale_price`) > 0
        ? watch(`attributes.${idx}.sale_price`)
        : 0;

    regularPrice === 0 &&
      setValue(`attributes.${idx}.regular_price`, Number(regularPrice));

    salePrice === 0 &&
      setValue(`attributes.${idx}.sale_price`, Number(salePrice));

    let deliveryVal = deliveryOptions?.deliverit_cut_rules;
    let price = regularPrice;

    if (Number(salePrice) > Number(regularPrice))
      setValue(`attributes.${idx}.sale_price`, regularPrice);

    if (
      getValues(`attributes.${idx}.regular_price`) &&
      getValues(`attributes.${idx}.sale_price`) &&
      getValues(`attributes.${idx}.sale_price`) <
        getValues(`attributes.${idx}.regular_price`)
    ) {
      price = getValues(`attributes.${idx}.sale_price`);
    }

    const categories_update = getValues("categories_update");
    const categories = getValues("categories");
    const cut_percentage = categories.length > 0
                              ? categories[categories.length - 1]?.cut_percentage
                              : categories_update[categories_update.length - 1]?.cut_percentage;

    if(cut_percentage)
      setProfit(parseFloat((cut_percentage * price) / 100));
    else if (deliveryVal)
      for (let i = 0; i < deliveryVal.length; i++)
        if (deliveryVal[i].max >= price) {
          setProfit(parseFloat((deliveryVal[i].percentage * price) / 100));
          break;
        }

  }, [
    watch(`attributes.${idx}.regular_price`),
    watch(`attributes.${idx}.sale_price`),
  ]);

  useEffect(() => {
    updatePriceValues();
  }, [updatePriceValues]);

  return (
    <Grid container key={idx} spacing={2} className="form-details-inputs">
      {/* Inventory Field */}
      <Grid size={{ lg: 2 , md:6 ,sm:12 }}>
        <InventoryInput
          idx={idx}
          inventories={inventories}
          language={language}
          setValue={setValue}
          control={control}
          getValues={getValues}
        />
      </Grid>

      {/* Color Field */}
      <Grid size={{ lg: 2 , md:6,sm:12 }}>
        <ColorInput
          idx={idx}
          colors={colors}
          language={language}
          register={register}
          setValue={setValue}
          control={control}
        />
      </Grid>

      {/* Size Field */}
      <Grid size={{ lg: 2 , md:6,sm:12}}>
        <SizeInput
          idx={idx}
          sizes={sizes}
          language={language}
          register={register}
          setValue={setValue}
          control={control}
        />
      </Grid>

      {/* Quantity Field */}
      <Grid size={{ lg: 2 , md:6,sm:12 }}>
        <TextField
          label={<Translation data="store_seller.quantity" />}
          variant="outlined"
          type="number"
          fullWidth
          {...register(`attributes.${idx}.quantity`, {
            required: "Quantity is required",
          })}
          inputProps={{ min: 1 }}
          error={!!errors.attributes?.[idx]?.quantity}
          helperText={errors.attributes?.[idx]?.quantity?.message}
        />
      </Grid>

      {/* Regular Price Field */}
      <Grid size={{ lg: 2 , md:6,sm:12 }} className="product-form-price">
        <TextField
          label={<Translation data="store_seller.regular_price" />}
          type="number"
          variant="outlined"
          fullWidth
          {...register(`attributes.${idx}.regular_price`, {
            required: "Regular price is required",
          })}
          error={!!errors.attributes?.[idx]?.regular_price}
          helperText={errors.attributes?.[idx]?.regular_price?.message}
          inputProps={{ min: 0 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Translation data="store_seller.aed" />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      {/* Sale Price Field */}
      <Grid size={{ lg: 2 , md:6,sm:12 }} className="product-form-price">
        <TextField
          label={<Translation data="store_seller.sale_price" />}
          type="number"
          variant="outlined"
          fullWidth
          {...register(`attributes.${idx}.sale_price`)}
          inputProps={{ min: 0 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Translation data="store_seller.aed" />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      {/*  details in product Dimension */}
      {!showDimension && (
        <Button
          variant="contained"
          className="add-dimension icon-arabic "
          onClick={() => setShowDimension(!showDimension)}
        >
          <Translation data="store_seller.add_dimension" />
        </Button>
      )}

      {showDimension && (
        <Grid container spacing={2} key={idx}>
          {/* Length */}
          <Grid container spacing={0} size={{  lg: 3,md:6,sm:12  }}>
            <Grid className="w-60">
              <TextField
                label={<Translation data="store_seller.length" />}
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
                fullWidth
                {...register(`attributes.${idx}.dimensions.length`)}
              />
            </Grid>
            <Grid className="w-40">
              <FormControl
                fullWidth
                variant="outlined"
                className="select-details"
              >
                <InputLabel id={`length-unit-label-${idx}`}>
                  <Translation data="store_seller.unit" />
                </InputLabel>
                <Controller
                  name={`attributes.${idx}.dimensions.lengthUnit`}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={<Translation data="store_seller.unit" />}
                    >
                      <MenuItem value="mm">mm</MenuItem>
                      <MenuItem value="cm">cm</MenuItem>
                      <MenuItem value="m">m</MenuItem>
                      <MenuItem value="in">in</MenuItem>
                      <MenuItem value="ft">ft</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Weight */}
          <Grid container spacing={0} size={{  lg: 3,md:6,sm:12  }}>
            <Grid className="w-60">
              <TextField
                label={<Translation data="store_seller.weight" />}
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
                fullWidth
                {...register(`attributes.${idx}.dimensions.weight`)}
              />
            </Grid>
            <Grid className="w-40">
              <FormControl
                fullWidth
                variant="outlined"
                className="select-details"
              >
                <InputLabel id={`weight-unit-label-${idx}`}>
                  <Translation data="store_seller.unit" />
                </InputLabel>
                <Controller
                  name={`attributes.${idx}.dimensions.weightUnit`}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={<Translation data="store_seller.unit" />}
                    >
                      <MenuItem value="g">g</MenuItem>
                      <MenuItem value="kg">kg</MenuItem>
                      <MenuItem value="lb">lb</MenuItem>
                      <MenuItem value="oz">oz</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Width */}
          <Grid container spacing={0} size={{  lg: 3,md:6,sm:12  }}>
            <Grid className="w-60">
              <TextField
                label={<Translation data="store_seller.width" />}
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
                fullWidth
                {...register(`attributes.${idx}.dimensions.width`)}
              />
            </Grid>
            <Grid className="w-40">
              <FormControl
                fullWidth
                variant="outlined"
                className="select-details"
              >
                <InputLabel id={`width-unit-label-${idx}`}>
                  <Translation data="store_seller.unit" />
                </InputLabel>
                <Controller
                  name={`attributes.${idx}.dimensions.widthUnit`}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={<Translation data="store_seller.unit" />}
                    >
                      <MenuItem value="mm">mm</MenuItem>
                      <MenuItem value="cm">cm</MenuItem>
                      <MenuItem value="m">m</MenuItem>
                      <MenuItem value="in">in</MenuItem>
                      <MenuItem value="ft">ft</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Height */}
          <Grid container spacing={0} size={{  lg: 3,md:6,sm:12  }}>
            <Grid className="w-60">
              <TextField
                label={<Translation data="store_seller.height" />}
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
                fullWidth
                {...register(`attributes.${idx}.dimensions.height`)}
              />
            </Grid>
            <Grid className="w-40">
              <FormControl
                fullWidth
                variant="outlined"
                className="select-details"
              >
                <InputLabel id={`height-unit-label-${idx}`}>
                  <Translation data="store_seller.unit" />
                </InputLabel>
                <Controller
                  name={`attributes.${idx}.dimensions.heightUnit`}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={<Translation data="store_seller.unit" />}
                    >
                      <MenuItem value="mm">mm</MenuItem>
                      <MenuItem value="cm">cm</MenuItem>
                      <MenuItem value="m">m</MenuItem>
                      <MenuItem value="in">in</MenuItem>
                      <MenuItem value="ft">ft</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      )}

      {profit !== 0 && (
        <Box>
          <Alert severity="info">
            {" "}
            <Translation data="store_seller.delivery_fees_1" /> {profit.toFixed(2)}{" "}
            <Translation data="store_seller.delivery_fees_2" />
          </Alert>
        </Box>
      )}
    </Grid>
  );
}

export default memo(ProductVariant);
