import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { keyLang } from '../../../utils/helpers';

export default function Specification({ obj, language }) {
  const specification = obj ? JSON.parse( obj) : null;
  const specificationArray = specification ? ( Object.entries(language === keyLang[0] ? specification.en : specification.ar) ) : []

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          {specificationArray.map(([key,value]) => {

            return (
              <TableRow
                key={key}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className="specification_key">{key}</TableCell>
                <TableCell className="specification_value">{value}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
