import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { setSortBy } from '../../../services/store/storeSlice';
import { useDispatch } from 'react-redux';
import Translation from '../../../utils/Translation'
import { memo } from 'react';

 function ProductSort({sortBy}) {
  
  const dispatch = useDispatch()
  const handleChange = (event) =>  dispatch(setSortBy(event.target.value)); // reset limit
 
  return (
    <Box>
      <FormControl className='limit' fullWidth>
        <InputLabel id="demo-simple-select-label"><Translation data="store.sort" /></InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="sort_product_input"
          value={sortBy}
          label="Sort"
          onChange={handleChange}
        >
          <MenuItem  className='sort_product' value={0}><Translation data="store.select_option" /></MenuItem>
          <MenuItem className='sort_product' value={4}><Translation data="store.best_rated" /></MenuItem>
          <MenuItem  className='sort_product' value={1}><Translation data="store.new_arrivals" /></MenuItem>
          <MenuItem className='sort_product' value={2}><Translation data="store.price_low_to_high" /></MenuItem>
          <MenuItem className='sort_product' value={3}><Translation data="store.price_heigh_to_low" /></MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default memo(ProductSort);