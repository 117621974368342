import * as React from 'react';
import Button from '@mui/material/Button';
import { Add } from '@mui/icons-material';
import Translation from '../../../utils/Translation';
import { memo } from 'react';

 function AddAttribute( {handleAddNewAttr}) {
  
  return (
    <Button className='mt-0-5' variant="outlined" onClick={handleAddNewAttr} endIcon={<Add />}>
       <Translation data="store_seller.add"   />
    </Button>
  );
}

export default memo(AddAttribute)


