export const allLinks = [
  "login",
  "confirm-otp",
  "",
  "add_request",
  "store",
  "product",
  "cart",
  "checkout",
  "address",
  "setting",
  "profile",
  "logout",
  "payment-result",
  "create_seller",
  "order_seller",
  "order_customer",
  "create_product",
  "update_product"
];
export const emarites = [
  { name: "abudhabi", id: 0 },
  { name: "dubai", id: 1 },
  { name: "sharjah", id: 2 },
  { name: "ajman", id: 3 },
  { name: "ummalquawain", id: 4 },
  { name: "rak", id: 5 },
  { name: "fujairah", id: 6 },
];

export const differentDate = (date) => {
  let arr = [];
  let diffrentDate = new Date() - new Date(date);
  arr["days"] = Math.floor(diffrentDate / (1000 * 60 * 60 * 24));
  arr["hours"] = Math.floor(diffrentDate / (1000 * 60 * 60));
  arr["minutes"] = Math.floor(diffrentDate / (1000 * 60));
  arr["seconds"] = Math.floor(diffrentDate / 1000);

  return arr;
};

export const getStripPk = () => {
  return "pk_live_51KgANnKpR5JpJdcY1GM1aCsQpFstSt6XWxUmpHg1B44TDTHO3vIU1fUlq25DAxHifBAkGLoClB3gTq9W1L5Y4wel00TcH8YlXB";
};

export const getTestStripPk = () => {
  return "pk_test_51KgANnKpR5JpJdcYDlX5SLKV02eWDAhiwYqHS7IPdzClwOzpsjfHlcVUqhfm5gbe88BWQEbCR8GqJ9coaXceYKYa00TlA3KvVJ";
};

export const getLanguage = () => {
  return document.getElementsByTagName("html")[0].lang;
};

export const googleMapsApiKey = () => {
  return "AIzaSyA5qj88Gbv3jCtM6V5NoPDHwo13Xu95OS4";
};

export const keyLang = ["en-US", "ar"];
export const loadArabicStyle = (href) => {
  document.getElementsByTagName("html")[0].lang = keyLang[1];
  document.getElementsByTagName("html")[0].classList.add("arabic");
};

export const removeArabicStyle = () => {
  document.getElementsByTagName("html")[0].lang = keyLang[0];
  document.getElementsByTagName("html")[0].classList.remove("arabic");
};

export const loadStyle = (lang) => {


  if (lang === keyLang[1])
    // set file arabic
    loadArabicStyle();
  else removeArabicStyle();
};

export const formatDate = (date, plusDay) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  let day = String(parseInt(String(date.getDate()).padStart(2, "0")) + plusDay);

  if (day.length === 1)
    // for make day as 02-  03
    day = "0" + day;

  return `${year}-${month}-${day}`;
};

export const getNotifyId = () => {
  const VITE_APP_VAPID_KEY =
    "BPwSFf-y3r5rhMvVI3aoVYD4g5FOcmCgxxoe3y22O4oFuyUt110S2VllAZAuNmUNEVKwjDpMmymaoVhnGK2bxN4";

  return VITE_APP_VAPID_KEY;
};

export const NoIndexPage = () => {
  // change meta title
  if (localStorage.getItem("i18nextLng") === "ar") {
    document.getElementById("project_name").innerHTML = "ديليفرإت";
  } else {
    document.getElementById("project_name").innerHTML = "Deliverit";
  }

  const metaTag = document.createElement("meta");
  metaTag.name = "robots";
  metaTag.content = "noindex, nofollow";
  document.head.appendChild(metaTag);

  // Cleanup: Remove the meta tag when the component unmounts
  return () => {
    document.head.removeChild(metaTag);
  };
};

export const IndexPage = () => {
  // change meta title
  document.getElementById("project_name").innerHTML =
    "DeliverIt Web Application Login";

  // remove meta nofollow
  let metaTags = document.querySelectorAll('meta[name="robots"]');
  for (var i = 0; i < metaTags.length; i++)
    document.head.removeChild(metaTags[i]);
};

export const isJson = (value) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return false; // It's not a valid JSON string
  }
};

export const getValByLang = (val, language) => {
  
  const stringVal = val;

  if (val !== undefined && val !== null && val !== "") {
    let lang = language === keyLang[0] ? "en" : "ar";
    val = isJson(val);

    if (val && val.hasOwnProperty(lang)) return val[lang];
  }
  
  return stringVal; // return same value
};

export const getValBySearch = (val, search_term, language) => {
  if (val !== undefined && val !== null && val !== "") {
    let lang = language === keyLang[0] ? "en" : "ar";
    let langOpposite = language === keyLang[0] ? "ar" : "en";
    search_term = search_term.trim();

    val = isJson(val);

    if (
      val[lang] &&
      val[lang].toLowerCase().includes(search_term.toLowerCase())
    )
      return val[lang];

    if (
      val[langOpposite] &&
      val[langOpposite].toLowerCase().includes(search_term.toLowerCase())
    )
      return val[langOpposite];
  }

  return "";
};

export const checkValidString = (search_term) => {
  if (
    search_term &&
    search_term !== "" &&
    search_term !== undefined &&
    search_term !== null &&
    search_term.trim() !== ""
  )
    return true;

  return false;
};

export const checkStorePage = () =>
  window.location.href.includes(allLinks[4]) ||
  window.location.href.includes(allLinks[5]) ||
  window.location.href.includes(allLinks[6]) ||
  window.location.href.includes(allLinks[7]) ||
  window.location.href.includes(allLinks[12]) ||
  window.location.href.includes(allLinks[15]);


export const checkRouteRelatedToStore = (route) =>
    route.includes(allLinks[4]) || 
    route.includes(allLinks[5]) || 
    route.includes(allLinks[6]) || 
    route.includes(allLinks[7]) || 
    route.includes(allLinks[12]) || 
    route.includes(allLinks[15]) || 
    route.includes(allLinks[16]) || 
    route.includes(allLinks[17]);

export const checkRouteRelatedToStoreForTapOnly = (route) =>
      route.includes(allLinks[4]) || 
      route.includes(allLinks[5]) || 
      route.includes(allLinks[6]) || 
      route.includes(allLinks[7]) || 
      route.includes(allLinks[12]) || 
      route.includes(allLinks[16]) || 
      route.includes(allLinks[17]);


export const checkEqualArray = (arr1, arr2) => {
  // Check if the lengths are the same
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Check if all elements are equal
  return arr1.every((value, index) => value === arr2[index]);
};

export const mergeWithoutDuplicates = (arr1, arr2) => {
  return [...new Set([...arr1, ...arr2])];
};

export const haveCommonElements = (arr1, arr2) => {
  return arr1.some((item) => arr2.includes(item));
};

export const getPrice = (details) => {
  return details.price < details.default_price ? details.price : details.default_price;
};

export const checkAddToCart = (Details, cardItems) => {
  let quantity = 0;
  let productQty = cardItems.filter((val) => val.id === Details.id);

  if (productQty.length > 0) quantity = productQty[0].quantity;

  return parseInt(Details.count) > parseInt(Details.sold_amount + quantity);
};

export const checkProductQuantity = (val, itemsCard, localDetailsSelected) => {
  let cart = itemsCard.filter((val) => val.id === localDetailsSelected.id);
  let quantityNow = 0;
  if (cart.length > 0) quantityNow = cart[0].quantity;
  quantityNow = quantityNow + localDetailsSelected.sold_amount;
  let maxQuantity = parseInt(localDetailsSelected.count - quantityNow);

  if (val < 1 || !Number(val)) return 1;
  else if (maxQuantity < val) return maxQuantity;
  else return val;
};

const getPlacesNow = async (coordinates) => {
  const apiKey = googleMapsApiKey();
  const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates}&key=${apiKey}`;

  try {
    const response = await fetch(geocodeUrl);
    const data = await response.json();
    if (data.status === "OK") {
      const placeDetails = data.results[0];
      const components = placeDetails.address_components;

      // Extract emirate from address components
      let emirate = null;
      if (components) {
        emirate = components.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name;
      }

      return emirate || "Unknown Emirate";
    } else {
      console.error("Geocode API error:", data.status);
    }
  } catch (error) {
    console.error("Error fetching city name:", error);
  }

  return "Unknown Emirate";
};

const checkEqualEmarites = (emirate) => {
  if (emirate.toLowerCase().includes("dhabi") || emirate.includes("ظبي"))
    return 0;
  if (emirate.toLowerCase().includes("dubai") || emirate.includes("دبي"))
    return 1;
  if (emirate.toLowerCase().includes("shar") || emirate.includes("الشارق"))
    return 2;
  if (emirate.toLowerCase().includes("ajm") || emirate.includes("عجمان"))
    return 3;
  if (emirate.toLowerCase().includes("um") || emirate.includes("القيوين"))
    return 4;
  if (emirate.toLowerCase().includes("ras") || emirate.includes("الخيم"))
    return 5;
  if (emirate.toLowerCase().includes("fuj") || emirate.includes("الفج"))
    return 6;

  return -1; // Return a default value if no match is found
};

export const getEmiratesFee = async (address, EmiratesObject) => {
  if (EmiratesObject["-1"]) {
    return EmiratesObject["-1"];
  } else {
    let emaritesSelected = null;
    if (address.emirate && address.emirate !== "Unknown Emirate") {
      const emaritesNow = address.emirate;
      emaritesSelected = emarites.filter(
        (val) => val.id === checkEqualEmarites(emaritesNow)
      );
    } else {
      let coordinates = address.coordinates;
      const emaritesNow = await getPlacesNow(coordinates);
      emaritesSelected = emarites.filter(
        (val) => val.id === checkEqualEmarites(emaritesNow)
      );
    }
    if (emaritesSelected.length > 0)
      return EmiratesObject[emaritesSelected[0].id];
    else {
      alert("place for shipment it's invalid");
      return -1;
    }
  }
};

export const getShipmentValue = (deliveryOptions, items, id, address) => {
  const delivery_options = deliveryOptions.delivery_options.filter(
    (val) => val.id === id
  )[0];
  const deliverit_cut_rules = deliveryOptions.deliverit_cut_rules;
  const free_on_cut = Number(delivery_options.free_on_cut);
  let sumFees = 0;
  let j = 0;

  for (var i = 0; i < items.length; i++) {
    j = 0;
    while (deliverit_cut_rules[j]) {
      if (
        Number(items[i].price) <= Number(deliverit_cut_rules[j].max) ||
        deliverit_cut_rules[j].max === -1
      ) {
        sumFees += Number(
          (
            (Number(items[i].totalPrice) *
              Number(deliverit_cut_rules[j].percentage)) /
            100
          ).toFixed(2)
        );
        break;
      }
      j++;
    }
  }

  return sumFees >= free_on_cut
    ? 0
    : getEmiratesFee(address, delivery_options.Emirates);
};

export const isFile = (variable) => {
  return variable instanceof File;
}


export const showMainPageStore = (categorySelected, searchTerm) =>
  categorySelected === 0 && searchTerm === "" ? true : false;

// function for seller
export const checkFindProfileUser = (userProfile) =>
  userProfile === 0 ? false : true;
export const checkFindStoreForSeller = (sellerInfo) => 
  sellerInfo ? true : false;
export const checkUserIsCustomer = (userProfile) =>
  userProfile?.user_type === 0 ? true : false;
export const checkUserIsDriver = (userProfile) =>
  userProfile?.user_type === 1 ? true : false;
export const checkUserIsSeller = (userProfile) =>
  userProfile?.user_type === 2 ? true : false;



export const convertDateProduct = (val) =>{

  const date = new Date(val);

  const formatter = new Intl.DateTimeFormat('en-US', { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: '2-digit', 
    minute: '2-digit', 
    second: '2-digit'
  });

    return formatter.format(date);
}

export const sellerIsPendding = (sellerInfo) =>
{
  return sellerInfo?.status === 1 ? true : false;
}

export const sellerIsRejected = (sellerInfo) =>
{
  return sellerInfo?.status === 2 ? true : false;
}

export const sellerIsDisabled= (sellerInfo) =>
{
  return sellerInfo?.status === 3 ? true : false;
}

export const  isEqual =(array1,array2) =>{

  return JSON.stringify(array1) === JSON.stringify(array2)
}

export const isObject = (value) => {
  return typeof value === 'object' && value !== null;
};

export const removeHtmlCharacter = (val) => {

  return <div dangerouslySetInnerHTML={{ __html: val }} />;
};

export const  truncateText = (text, wordLimit = 50)=> {

  if(text) {

    const words = text.split(/\s+/); // Split by spaces
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(" ") + "..."; // Join back with a space and add ellipsis
    }
    return text;

  }else{
    return '';
  }

}

export const attrNameFullObject = (val) => {

  return JSON.stringify({ en: val.trim(), ar: val.trim() });
}

export const areArraysEqualUnordered =(arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  return arr1.slice().sort().toString() === arr2.slice().sort().toString();
}

export const  areArraysEqual = (arr1 ,arr2) => {
  return JSON.stringify(arr1) === JSON.stringify(arr2);
}


export const  checkScreenLaptop = (screenWidth ,mediumWidthScreen) => {
  return (screenWidth > mediumWidthScreen) 
}

export function cleanNonASCIICharacters(text) {
  return text.replace(/[^\x00-\x7F]/g, ""); // Removes all non-ASCII characters
}



