import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import 'react-chat-widget/lib/styles.css';
import { useSelector } from 'react-redux';
import { ShoppingCart } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { allLinks } from '../../../utils/helpers';

export default function ButtonCart() {

  const {totalQuantity} = useSelector(state => state.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const redirectToCart = () =>{
    navigate(`/${allLinks[6]}`)
  }

  return (
    <Box className="rcw-widget-container rcw-widget-container-cart rcw-close-widget-container ">
        <Button onClick = {() => redirectToCart()}  type="button" className="rcw-launcher chat-button" aria-controls="rcw-chat-container">
            <Typography className="rcw-badge">{totalQuantity}</Typography>
            <ShoppingCart
              fontSize="large" // Options: small, medium, large, inherit
              className='ShoppingCartButton'
             />
        </Button>
    </Box>
  )
}
