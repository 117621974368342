import React from "react";
import { Box, Typography } from "@mui/material";
import BoxOrder from "../../../components/box/BoxOrder";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useSelector } from "react-redux";
import Translation from "../../../utils/Translation";

export default function Order() {
  const { orders } = useSelector((state) => state.order);
  return (
    <Box className="all-request">
      <Box>
        <Typography
          variant="h5"
          component="h5"
          className="font-weight-600 mb-1 request-title"
        >
          <Translation data="home.orders" />
        </Typography>
        <BoxOrder
          status={"all"}
          icon={<ShoppingCartIcon />}
          keyVal={"allOrder"}
          values={orders}
          customer={true}
        />
      </Box>
    </Box>
  );
}
