import React, { useEffect, useState } from "react";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { Container, Typography, Card, Button, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { allLinks } from "../../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Address from "../../../components/store/checkout/Address";
import DeliveryOptions from "../../../components/store/checkout/DeliveryOptions";
import { useSelector } from "react-redux";
import PaymentMethod from "../../../components/store/checkout/PaymentMethod";
import ReviewOrder from "../../../components/store/checkout/ReviewOrder";
import OrderSummary from "../../../components/store/checkout/OrderSummary";
import { useDispatch } from "react-redux";
import {
  createOrderAsync,
  GetOrderPaymentIntentAsync,
  orderCreated,
  resetPayment,
  setErrorOrder,
} from "../../../services/order/orderSlice";
import {
  clearCart,
  updateCartMismatching,
} from "../../../services/cart/cartSlice";
import { setCategorySelected } from "../../../services/store/storeSlice";
import FormPayment from "../../../components/store/checkout/CreditCardPayment/FormPayment";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const dynamicState = useSelector((state) => state.dynamicConfig);
  const { language } = useSelector((state) => state.global);
  const { data } = useSelector((state) => state.address);
  const { items, totalQuantity, totalPrice } = useSelector(
    (state) => state.cart
  );
  const { deliveryOptions } = useSelector((state) => state.store);
  const { paymentIntent, orderCreated } = useSelector((state) => state.order);
  const [submit, setSubmit] = useState(false);
  const [deliveryOption, setDeliveryOption] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [shippingFee, setShippingFee] = useState(null);
  const [showFormPayment, setShowFromPayment] = useState(false);
  const [paymentDone, setPaymentDone] = useState(false);
  const [address, setAddress] = useState({
    idx: null,
    fav_id:null,
    area: "",
    city: "",
    mobile: "",
    street: "",
    coordinates: "",
    friendly_name: "",
    villa_bldng_no: "",
    location_address: "",
    emirate: "",
    formatted_address: "",
  });

  const removeAllAttribute = () => {
    dispatch(setCategorySelected(0));
    dispatch(clearCart());
    dispatch(resetPayment());
  };

  const handleCheckout = async (e) => {
    
    e.preventDefault();
    const submitAction = e.nativeEvent.submitter.name; // Get the name of the button clicked
  
    if(submitAction === "checkout") {

      setSubmit(true);
      const data = {
        order_details: items.map((val) => ({
          product_id: val.product_id,
          variant_id: val.detail.id,
          quantity: val.quantity,
          price: val.price,
        })),
        payment_method: Number(paymentMethod),
        delivery_option: Number(deliveryOption),
        coupon_code: "",
        order_id: orderCreated?.id ? orderCreated.id : null,
        drop_address_data: {
          area: address.area,
          city: address.city,
          mobile: address.mobile,
          coordinates: address.coordinates,
          friendly_name: address.friendly_name,
          villa_bldng_no: address.villa_bldng_no,
          location_address: address.location_address,
        },
      };
  
      try {
        const resultAction = await dispatch(
          createOrderAsync({ auth, dynamicState, data })
        );
        const result = resultAction.payload; // Access the resolved payload
  
        if (result?.data?.status === 200) {
          // credit card
          if (Number(paymentMethod) === 0) {
            // open order for payment
            if (result.data?.paymentIntent?.client_secret)
              setShowFromPayment(true);
            else dispatch(setErrorOrder(`An error occurred`));
          }
  
          // Cache on Delivery
          if (Number(paymentMethod) === 1) {
            await removeAllAttribute();
            navigate(`/${allLinks[12]}`, {
              state: {
                from: allLinks[7],
                error: false,
                title: t("payment_status.cache_title"),
                body: t("payment_status.cache_body"),
              },
            });
          }
        } else if (result?.data?.status === 409) {
          await dispatch(updateCartMismatching(result?.data?.cartData));
          navigate(`/${allLinks[6]}`, {
            state: { msg: t("cart.cart_not_matching") },
          });
        } else {
          setSubmit(false);
          console.error(
            "Order creation failed:",
            result?.error || "Unknown error"
          );
        }
      } catch (error) {
        setSubmit(false);
        console.error("An error occurred:", error);
        dispatch(setErrorOrder(`An error occurred: ${error}`));
      }
    }

  };

  useEffect(() => {
    const checkPaymentDone = async () => {
      const order_id = orderCreated?.id;
      const resultAction = await dispatch(
        GetOrderPaymentIntentAsync({ dynamicState, auth, order_id })
      );
      const result = resultAction.payload; // Access the resolved payload
      if (
        result.data?.status === "succeeded" ||
        result.data?.status === "requires_capture"
      ) {
        await removeAllAttribute();
        navigate(`/${allLinks[12]}`, {
          state: {
            from: allLinks[7],
            error: false,
            title: t("payment_status.payment_successfully_title"),
            body: t("payment_status.payment_successfully_body"),
          },
        });
      } else
        navigate(`/${allLinks[12]}`, {
          state: {
            from: allLinks[7],
            error: true,
            title: t("payment_status.payment_failed_title"),
            body: t("payment_status.payment_failed_body"),
          },
        });
    };

    paymentDone && checkPaymentDone();
  }, [paymentDone]);

  return items.length > 0 || submit ? (
    <>
      {showFormPayment && paymentIntent && (
        <FormPayment
          clientSecret={paymentIntent?.client_secret}
          showFormPayment={showFormPayment}
          setShowFromPayment={setShowFromPayment}
          setPaymentDone={setPaymentDone}
          setSubmit={setSubmit}
        />
      )}
      <Box className="mt-6-percent main-store" component="form" onSubmit={(e)=>handleCheckout(e)}>
        <Container className="no-padding  page_size">
          <Box sx={{ display: "inline-flex" }}>
            <Button  onClick={() => navigate(`/${allLinks[6]}`)}>
              <ArrowCircleLeftOutlinedIcon
                className="back-to-home"
              />
            </Button>
            <Typography
              variant="h5"
              component="h5"
              className="font-weight-600 mb-1 mt-1"
            >
              {t("checkout.main-title")}
            </Typography>
          </Box>

          <Grid container spacing={4}>
            <Grid
              className="checkout-component"
              size={{ lg: 8, md: 9, xs: 12 }}
            >
            
            <Card variant="outlined">
              <Address data={data} setAddress={setAddress} />
            </Card>

              {deliveryOptions &&
                deliveryOptions?.delivery_options?.length > 0 && (
                  <Card variant="outlined">
                    <DeliveryOptions
                      setDeliveryOption={setDeliveryOption}
                      deliveryOption={deliveryOption}
                      deliveryOptions={deliveryOptions}
                      setShippingFee={setShippingFee}
                      address={address}
                      items={items}
                      language={language}
                    />
                  </Card>
                )}

              <Card variant="outlined" className="mt-1">
                <PaymentMethod
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                />
              </Card>

              <Card variant="outlined" className="mt-1">
                <ReviewOrder items={items} language={language} />
              </Card>
            </Grid>

            <Grid size={{ lg: 4, md: 3, xs: 12 }}>
              <OrderSummary
                totalPrice={totalPrice}
                totalQuantity={totalQuantity}
                shippingFee={shippingFee}
                address={address}
                submit={submit}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  ) : (
    navigate(`/${allLinks[4]}`)
  );
};

export default Checkout;
