import { createSlice } from "@reduxjs/toolkit";
import { checkStorePage } from "../utils/helpers";
import { allLinks } from "../utils/helpers";
import { keyLang } from "../utils/helpers";

const initialState = {
  isLoading: false,
  error: null,
  message: null,

  valueTab: 0,
  route: "/",

  language: localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : keyLang[0],

  pageNotFound: false,

  isOnline: true,

  notification: [],
  badgeContent: 0,

  lastStorePath: null,

  screenWidth:1024,
  mediumWidthScreen:1024
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    setError: (state, action) => {
      state.error = action.payload.error;
    },
    setMessage: (state, action) => {
      state.message = action.payload.message;
    },
    setPageNotFound: (state, action) => {
      state.pageNotFound = action.payload;
    },
    setValueTab: (state, action) => {
      if (action.payload !== undefined) state.valueTab = action.payload;
      else {
        // when reload page only
        if (checkStorePage()) state.valueTab = 2;
        else if (
          window.location.href.includes(allLinks[10]) ||
          window.location.href.includes(allLinks[13])
        )
          state.valueTab = 4;
      }
    },
    setRoute: (state, action) => {
      state.route = action.payload;
    },
    setIsOnline: (state, action) => {
      state.isOnline = action.payload;
    },
    setNotification: (state, action) => {
      state.notification = [action.payload, ...state.notification];
    },
    setBadgeContent: (state, action) => {
      state.badgeContent = action.payload !== 0 ? action.payload : 0;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setLastStorePath: (state, action) => {
      state.lastStorePath = action.payload;
    },
    setScreenWidth: (state, action) => {
      state.screenWidth = action.payload;
    }
  },
});

export const {
  setLoading,
  setError,
  setMessage,
  setPageNotFound,
  setValueTab,
  setRoute,
  setIsOnline,
  setNotification,
  setBadgeContent,
  setLanguage,
  setLastStorePath,
  setScreenWidth
} = globalSlice.actions;

export default globalSlice.reducer;
