import React, { memo, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Translation from "../../../utils/Translation";
import SliderProduct from "../../slider/SliderProduct";
import { useDispatch } from "react-redux";
import {loadProductByStoreIdAsync } from "../../../services/store/storeSlice";
import { useSelector } from "react-redux";

function ProductRelated({ auth, dynamicState ,store_id ,setReadyForShowAttr }) {

  const dispatch = useDispatch();
  const {productsSeller}= useSelector((state) => state.store);
  
  useEffect(() => {

    let data = {
      limit: 20,
      offset: 0,
      extra: `&store_ids=${store_id}` ,
    };

    dispatch(loadProductByStoreIdAsync({ auth, dynamicState, data }));

  },[dispatch])
  

  return (
    productsSeller.length > 0 && (
      <Box  className="section-product-related" >
        <Typography className=" mb-1" component="p" variant='h6'>
          <Translation data="store.product_related"  />
        </Typography>
        <SliderProduct setReadyForShowAttr={setReadyForShowAttr}  products = {productsSeller}  />
      </Box>)
  );
}

export default  memo(ProductRelated);
